import React, { useEffect, useRef, useState } from "react";
import { useNotification } from "src/components/Notifination/ToastNotification";
import useDebounce from "src/hooks/useDebounce";
import { formatFileSizeUpload, getFileType, isTruthy } from "src/utils/utils";
import { Chip } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from "@mui/icons-material/Image";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';


import {
  createUserNote,
  deleteUserNote,
  getUserNotesList,
  pinUserNote,
  updateUserNote,
} from "../home-container/apis/Notesapis";
import { Picker } from "emoji-mart";
import { useSelector } from "react-redux";
import { deleteFile, UploadFile } from "../profile-container/ProfileServices";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import BulletList from "@tiptap/extension-bullet-list";
import Placeholder from "@tiptap/extension-placeholder";
import parse from "html-react-parser";
import OnlyDateRangePicker from "src/components/OnlyDateRangePicker";
import moment from "moment";
import Gallery from "../Media-container/components/Media/Gallery";
import DownloadSnackbar from "./ChatBox/DownloadSnackbar";

const options = [
  "Non",
  "Personal",
  "Important",
  "Most Important",
  "Work",
  "Favorite",
];

const extensions = [
  StarterKit,
  Placeholder.configure({
    placeholder: "Take a Note...",
  }),
  Underline,
  BulletList,
];
const content = {
  type: "doc",
  content: [{ type: "paragraph" }],
};

const Notespopup = ({
  toggleRightBarDrawer,
  openedRightBarDrawer,
  chatRoomId,
}) => {
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userNotes, setUserNotes] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const debouncedSearch = useDebounce(searchText, 500);
  const [isToggled, setIsToggled] = useState(false);
  const [isSelection, setIsSelection] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editableNoteId, setEditableNoteId] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [noteData, setNoteData] = useState({
    noteTag: "",
    title: {
      value: "",
      error: "",
    },
    content: {
      value: "",
      error: "",
    },
    files: [],
  });
  const [selectedNotes, setSelectedNotes] = useState([]);




  const [downloads, setDownloads] = useState([]);

  const truncateFileName = (fileName, maxLength = 15) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.slice(fileName.lastIndexOf("."));
    const baseName = fileName.slice(0, maxLength - extension.length);
    return `${baseName}...${extension}`;
  };

  const updateDownloadProgress = (id, progress, success = false) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id
          ? { ...download, progress, success, open: progress < 100 }
          : download
      )
    );
  };
  const isTimestamp = (name) => {
    const timestampPattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|([+-]\d{2}:\d{2}))$/;
    return timestampPattern.test(name);
  };
  const handleDownload = async (url, fileName, mediaItem) => {
    const modifiedFileName = isTimestamp(fileName)
      ? `${fileName}.png`
      : fileName;
    const id = new Date().getTime();
    const imagesType = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/webp",
      "image/svg+xml",
      "image/heif",
      "image/heic",
      "image/x-icon",
      "image/pipeg",
      "image/apng",
      "image/jxr",
    ];

    try {
      if (isTimestamp(fileName) || imagesType.includes(mediaItem?.fileType)) {
        try {
          const response = await fetch(url);
          if (!response.ok)
            throw new Error(`Failed to download file: ${response.statusText}`);
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = objectUrl;
          anchor.download = modifiedFileName;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(objectUrl);
          openSuccessNotification("Download complete.");
        } catch (error) {
          openErrorNotification(
            "Slow internet connection . Please reload application."
          );
        }
      } else {
        setDownloads((prev) => [
          ...prev,
          {
            id,
            progress: 0,
            success: false,
            fileName: truncateFileName(modifiedFileName),
            open: true,
          },
        ]);

        let start = 0;
        let end = 1024 * 1024 - 1;
        let downloadedBytes = 0;

        const getFileSize = async () => {
          const response = await fetch(url);
          return parseInt(response.headers.get("Content-Length"), 10);
        };
        const totalSize = await getFileSize();
        const chunks = [];
        while (downloadedBytes < totalSize) {
          const range = `bytes=${start}-${end}`;
          const response = await fetch(url, { headers: { Range: range } });
          if (!response.ok)
            throw new Error(
              `Failed to fetch range ${range}: ${response.statusText}`
            );
          const blob = await response.blob();
          chunks.push(blob);
          downloadedBytes += blob.size;
          start = end + 1;
          end = start + 1024 * 1024 - 1;
          if (end >= totalSize) end = totalSize - 1;

          const percentCompleted = Math.round(
            (downloadedBytes * 100) / totalSize
          );
          updateDownloadProgress(
            id,
            percentCompleted,
            downloadedBytes === totalSize
          );
        }

        const finalBlob = new Blob(chunks);
        const objectUrl = URL.createObjectURL(finalBlob);
        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = modifiedFileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
      }
    } catch (error) {
      updateDownloadProgress(id, 0, false);
    }
  };

  const handleCloseSnackbar = (id) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id ? { ...download, open: false } : download
      )
    );
  };
















  const fileInputRef = useRef(null);
  const handleFileUploadClick = () => {
    setTimeout(() => {
      fileInputRef.current.click();
    }, 0);
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      console.log(files);
      handleFileUpload(files);
    }
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "notes"
    ) {
      fetchUserNotes();
    }
  }, [openedRightBarDrawer, noteData.noteTag, startDate, endDate]);

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "notes"
    ) {
      fetchUserNotes();
    }
  }, [debouncedSearch]);

  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        class: "notespopup tiptap ProseMirror",
      },
    },
    content,
    onUpdate: ({ editor }) => {
      const msgHtml = editor.getHTML();
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = msgHtml;
      const innerText = tempDiv.innerText;
      setNoteData((prevNoteData) => ({
        ...prevNoteData,
        content: {
          ...prevNoteData.content,
          value: innerText,
          error: "",
        },
      }));
    },
  });

  useEffect(() => {}, [isEditable]);
  if (!editor) {
    return null;
  }

  const notes_close = () => {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myNotes").style.display = "none";
    toggleRightBarDrawer("notes");
    resetState();
    setEditableNoteId(null);
    setEditableNoteId(null);
    setIsEditable(false);
    setIsSelection(false);
  };
  const toggleDropdown = () => {
    const options = document.querySelector(".options");
    options.style.display =
      options.style.display === "block" ? "none" : "block";
  };

  const handleClick = (click) => {
    setIsToggled(click);
    resetState();
    setEditableNoteId(null);
    setIsEditable(false);
  };
  const resetState = () => {
    setSearchText("");
    setStartDate("");
    setEndDate("");
    setNoteData({ noteTag: "", title: "", content: "", files: [] });
    setIsToggled(false);
    setFileNames([]);
    setFileTypes([]);
    setFileProgress(0);
    setLoader(false);
  };
  const selectOption = (option) => {
    setNoteData((prevState) => ({
      ...prevState,
      noteTag: option,
    }));
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    setNoteData((prevData) => ({
      ...prevData,
      [event.target.name]: {
        ...prevData[event.target.name],
        value: event.target.value,
        error: "",
      },
    }));
  };

  const handleDeleteUploadedFiles = (id) => {
    setNoteData((prevState) => ({
      ...prevState,
      files: noteData.files.filter((fileId) => fileId !== id), // Update files with the filtered list
    }));
    setFileNames(fileNames.filter((fileId) => fileId?._id !== id));
    deleteUploadedFiles(id);
  };

  const deleteUploadedFiles = async (id) => {
    try {
      await deleteFile(id, authToken);
    } catch (error) {}
  };

  function FileAvatar(fileName) {
    let iconComponent;
    if (fileName) {
      switch (fileName) {
        case "audio":
          iconComponent = <AudiotrackIcon style={{ color: "#1c9dea" }} />;
          break;
        case "video":
          iconComponent = <VideocamIcon style={{ color: "#1c9dea" }} />;
          break;
        case "image":
          iconComponent = <ImageIcon style={{ color: "#1c9dea" }} />;
          break;
        default:
          iconComponent = <InsertDriveFileIcon style={{ color: "#1c9dea" }} />;
          break;
      }
    } else {
      iconComponent = <InsertDriveFileIcon style={{ color: "#1c9dea" }} />;
    }

    return iconComponent;
  }

  const handleFileUpload = async (files) => {
    try {
      setLoader(true);
      if (!files || files.length === 0) {
        return;
      }
      const newFileNames = [];
      const newFileIds = [];
      const newFileTypes = [];
      for (let i = 0; i < Math.min(files.length, 10); i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("remark", "Notes");
        formData.append("title", file.name);
        formData.append("refId", user?._id);
        formData.append("source", "user");
        formData.append("directory", "user");
        formData.append("by", user?._id);
        formData.append("chatRoomId", chatRoomId);
        formData.append("originalname", file.name);
        formData.append("mimetype", file.type);
        formData.append("size", file.size);
        const { data } = await UploadFile(formData, (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setFileProgress(progress);
        });

        if (data) {
          newFileNames.push(data.data);
          newFileIds.push(data.data?._id);
          newFileTypes.push(data.data?.extension);
          setFileProgress(0);
        }
      }

      setFileNames([...fileNames, ...newFileNames]);
      setFileTypes([...fileTypes, ...newFileTypes]);
      setNoteData((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...newFileIds],
      }));

      setLoader(false);
    } catch (error) {
      openErrorNotification("Failed to Attach Document");
      setLoader(false);
      setFileProgress(0);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  async function fetchUserNotes() {
    try {
      const { data } = await getUserNotesList(
        searchText,
        startDate,
        endDate,
        authToken
      );
      setUserNotes([...data]);
    } catch (error) {
      openErrorNotification("Error while fetching Notes.");
    }
  }

  const validateNoteData = () => {
    let hasError = false;
    setNoteData((prevData) => ({
      ...prevData,
      title: {
        ...prevData.title,
        error: "",
      },
      content: {
        ...prevData.content,
        error: "",
      },
    }));
    if (!noteData.title.value) {
      hasError = true;
      setNoteData((prevData) => ({
        ...prevData,
        title: {
          ...prevData.title,
          error: "Title is required",
        },
      }));
    }
    if (!noteData.content.value) {
      hasError = true;
      setNoteData((prevData) => ({
        ...prevData,
        content: {
          ...prevData.content,
          error: "Content is required",
        },
      }));
    }

    return !hasError;
  };

  const handleClickOnEditor = () => {
    const msgHtml = editor.getHTML();
    return {
      msgHtml: msgHtml,
    };
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { msgHtml } = handleClickOnEditor();
    try {
      if (validateNoteData()) {
        let formattedNoteData = {
          noteTag: noteData.noteTag,
          title: noteData.title.value,
          content: noteData.content.value,
          files: noteData.files || [],
          notesHtml: msgHtml,
        };

        let response;
        if (editableNoteId && isEditable) {
          formattedNoteData.noteId = editableNoteId;
          response = await updateUserNote(formattedNoteData, authToken);
        } else {
          response = await createUserNote(formattedNoteData);
        }

        if (response) {
          const message =
            editableNoteId && isEditable
              ? "Note updated successfully"
              : "Note created successfully";
          openSuccessNotification(message);
          resetState();
          setEditableNoteId(null);
          setIsEditable(false);
          editor?.commands?.setContent("");
        } else {
          openErrorNotification("Error creating note");
        }
        fetchUserNotes();
      }
    } catch (error) {
      openErrorNotification("Error creating note");
    }
  };

  const formatDateX = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? "0" : ""}${day}/${
      month < 10 ? "0" : ""
    }${month}/${year}`;
  };

  const handlePin = async (noteId) => {
    try {
      await pinUserNote(noteId, authToken);
      await fetchUserNotes();
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const deleteMultipleNotes = async () => {
    try {
      await Promise.all(
        selectedNotes.map(async (id) => {
          await deleteUserNote(id);
        })
      );
      setSelectedNotes([]);
      setIsSelection(false);
      await fetchUserNotes();
      openSuccessNotification(`Deleted Successfully.`);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleDelete = async (noteId) => {
    try {
      await deleteUserNote(noteId);
      await fetchUserNotes();
      openSuccessNotification(`Deleted Successfully.`);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const copyMessageToClipboard = (note) => {
    const { title, content } = note;
    const message = `${title}\n\n${content}\n`;
    navigator.clipboard
      .writeText(message)
      .then(() => {
        openSuccessNotification("Message copied to clipboard!");
      })
      .catch((error) => {
        openErrorNotification("Error copying message to clipboard");
      });
  };

  const handleSelectCheckBox = (e, note) => {
    if (e.target.checked) {
      setSelectedNotes([...selectedNotes, note?._id]);
    } else {
      setSelectedNotes(selectedNotes.filter((noteID) => noteID !== note?._id));
    }
  };

  const handleSelect = () => {
    setIsSelection(true);
  };

  const handleEdit = (note) => {
    editor.chain().focus().setContent(note?.notesHtml).run();
    setEditableNoteId(note._id);
    setNoteData({
      noteTag: note.noteTag || "",
      title: {
        value: note.title || "",
        error: "",
      },
      content: {
        value: note.content || "",
        error: "",
      },
      files: note.files || [],
    });
    setIsEditable(true);
  };

  function insertEmoji(editor, emoji) {
    editor.chain().focus().insertContent(emoji).run();
  }

  const createUserNoteModel = () => (
    <>
      <div className="notesTagsbg">
        <div className="notesTags">
          <div className="custom-dropdown dropNotes">
            <div
              className="selected-option"
              onClick={() => {
                toggleDropdown();
              }}
            >
              {isTruthy(noteData.noteTag) ? noteData.noteTag : "Note tag"}
            </div>
            <ul className="options">
              {options.map((option, index) => (
                <li key={index} onClick={() => selectOption(option)}>
                  {option}
                  {noteData.noteTag === option && (
                    <span className="check-icon">&#10003;</span>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="">
            <input
              type="text"
              placeholder="Title"
              onChange={handleInputChange}
              value={noteData.title.value}
              name="title"
              required
            />
            {noteData.title.error && (
              <p className="error-message">{noteData.title.error}</p>
            )}

            <EditorContent editor={editor} />
            {noteData.content.error && (
              <p className="error-message">{noteData.content.error}</p>
            )}
            {fileProgress > 0 && fileProgress <= 100 && loader && (
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  value={fileProgress}
                  sx={{}}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "12px",
                  }}
                >
                  {`${fileProgress}%`}
                </Box>
              </Box>
            )}
            {fileNames.length > 0 && (
              <div className="clientDocShow my-4">
                {fileNames.map((file, index) => (
                  <div key={index} className="cleDocContent">
                    <div className="d-flex align-items-start gap-1">
                      <div className="fileFormat">
                        <span className="bgFilesg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-file-earmark-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                          </svg>
                        </span>
                        <span className="filefmttext">{file.extension}</span>                      </div>
                      <div>
                        <div className="FileName">
                          {file?.title
                            ? 
                              (() => {
                                const dotIndex = file.title.lastIndexOf(".");
                                const name = file.title.slice(0, dotIndex); // Name without extension
                                const extension = file.title.slice(dotIndex); // Extension including the dot
                                return name.length > 5
                                  ? name.slice(0, 5) + ".." + extension
                                  : file.title;
                              })()
                            : null}
                        </div>

                        <div className="FileSize">
                          {formatFileSizeUpload(file.size)}{" "}
                        </div>

                        <div className="FileType">Type: {file.extension}</div>
                      </div>
                    </div>
                    <button
                      className="deletFilebtn px-3 py-3 d-flex justify-content-center align-items-center"
                      onClick={() => handleDeleteUploadedFiles(file?._id)}
                    >
                      <svg
                        width={16}
                        height={18}
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.30775 17.4997C2.81058 17.4997 2.385 17.3227 2.031 16.9687C1.677 16.6147 1.5 16.1892 1.5 15.692V2.99973H1.25C1.0375 2.99973 0.859417 2.92782 0.71575 2.78398C0.571917 2.64015 0.5 2.46198 0.5 2.24948C0.5 2.03682 0.571917 1.85873 0.71575 1.71523C0.859417 1.57157 1.0375 1.49973 1.25 1.49973H5C5 1.2549 5.08625 1.04624 5.25875 0.873735C5.43108 0.701402 5.63967 0.615234 5.8845 0.615234H10.1155C10.3603 0.615234 10.5689 0.701402 10.7413 0.873735C10.9138 1.04624 11 1.2549 11 1.49973H14.75C14.9625 1.49973 15.1406 1.57165 15.2843 1.71548C15.4281 1.85932 15.5 2.03748 15.5 2.24998C15.5 2.46265 15.4281 2.64073 15.2843 2.78423C15.1406 2.9279 14.9625 2.99973 14.75 2.99973H14.5V15.692C14.5 16.1892 14.323 16.6147 13.969 16.9687C13.615 17.3227 13.1894 17.4997 12.6923 17.4997H3.30775ZM13 2.99973H3V15.692C3 15.7818 3.02883 15.8556 3.0865 15.9132C3.14417 15.9709 3.21792 15.9997 3.30775 15.9997H12.6923C12.7821 15.9997 12.8558 15.9709 12.9135 15.9132C12.9712 15.8556 13 15.7818 13 15.692V2.99973ZM6.15425 13.9997C6.36675 13.9997 6.54483 13.9279 6.6885 13.7842C6.832 13.6404 6.90375 13.4622 6.90375 13.2497V5.74973C6.90375 5.53723 6.83183 5.35907 6.688 5.21523C6.54433 5.07157 6.36617 4.99973 6.1535 4.99973C5.941 4.99973 5.76292 5.07157 5.61925 5.21523C5.47575 5.35907 5.404 5.53723 5.404 5.74973V13.2497C5.404 13.4622 5.47583 13.6404 5.6195 13.7842C5.76333 13.9279 5.94158 13.9997 6.15425 13.9997ZM9.8465 13.9997C10.059 13.9997 10.2371 13.9279 10.3807 13.7842C10.5243 13.6404 10.596 13.4622 10.596 13.2497V5.74973C10.596 5.53723 10.5242 5.35907 10.3805 5.21523C10.2367 5.07157 10.0584 4.99973 9.84575 4.99973C9.63325 4.99973 9.45517 5.07157 9.3115 5.21523C9.168 5.35907 9.09625 5.53723 9.09625 5.74973V13.2497C9.09625 13.4622 9.16817 13.6404 9.312 13.7842C9.45567 13.9279 9.63383 13.9997 9.8465 13.9997Z"
                          fill="#1C1B1F"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div className="send-btns">
              <div className="attach d-flex justify-content-between align-items-center ql-formats">
                <div>
                  <button onClick={handleFileUploadClick}>
                    <i className="mdi mdi-paperclip"></i>
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
                <button onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                  <i
                    className="mdi mdi-emoticon-happy"
                    style={{
                      background: showEmojiPicker ? "gray" : "",
                    }}
                  />
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleBold().run()}
                  disabled={!editor.can().chain().focus().toggleBold().run()}
                  className={`button ${
                    editor.isActive("bold") ? "is-active" : ""
                  } ql-bold`}
                >
                  <i
                    className="mdi mdi-format-bold"
                    style={{
                      background: editor.isActive("bold") ? "gray" : "",
                    }}
                  />
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  disabled={!editor.can().chain().focus().toggleItalic().run()}
                  className={editor.isActive("italic") ? "is-active" : ""}
                >
                  <i
                    className="mdi mdi-format-italic"
                    style={{
                      background: editor.isActive("italic") ? "gray" : "",
                    }}
                  />
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleUnderline().run()}
                  className={
                    editor.isActive("underline") ? "is-active" : "ql-underline"
                  }
                >
                  <i
                    className="mdi mdi-format-underline"
                    style={{
                      background: editor.isActive("underline") ? "gray" : "",
                    }}
                  />
                </button>
                <button
                  onClick={() =>
                    editor.chain().focus().toggleBulletList().run()
                  }
                  className={editor.isActive("bulletList") ? "is-active" : ""}
                >
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{
                      background: editor.isActive("bulletList") ? "gray" : "",
                    }}
                  />
                </button>
              </div>
            </div>

            {showEmojiPicker && (
              <Picker
                showPreview={false}
                showSkinTones={false}
                emojiTooltip={true}
                title="Pick your emoji…"
                style={{
                  borderRadius: ".1rem",
                  maxWidth: "100%",
                  maxHeight: "250px",
                  overflow: "auto",
                }}
                onSelect={(emoji) => insertEmoji(editor, emoji.native)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="text-center mt-3 mb-3">
        <button type="button" className="btnblue" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </>
  );

  const renderUserNote = (note) =>
    isEditable && note?._id === editableNoteId ? (
      <div className="slide-header">
          {downloads.map(download => (
                <DownloadSnackbar
                  key={download.id}
                  download={download}
                  onClose={handleCloseSnackbar}
                />
              ))}
        <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a">
          Cancel Edit Note
          <button onClick={() => handleClick(false)}>&times;</button>
        </div>
        {createUserNoteModel()}
      </div>
    ) : (
      <>
        <div className="desdisbg" key={note?._id}>
          <span className="font-12 color80 d-block">
            {isSelection && (
              <input
                type="checkbox"
                onChange={(e) => handleSelectCheckBox(e, note)}
              />
            )}{" "}
            {"  "}
            {formatDateX(note?.createdAt)}
          </span>
          <div
            className={`desdis ${note?.noteTag
              ?.replace(/\s+/g, "")
              .replace(/^./, (match) =>
                match.toLowerCase()
              )} position-relative`}
          >
            <h3>{note?.title}</h3>
            {/* {note?.notesHtml ? parse(note?.notesHtml) : note?.content}  */}
            {/* Add p tag */}
            <p>{note?.notesHtml ? parse(note?.notesHtml) : note?.content}</p>
            {note?.filesData?.length > 0 && (
              <div className="clientDocShow my-4">
                {note?.filesData?.map((file, index) => (
                  <div key={index} className="cleDocContent">
                    <div className="d-flex align-items-start gap-1">
                      <div className="fileFormat">
                        <span className="bgFilesg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-file-earmark-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                          </svg>
                        </span>
                        <span className="filefmttext">{file.extension}</span>
                      </div>
                      <div>
                        <div className="FileName">
                          {file?.title
                            ? (() => {
                                const dotIndex = file.title.lastIndexOf(".");
                                const name = file.title.slice(0, dotIndex); // Name without extension
                                const extension = file.title.slice(dotIndex); // Extension including the dot
                                return name.length > 5
                                  ? name.slice(0, 5) + ".." + extension
                                  : file.title;
                              })()
                            : null}
                        </div>
                        <div className="FileSize">
                          {formatFileSizeUpload(file.size)}{" "}
                        </div>
                        <div className="FileType">Type: {file.extension}</div>
                      </div>
                    </div>
                    <button
                      className="deletFilebtn px-3 py-3 d-flex justify-content-center align-items-center"
                      onClick={() => handleDownload (file.url ,file.title,file)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={18}
                        viewBox="0 0 512 512"
                        fill="black"
                      >
                        <path
                          fill="black"
                          d="M512 256c0,70.67 -28.66,134.68 -74.99,181.01 -46.33,46.33 -110.34,74.99 -181.01,74.99 -70.68,0 -134.69,-28.66 -181.01,-74.99 -46.33,-46.33 -74.99,-110.34 -74.99,-181.01 0,-70.68 28.66,-134.69 74.99,-181.01 46.32,-46.33 110.33,-74.99 181.01,-74.99 70.67,0 134.68,28.66 181.01,74.99 46.33,46.32 74.99,110.33 74.99,181.01zm-204.29 -21.97l0 -67.04c0,-7.53 -6.19,-13.72 -13.73,-13.72l-75.96 0c-7.53,0 -13.72,6.17 -13.72,13.72l0 67.03 -42.84 0c-16.5,0 -24.78,19.64 -13.86,31.54l94.74 110.57c7.44,9 21.03,9.01 28.66,0.37l93.71 -111.31c10.69,-12.27 1.64,-31.14 -14.19,-31.16l-42.81 0zm105.52 179.2c40.22,-40.24 65.11,-95.84 65.11,-157.23 0,-61.4 -24.89,-117 -65.11,-157.23 -40.24,-40.23 -95.84,-65.11 -157.23,-65.11 -61.4,0 -117,24.88 -157.23,65.11 -40.23,40.23 -65.11,95.83 -65.11,157.23 0,61.39 24.88,116.99 65.11,157.23 40.23,40.22 95.83,65.11 157.23,65.11 61.39,0 116.99,-24.89 157.23,-65.11z"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}

            <button className="btn">{note?.noteTag} </button>
            <div className="d-flex align-item center wdpin">
              <button className="pin-outline">
                {note?.isPinned && <i className="mdi mdi-pin-outline"></i>}
              </button>
              <div className="dropdown toggleBtns toggleBtnsfile">
                <button
                  className="toggledots"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </button>
                <ul
                  className="dropdown-menu mx-0 shadow w-220px p-0"
                  data-bs-theme="light"
                >
                  <li>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center"
                      href="#"
                      onClick={() => handleEdit(note)}
                    >
                      <i className="mdi mdi-pencil"></i>
                      Edit
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center"
                      href="#"
                      onClick={() => copyMessageToClipboard(note)}
                    >
                      <i className="mdi mdi-content-copy"></i>
                      Copy
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center"
                      href="#"
                      onClick={() => {
                        handleDelete(note?._id);
                      }}
                    >
                      <i className="mdi mdi-delete-outline"></i>
                      Delete
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center"
                      href="#"
                      onClick={handleSelect}
                    >
                      <i className="mdi mdi-check-circle-outline"></i>
                      Select
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item dropdown-item-danger d-flex gap-2 align-items-center"
                      href="#"
                      onClick={() => {
                        handlePin(note?._id);
                      }}
                    >
                      <i className="mdi mdi-pin-outline"></i>
                      {note?.isPinned ? "Unpin" : "Pin"}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div
        className="chat-sidebar chat-animate-right"
        style={{ display: "none", right: "109px" }}
        id="myNotes"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName">Notes</div>
                <button
                  className=""
                  onClick={() => {
                    notes_close();
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="msg-search mx-0">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search"
                  aria-label="search"
                  value={searchText}
                  onChange={handleSearch}
                />
                <button>
                  <i className="mdi mdi-magnify"></i>
                </button>
              </div>
              <div className="onlydaterange fonsdfrnt">
                <OnlyDateRangePicker
                  onDateRangeChange={handleDateRangeChange}
                  position={"left"}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a">
                Create a New Note
                {!isToggled ? (
                  <button onClick={() => setIsToggled(true)}>
                    <i className="mdi mdi-plus"></i>
                  </button>
                ) : (
                  <button onClick={() => handleClick(false)}>&times;</button>
                )}
              </div>
              {isToggled && createUserNoteModel()}
            </div>
            <div className="modal-body pt-0">
              <div style={{ display: "flex", gap: "10px" }}>
                {selectedNotes?.length > 0 && (
                  <button
                    className="font-14 fw-500 rounded-5 py-1 px-3 border-0 d-flex align-items-center gap-2 deletedBtn"
                    onClick={() => {
                      deleteMultipleNotes();
                    }}
                  >
                    <i className="mdi mdi-delete-variant" /> Delete
                  </button>
                )}
                {isSelection && (
                  <button
                    className="font-14 fw-500 rounded-5 py-1 px-3 border-0 d-flex align-items-center gap-2 "
                    onClick={() => {
                      setIsSelection(false);
                      setSelectedNotes([]);
                    }}
                  >
                    X
                  </button>
                )}
              </div>
              {userNotes && userNotes.map(renderUserNote)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notespopup;

const formatDateDiff=(dateString)=> {
    const date = new Date(dateString);
    const now = new Date();
    const diff = Math.abs(now.getTime() - date.getTime());
  
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60) % 24);
  
    let result = '';
    if (days > 0) {
      result += `${days} days `;
    }
    if (hours > 0) {
      result += `${hours} hours`;
    }
  
    return result?.trim();
  }
  const getDateRange=({timePeriod})=> {
    const currentDate = new Date();
    let startDate = new Date();
    let endDate = new Date();
  
    switch (timePeriod) {
      case "Today":
        startDate = currentDate
        endDate = currentDate
        break;
      case "Yesterday":
        startDate.setDate(currentDate.getDate() - 1);
        // console.log(currentDate.getDate()-1,startDate)
        endDate.setDate(currentDate.getDate() - 1)
        endDate.setHours(23,59,59)
        break;
      case "Days_7":
        startDate.setDate(currentDate.getDate() - 7);
        endDate.setDate(currentDate.getDate()-1);
        break;
      case "Days_15":
        startDate.setDate(currentDate.getDate() - 15);
        endDate.setDate(currentDate.getDate() - 1)
        break;
      case "Days_30":
        startDate.setDate(currentDate.getDate() - 30);
        endDate.setDate(currentDate.getDate() - 1)
        break;
      case "Month_6":
        startDate.setMonth(currentDate.getMonth() - 6); // 1 day less
        startDate.setDate(startDate.getDate() -1 )
        endDate.setDate(currentDate.getDate() - 1)
        break;
      case "Year_1":
        startDate.setFullYear(currentDate.getFullYear() - 1);
        startDate.setDate(startDate.getDate() -1 )                   
        endDate.setDate(currentDate.getDate() - 1) // 1 day less
        break;
      case "All":
        startDate = new Date(0); // Unix epoch (Jan 1, 1970)
        endDate = currentDate;
        break;
      default:
        startDate = currentDate;
        endDate = currentDate;
    }
  
    return {
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
    };
  }
  const titleCase=(str)=>{
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
    }
const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
  let CSV = '';

  if (ShowLabel) {
    const row = Object.keys(arrData[0]).join(',');
    CSV += row + '\r\n';
  }

  
  arrData.forEach(item => {
    const row = Object.values(item).join(',');
    CSV += row + '\r\n';
  });

  
  const blob = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${ReportTitle}.csv`;
  link.click();
};
const redirectTargetBlank=(url)=>{
    if (window.electron && window.electron?.openExternal) {
      window.electron.openExternal(url);
  } else {
      window.open(url, '_blank');
  }
}
const  getFileExtension=(filename)=>{
  return "." + filename.split(".").pop();
}
export  {
    formatDateDiff,
    getDateRange,
    titleCase,
    JSONToCSVConvertor,
    redirectTargetBlank,
    getFileExtension,
}
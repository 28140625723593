import React, { useEffect, useState, useCallback, Suspense, lazy, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDetails,
  joinGroup,
  updateFavUser,
} from "../home-container/apis/Groupapis";
import {
  getUserList,
  getOrangeUserList,
  getUnreadList,
} from "../profile-container/ProfileServices";
import { updateLiveStatus } from "../home-container/apis/UserApis";
import CircularProgress from '@mui/material/CircularProgress';
import {
  currentGroup,
  setUserMessages,
  setReceiptent
} from "../home-container/actions/ChatHistoryAction";
import TopTab from "src/components/sideUserGroupList/TopTab";
import UserStatus from "src/components/sideUserGroupList/UserStatus";
import TopSearchBox from "src/components/sideUserGroupList/TopSearchBox";
import AllMessageTabList from "src/components/sideUserGroupList/AllMessageTabList";
import MemberMessageTabList from "src/components/sideUserGroupList/MemberMessageTabList";
import GroupTabList from "src/components/sideUserGroupList/GroupTabList";
import OrangeMemberTabList from "src/components/sideUserGroupList/OrangeMemberTabList";
import UserChatProfile from "src/components/sideUserGroupList/profile/UserChatProfile";
import GroupChatProfile from "src/components/sideUserGroupList/profile/GroupChatProfile";
import { chatBoxUpdateCurrentRecoardAction } from "../chatbox-container/actions/ChatBoxActions";
import { fetchUserDetailsAction } from "../auth-container/actions/AuthActions";
import { debounce, isEqual } from "lodash";
import { HOME_ACTIONS, onlineUserListAction } from "../home-container/actions/HomeActions";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { updateUnreadCount } from "src/redux/slices/chatListSlice";

const Chatlist = ({
  socket,
  startChat,
  getCurrentTime,
  groupList,
  setChatRoomId,
  setSelectedUserOrGroup,
  setReceiverData,
  // onlineUserList,
  // setOnlineUserList,
  one2oneTyping,
  groupTyping,
  getRootProps,
  getInputProps,
  toggleDrawer,
  setIsPinDrawerOpen,
  fetchUserGroups,
  selectedUserOrGroup,
  resetAndToggleModal,
  receiverData,
  setLiveStatus,
  totalUnReadGlobal,
  setTotalUnReadGlobal,
  fetchDeactivatedUsersList,
  fetchDeactivatedGroupList,
  setInactiveSearchValue,
  inactiveSearchValue,
  setInactiveValue,
  deActivatedGroups,
  deActivatedUsers,
  inactiveValue,
  setDeActivatedUsers,
  setDeActivatedGroups,
  searchFromList,
  setSearchFromList,
  searchFrom,
  tabValue,
  setTabValue,
  fetchUserList,
  fetchAndAddUserGroupList,
  searchUsersResponse,
  setSearchUsersResponse,
  setSetCurrentActiveTab,
  selectedRoomId,
  setSelectedRoomId,
  UnReadChat,
  setUnReadChat,
  isTabVisible,
  isScrolledToBottom,
  // allUser,
  // setAllUser,
  handleSocketUserListResponse,
  chatListRef
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const currentUserRedux = useSelector(
    (state) => state.ChatBoxReducer.currentRecord
  );
  const currentGroupRedux = useSelector(
    (state) => state.chatHistoryReducer.currentGroup
  );
  const onlineUserList = useSelector(
    (state) => state.HomeReducer.onlineUserList
  );

  const { jwtToken: authToken } = user || {};


  const userList = useSelector(state => state?.activeUser?.users);
  const [orangeUserList, setOrangeUserList] = useState([]);
  const [renderedItems, setRenderedItems] = useState([]);
  const [searchedUserItems, setSearchedUserItems] = useState([]);
  const [searching, setSearching] = useState(false);
  const [totalUnRead, setTotalUnRead] = useState(0);
const allUser = useSelector((state) => state.chatList.chats);


useEffect(() => {
  if (socket) {
      // Emit the getChatList event once when the socket is available
      socket.emit("getChatList", {
          userId: user?._id,
          organizationId: user?.organization,
      });

      // Define event listeners only once
      const handleChatListSpecific = (data) => handleSocketUserListResponse(data);
      const handleOnlineUsers = (data) => handleOnlineUserList(data);
      const handleSearchedUsers = (data) => handleSocketSearchedUserListResponse(data);

      // Attach event listeners
      socket.on("getChatListSpecific", handleChatListSpecific);
      socket.on("getOnlineUserList", handleOnlineUsers);
      socket.on("searchUserResponse", handleSearchedUsers);

      // Cleanup function to remove event listeners when the component unmounts or socket changes
      return () => {
          socket.off("getChatListSpecific", handleChatListSpecific);
          socket.off("getOnlineUserList", handleOnlineUsers);
          socket.off("searchUserResponse", handleSearchedUsers);
      };
  }
}, [socket, user?._id, user?.organization]);



  useEffect(() => {
    if (UnReadChat?.length > 0 ) {
      setRenderedItems(renderUserItems(UnReadChat));
    }
     if (allUser?.length > 0 &&  tabValue !==  "UnReadChatHistory") {
      setRenderedItems(renderUserItems(allUser));
    }
  }, [
    allUser,
    orangeUserList,
    onlineUserList,
    one2oneTyping,
    groupTyping,
    tabValue,
    searchUsersResponse,
    selectedRoomId,
    searchedUserItems,
    UnReadChat
  ]);

  useEffect(() => {
    if (searchUsersResponse.length > 0) {
      if (searchFromList == "userList" || searchFromList == "orangeList") {
        setSearchedUserItems(
          renderSearchUserItemsUserList(searchUsersResponse)
        );
      }
      if (searchFromList == "chatHistory") {
        setSearchedUserItems(renderUserItems(searchUsersResponse));
      }
      if (searchFromList == "groupList") {
        setSearchedUserItems(
          renderSearchUserItemsGroupList(searchUsersResponse)
        );
      }
      if (searchFromList == "UnReadChatHistory") {
        setSearchedUserItems(renderUserItems(UnReadChat));
      }
    } else {
      setSearchedUserItems("");
      setSearching(false);
      setInactiveSearchValue("")
    }
  }, [searchUsersResponse, UnReadChat, tabValue, currentGroupRedux, currentUserRedux, selectedRoomId, searchedUserItems]);

  useEffect(() => {
    const newTotalUnReadGlobal = totalUnRead > 0 ? totalUnRead : null;
    if (newTotalUnReadGlobal !== null && totalUnRead / 2 === newTotalUnReadGlobal) {
      setTotalUnRead(totalUnRead);
      setTotalUnReadGlobal(totalUnRead)
    }
    if (window.electron && window.electron.setBadgeCount) {
      window.electron.setBadgeCount(totalUnRead > 0 ? totalUnRead : 0);
    }
  }, [totalUnRead, totalUnReadGlobal]);



  const handleOnlineUserList = async ({ onlineUser }) => {
    // console.log("onlineUser=================================>", onlineUser.length)
    // setOnlineUserList(onlineUser);
    dispatch(onlineUserListAction({
      type: HOME_ACTIONS.HOME_ONLINE_USER_LIST_SUCCESS,
      data: [...onlineUser],
    }
    ));
  };
  const handleSocketSearchedUserListResponse = ({ list }) => {
    if (list?.length > 0) {
      setSearchUsersResponse(list);
      const totalUnReadCounts = list.reduce((total, item) => {
        return total + (item?.unRead || 0); 
      }, 0); 
      setTotalUnReadGlobal(totalUnReadCounts);
    } else {
      setSearchUsersResponse([]);
      setTotalUnReadGlobal(0); 
    }
    const totalUnReadCountsAllUsers = allUser?.reduce((total, item) => {
      return total + (item?.unRead || 0); 
    }, 0); 
    
    setTotalUnReadGlobal(totalUnReadCountsAllUsers);
  };
  
  const fetchUnReadList = async () => {
    const unReadFilter = allUser.filter(item => item.unRead > 0);
    setUnReadChat(unReadFilter.length > 0 ? unReadFilter : [""]);
  };
  const markAsFavUser = async (chatHistoryId) => {
    await updateFavUser(chatHistoryId?._id);
    let elem = document.getElementById(`str_${chatHistoryId?._id}`);
    let iconElem = elem.querySelector("i");
    iconElem.classList.toggle("active");

    let elemGroupSingle = document.getElementById(
      `str_chat_${chatHistoryId?.roomId}`
    );
    if (elemGroupSingle) {
      let iconElemGroupSingle = elemGroupSingle.querySelector("i");
      iconElemGroupSingle.classList.toggle("active");
    }

    if (chatHistoryId?.type == "single") {
      chatHistoryId?.participants.map((item) => {
        if (item?._id != user?._id) {
          let elemGroupSingle = document.getElementById(
            `str_chat_${item?._id}`
          );
          if (elemGroupSingle) {
            let iconElemGroupSingle = elemGroupSingle.querySelector("i");
            iconElemGroupSingle.classList.toggle("active");
          }
        }
      });
    }

    socket?.emit("getChatList", {
      userId: user?._id,
      organizationId: user?.organization,
    });
  };

  const renderUserItems = useCallback(
    (users) => {
      return users.map((item, index) => (
        <React.Fragment key={index}>
          {item.type === "single"  //&& item.participants
            ? renderSingleUser(item, index)
            : renderGroupUser(item, index)}
        </React.Fragment>
      ));
    },
    [
      onlineUserList,
      one2oneTyping,
      groupTyping,
      allUser,
      tabValue,
      searchUsersResponse,
      selectedRoomId,
      searchedUserItems
    ]
  );

  const renderSingleUser = useCallback(
    (item, index) => {
      // console.log("isNewChat", item);
      if (item?.isNewChat) {
        return renderSearchUserItemsUserList([item])
      }
      else {
        return item.participants.map((participantsUser, ind) => {
          if (participantsUser?._id !== user?._id) {
            return (
              <UserChatProfile
                resetAndToggleModal={resetAndToggleModal}
                ind={ind}
                key={ind}
                index={index}
                getRootProps={getRootProps}
                handleUserClick={handleUserClick}
                item={item}
                participantsUser={participantsUser}
                onlineUserList={onlineUserList}
                one2oneTyping={one2oneTyping}
                getInputProps={getInputProps}
                markAsFavUser={markAsFavUser}
                user={user}
                // setAllUser={setAllUser}
                setTotalUnRead={setTotalUnRead}
                selectedRoomId={selectedRoomId}
                selectedUserOrGroup={selectedUserOrGroup}
              />
            );
          }
          return null;
        });
      }

    },
    [
      onlineUserList,
      one2oneTyping,
      getCurrentTime,
      getInputProps,
      markAsFavUser,
      allUser,
      tabValue,
      searchUsersResponse,
      selectedRoomId,
      searchedUserItems
    ]
  );

  const renderGroupUser = useCallback(
    (item, index) => {
      return item?.groupDetails?.map((grp, groupIndex) => (
        <GroupChatProfile
          key={groupIndex}
          resetAndToggleModal={resetAndToggleModal}
          groupIndex={groupIndex}
          getRootProps={getRootProps}
          grp={grp}
          handleGroupClick={handleGroupClick}
          groupTyping={groupTyping}
          user={user}
          item={item}
          setTotalUnRead={setTotalUnRead}
          markAsFavUser={markAsFavUser}
          selectedRoomId={selectedRoomId}
        />
      ));
    },
    [
      groupTyping,
      getCurrentTime,
      getInputProps,
      tabValue,
      searchUsersResponse,
      selectedRoomId,
      searchedUserItems
    ]
  );


  const renderProfilePicture = (item) => {
    const profilePicture = item?.otherInformation?.profilePicture;
    const firstNameInitial = item?.firstName?.charAt(0);
    const lastNameInitial = item?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="40"
          height="40"
          loading="lazy"
          alt="user img"
          style={{
            border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
            borderWidth: "2px",
          }}
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
            borderWidth: '2px',
            color: '#555',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {`${firstNameInitial || ''}${lastNameInitial || ''}`}
        </div>
      );
    }
  };

  const renderSearchUserItemsUserList = (userList) => {
    return filterUserList(userList).map((item, index) => (
      <div
        className={`chat-user-list ${(selectedRoomId === item?._id) ? "selectedChatBox" : ""}`}
        key={index}
      >
        <a
          href="#"
          className="d-flex align-items-start"
          key={index}
          onClick={(e) => {
            e.preventDefault();
            startChat(item);
            setSelectedRoomId(item?._id);
          }}
          onDrop={(e) => {
            e.preventDefault();
            startChat(item);
            setSelectedRoomId(item?._id);
          }}
        >
          <div className="flex-shrink-0 position-relative imgUserLs">
            {renderProfilePicture(item)}
            {item.checkIcon && <span className="active"></span>}
          </div>

          <div className="flex-grow-1 ms-3">
            <div className="d-flex align-items-center justify-content-between">
              <input {...getInputProps()} multiple />
              <h3>
                {item?.firstName} {item?.lastName}
              </h3>
            </div>
            {item.action && (
              <div
                className={`font-14 d-flex align-items-center text66 ${item.mdiIconClass ? "image" : "miscall"
                  }`}
              >
                {item.mdiIconClass && (
                  <i className={`mdi ${item.mdiIconClass} font-20 pe-1`}></i>
                )}
                {item.action}
              </div>
            )}
            <div className="onlineOfine d-flex justify-content-between align-items-center">
              <div className="clock font-14 textb3">
                { } {getCurrentTime(item?.otherInformation?.lastSeen)}
              </div>
              {item.checkIcon && (
                <div className="checkonline font-14">
                  <i className="mdi mdi-check-all"></i>
                </div>
              )}
            </div>
          </div>
        </a>
      </div>
    ));
  };

  const renderSearchUserItemsGroupList = (users) => {
    return users
      .filter((grp) => {
        if (grp && Array.isArray(grp.participants) && user && user._id) {
          return grp.participants.includes(user._id);
        }
        return false;
      })
      .map((grp, index) => (
        <div
          className={`chat-list ${grp?.isDeactivated && !grp?.isDeleted ? "blurGroup" : ""
            }  ${grp?.isDeleted ? "dangerGroup" : ""}`}
          key={index}
        >
          <a
            href="#"
            className="d-flex align-items-start"
            onClick={(e) => {
              e.preventDefault();
              joinGroupFun(grp);
              // socket.on("getChatListSpecific", handleSocketUserListResponse);
            }}
            onDrop={(e) => {
              e.preventDefault();
              joinGroupFun(grp);
              // socket.on("getChatListSpecific", handleSocketUserListResponse);
            }}
          >
            <div className="flex-shrink-0 position-relative imgUserLs">
              <img
                className="img-fluid userimg"
                src={
                  grp?.groupDP?.url || "../../assets/images/no_group_image.jpg"
                }
                alt={grp?.groupDP?.title || "grp img"}
                width={45}
                height={45}
                style={{
                  border: "solid #c890ef",
                  borderWidth: "2px",
                }}
                loading="lazy"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="d-flex align-items-center justify-content-between">
                <input {...getInputProps()} multiple />
                <h3>
                  <span className="group-icon" /> {grp.title}{" "}
                </h3>
              </div>
              <div className="d-flex justify-content-between grptext">
                <div className="text onelinetext font-14 d-flex align-items-center text66">
                  {`Details: ${grp.content ? grp.content : " ---"}`}
                </div>
              </div>
              <div className="onlineOfine d-flex justify-content-between align-items-center">
                <div className="clock font-14 textb3">
                  {`Created: ${getCurrentTime(grp?.createdAt)}`}
                </div>
              </div>
            </div>
          </a>
        </div>
      ));
  };


  const SearchUser = useCallback(
    debounce((keywords) => {
      if (socket && keywords && searchFromList) {
        socket.emit("searchUser", {
          userId: user?._id,
          excludeUsers: [user?._id],
          searchString: keywords?.trim(),
          searchFromList: searchFromList,
        });
        setSearching(true);
      } else {
        setSearching(false);
      }
    }, 500), [socket, user, searchFromList]
  );

  useEffect(() => {
    SearchUser("");
    setInactiveSearchValue("")
  }, [searchFromList]);
  console.log("setInactiveSearchValue" , inactiveSearchValue);
  
  const fetchUnReadListCurrent = async (currentRoomId) => {
    const currentRoomChat = allUser.filter(item => item.roomId === currentRoomId);
    
    const unReadFilter = allUser.filter(item => item.roomId !== currentRoomId && item.unRead > 0);
    
    const mergedChats = new Array(allUser.length).fill(null);
    
    const uniqueRoomIds = new Set();
  
    if (currentRoomChat?.length > 0) {
      const currentRoomIndex = allUser.findIndex(item => item.roomId === currentRoomId);
      mergedChats[currentRoomIndex] = currentRoomChat[0];
      uniqueRoomIds.add(currentRoomChat[0].roomId);
    }
    
    unReadFilter?.forEach(item => {
      const index = allUser.findIndex(user => user.roomId === item.roomId);
      if (index !== -1 && !uniqueRoomIds.has(item.roomId)) {
        mergedChats[index] = item; 
        uniqueRoomIds.add(item.roomId);
      }
    });
  
    const finalMergedChats = mergedChats?.filter(chat => chat !== null);
    
    
    setUnReadChat(finalMergedChats.length > 0 ? finalMergedChats : [""]);
  };
  
  let userDebounceTimer;
let groupDebounceTimer; 
let isUserFirstClick = true;
let isGroupFirstClick = true; 

const handleUserClick = (e, item, participantsUser) => {
  e.preventDefault(); 

  if (isUserFirstClick) {
    isUserFirstClick = false; 

    executeUserClickLogic(item, participantsUser);

    userDebounceTimer = setTimeout(() => {
      isUserFirstClick = true; 
    }, 1000);
  } else {
    clearTimeout(userDebounceTimer);

    userDebounceTimer = setTimeout(() => {
      executeUserClickLogic(item, participantsUser);
    }, 1000); 
  }
};

const handleGroupClick = async (e, item, grp) => {
  e.preventDefault(); 

  if (isGroupFirstClick) {
    isGroupFirstClick = false;

    await executeGroupClickLogic(item, grp);

    groupDebounceTimer = setTimeout(() => {
      isGroupFirstClick = true; 
    }, 1000);
  } else {
    clearTimeout(groupDebounceTimer);

    groupDebounceTimer = setTimeout(async () => {
      await executeGroupClickLogic(item, grp);
    }, 1000); 
  }
};

const executeUserClickLogic = (item, participantsUser) => {
  try {
    setSetCurrentActiveTab("chat");
    localStorage.setItem("chatRoomId", (item?.roomId).toString());
    localStorage.removeItem("selectedGroup");
    fetchUnReadListCurrent(item?.roomId);
    dispatch(setReceiptent({ receiver: item }));
    dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));

    setChatRoomId(item?.roomId);
    setSelectedRoomId(item?._id);
    startChat(participantsUser);

    toggleDrawer();
    setIsPinDrawerOpen(false);
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatProfile").style.display = "none";

    let AllNotifications = document.querySelector("#AllNotifications");
    let chatBoxModal = document.querySelector("#chatBoxModal");
    let showNotifctn = document.querySelector("#showNotifctn");
    let notificationTextshow = document.querySelector("#notificationTextshow");

    if (chatBoxModal) {
      chatBoxModal.style.display = "block";
    }
    if (AllNotifications) {
      AllNotifications.style.width = "0";
    }
    if (showNotifctn) {
      showNotifctn.style.display = "none";
    }
    if (notificationTextshow) {
      notificationTextshow.style.display = "none";
    }
  } catch (error) {
    console.log("handleUserClick error", error);
  }
};

// Extracted logic for group clicks
const executeGroupClickLogic = async (item, grp) => {
  try {
    setSetCurrentActiveTab("chat");
    await fetchUnReadListCurrent(item?.roomId);
  
    dispatch(currentGroup(grp));
    dispatch(chatBoxUpdateCurrentRecoardAction({}));
  
    const superAdmin = item?.participants.find(elum => elum._id === grp?.userId);
  
    const updatedGroupDetails = item?.groupDetails.map(group => ({
      ...group,
      superAdmin: [superAdmin],
      participantsDetails: item?.participants.filter(elem => 
        !item.noParticipants.includes(elem._id)
      ),
    }));
  
    dispatch(setReceiptent({ receiver: item }));
    dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
  
    localStorage.setItem("selectedUserOrGroup", JSON.stringify(grp));
    localStorage.setItem("selectedGroup", JSON.stringify(grp));
    setSelectedUserOrGroup(grp);
    setReceiverData(grp);
  
    joinGroup(socket, user?._id, grp?._id, user?.organization);
    startChat(null);
  
    toggleDrawer();
    setChatRoomId(item?.roomId);
    localStorage.setItem("chatRoomId", grp?._id.toString());
    setSelectedRoomId(grp?._id);
  
    setIsPinDrawerOpen(false);
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatProfile").style.display = "none";
  
    const chatBoxModal = document.querySelector("#chatBoxModal");
    if (chatBoxModal) {
      chatBoxModal.style.display = "block";
    }
  
    const AllNotifications = document.querySelector("#AllNotifications");
    if (AllNotifications) {
      AllNotifications.style.width = "0";
    }
  
    const showNotifctn = document.querySelector("#showNotifctn");
    if (showNotifctn) {
      showNotifctn.style.display = "none";
    }
  
    const notificationTextshow = document.querySelector("#notificationTextshow");
    if (notificationTextshow) {
      notificationTextshow.style.display = "none";
    }
  } catch (e) {
    console.log("handleGroupClick error", e);
  }
};

  
  
  const joinGroupFun = async (selectedGroup) => {
    try {
      // console.log("Chatlist joinGroupFun", selectedGroup);

      let item = {
        "_id": selectedGroup?._id,
        "roomId": selectedGroup?._id,
        "type": "group",
        "participants": selectedGroup?.participants,
        "noParticipants": selectedGroup?.noParticipants,
        "message": "",
        "messageHtml": "",
        "messageType": "",//
        "title": selectedGroup?.title,
        "active": true,
        "isDeleted": selectedGroup?.isDeleted,
        "isMute": [],
        "isDeactivated": selectedGroup?.isDeactivated,
        "isFav": selectedGroup?.isFav,
        "updated_at": selectedGroup?.updated_at,
        "organizationId": selectedGroup?.organizationId,
        "timestamp": selectedGroup?.createdAt,
        "groupDetails": [selectedGroup],
        "history": [],
        "unRead": 0,
        "hasFav": false,
      }
      // console.log("item", item);

      dispatch(setReceiptent({ receiver: item }));
      dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
      localStorage.setItem("selectedGroup", JSON.stringify(selectedGroup));
      // let { data } = await getDetails(selectedGroup?._id);
      // if (socket && data) {
      // console.log("data.groups[0]", data.groups[0]);
      setChatRoomId(selectedGroup?._id);
      setSelectedUserOrGroup(selectedGroup);
      joinGroup(socket, user?._id, selectedGroup?._id, user?.organization);
      localStorage.setItem("chatRoomId", selectedGroup?._id);
      setSelectedRoomId(selectedGroup?._id);
      setReceiverData(selectedGroup);
      localStorage.setItem("selectedUserOrGroup", JSON.stringify(selectedGroup));
      startChat(null);

      // }
    } catch (error) {
      console.log("Error joining group:", error);
    }
  };


  const getCurrentStatus = async () => {
    socket?.emit("userStatusUpdate", {
      userId: user?._id,
      organizationId: user?.organization,
    });
  };

  const changeStatus = async (status) => {
    const customStatus = "";
    let res = await updateLiveStatus(status, customStatus, authToken);
    if (res) {
      setTimeout(() => {
        setLiveStatus(status);
        getCurrentStatus();
      }, [2000]);
    }
  };
  const fetchOrangeUserList = async () => {
    try {
      let paramData = {
        userId: user?._id,
        organization: user?.organization,
      };
      const data = await getOrangeUserList(paramData);
      if (data) {
        setOrangeUserList(data?.data?.user || []);
      }
    } catch (error) { }
  };
  const filterUserList = (userList) => {
    let list = userList?.filter((listUser) => listUser?._id !== user?._id);
    return list?.filter((listUser) => listUser?._id !== user?._id);
  };
  const handleReload = () => {
    window.document.getElementById("spinner").classList.add("mdi-spin");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  
  return (
    <>
      <div  className="modal-dialog-scrollable" id="chatlistusers">
        <div className="modal-content">
          <div className="chat-header">
            <div className="userstatus d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="userName">
                  Hi,{" "}
                  {user?.firstName?.replace(/\b\w/g, (char) =>
                    char?.toUpperCase()
                  )}
                  !
                </div>
                <UserStatus changeStatus={changeStatus} />
              </div>

              <div className="refrshbtn">
  <button className="connect_socket">
    {/* <span style={{ color: "green", fontFamily: "Arial, sans-serif", fontSize: "14px" }}>Connecting...</span>{" "} */}
    <i
      className="mdi mdi-flattr"
      id="spinner"
      style={{
        background: "#1c9dea",
        borderRadius: "50%",
        width: "23px",
        height: "23px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontSize: "20px",
      }}
      onClick={handleReload}
    ></i>
  </button>
</div>

            </div>

            <TopSearchBox
              SearchUser={SearchUser}
              fetchDeactivatedGroupList={fetchDeactivatedGroupList}
              fetchDeactivatedUsersList={fetchDeactivatedUsersList}
              setInactiveSearchValue={setInactiveSearchValue}
              setInactiveValue={setInactiveValue}
              setDeActivatedUsers={setDeActivatedUsers}
              setDeActivatedGroups={setDeActivatedGroups}
              inactiveValue={inactiveValue}
              resetAndToggleModal={resetAndToggleModal}
              setTotalUnRead={setTotalUnRead}
              inactiveSearchValue={inactiveSearchValue}
            />
            {(!inactiveValue && inactiveValue?.length <= 0) ||
              (inactiveValue === "all" && (
                <TopTab
                  fetchOrangeUserList={fetchOrangeUserList}
                  searchFrom={searchFrom}
                  totalUnReadGlobal={totalUnReadGlobal}
                  inactiveValue={undefined}
                  searchFromList={searchFromList}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  fetchUserList={fetchUserList}
                  fetchUnReadList={fetchUnReadList}
                  setUnReadChat={setUnReadChat}
                  UnReadChat={UnReadChat}
                  fetchAndAddUserGroupList={fetchAndAddUserGroupList}
                />
              ))}
          </div>
          <div className="modal-body"  ref={chatListRef}>
            <div className="chat-lists">
              <div className="tab-content" id="myTabContent">
                {inactiveValue === "all" && searchFromList === "groupList" && (
                  <GroupTabList
                    selectedRoomId={selectedRoomId}
                    resetAndToggleModal={resetAndToggleModal}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    groupList={groupList}
                    joinGroupFun={joinGroupFun}
                    socket={socket}
                    handleSocketUserListResponse={handleSocketUserListResponse}
                    searching={searching}
                    searchedUserItems={searchedUserItems}
                    inactiveValue={undefined}
                    inactiveSearchValue={inactiveSearchValue}
                  />
                )}
                {inactiveValue &&
                  inactiveValue?.length > 0 &&
                  inactiveValue == "deActiveGroups" &&
                  inactiveValue != "deActiveUsers" && (
                    <GroupTabList
                      selectedRoomId={selectedRoomId}
                      resetAndToggleModal={resetAndToggleModal}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      groupList={deActivatedGroups}
                      joinGroupFun={joinGroupFun}
                      socket={socket}
                      handleSocketUserListResponse={
                        handleSocketUserListResponse
                      }
                      searching={searching}
                      searchedUserItems={searchedUserItems}
                      inactiveValue={undefined}
                      inactiveSearchValue={inactiveSearchValue}
                    />
                  )}
                {inactiveValue === "all" && searchFromList === "userList" && (
                  <MemberMessageTabList
                    resetAndToggleModal={resetAndToggleModal}
                    getRootProps={getRootProps}
                    userList={userList}
                    filterUserList={filterUserList}
                    startChat={startChat}
                    getInputProps={getInputProps}
                    searching={searching}
                    searchedUserItems={searchedUserItems}
                    inactiveValue={undefined}
                    selectedUserOrGroup={selectedUserOrGroup}
                    inactiveSearchValue={inactiveSearchValue}
                  />
                )}
                {inactiveValue &&
                  inactiveValue?.length > 0 &&
                  inactiveValue != "deActiveGroups" &&
                  inactiveValue !== "all" && (
                    <MemberMessageTabList
                      resetAndToggleModal={resetAndToggleModal}
                      getRootProps={getRootProps}
                      userList={deActivatedUsers}
                      filterUserList={filterUserList}
                      startChat={startChat}
                      getInputProps={getInputProps}
                      searching={searching}
                      searchedUserItems={searchedUserItems}
                      inactiveValue={undefined}
                      selectedUserOrGroup={selectedUserOrGroup}
                      inactiveSearchValue={inactiveSearchValue}
                    />
                  )}

                {inactiveValue === "all" && (
                  <>
                    {searchFromList === "chatHistory" &&
                      searchFromList !== "UnReadChatHistory"
                      && (
                        <AllMessageTabList
                          searching={searching}
                          searchedUserItems={searchedUserItems}
                          renderedItems={renderedItems}
                          inactiveValue={undefined}
                          inactiveSearchValue={inactiveSearchValue}
                        />
                      )}
                    {searchFromList === "UnReadChatHistory" &&   searchFromList !== "chatHistory" && (
                      <AllMessageTabList
                        searching={searching}
                        searchedUserItems={searchedUserItems}
                        renderedItems={renderedItems}
                        inactiveValue={undefined}
                        inactiveSearchValue={inactiveSearchValue}
                      />
                    )}
                  </>
                )}

                {(!inactiveValue && inactiveValue?.length <= 0) ||
                  (inactiveValue === "all" &&  (
                    <OrangeMemberTabList
                      resetAndToggleModal={resetAndToggleModal}
                      orangeUserList={orangeUserList}
                      startChat={startChat}
                      searching={searching}
                      searchedUserItems={searchedUserItems}
                      inactiveValue={undefined}
                      inactiveSearchValue={inactiveSearchValue}
                    />
                  ))}
              </div>
            </div>
            { }
          </div>
        </div>
      </div>
      <div className="splitter" style={{ touchAction: "none" }}></div>
    </>
  );
};
export default Chatlist;

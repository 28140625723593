import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadFile,
  getLastSeen,
  getUserDetails,
  updateLastSeen,
  updateUserDetails,
} from "./ProfileServices";
import {
  getLastSeenString,
  userProfileInitialState,
} from "./ProfileDetailsutils";
import io from "socket.io-client"; 
import urls from "src/constants/UrlConstant";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { updateUserProfile } from "../home-container/actions/authAction";
// const Profile = ({
//   toggleDrawer,
//   setEditGroup,
//   setIsGroupEditable,
// }) => {
  const Profile = React.memo(({ 
    toggleDrawer,
    setEditGroup,
    setIsGroupEditable
   }) => {
    console.log("profileRender")
  const user = useSelector((state) => state.auth.user);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const [userDetails, setUserDetails] = useState(userProfileInitialState({}));
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
  const [lastSeen, setLastSeen] = useState(null);
  const socket = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getUserProfileDetails();
  }, [toggleDrawer]);

  useEffect(() => {
    socket.current = io(urls.socketUrl, {
      extraHeaders: {
        Authorization: `${authToken}`,
      },
    });
    socket.current.on("get-last-seen", (timestamp) => {
      setLastSeen(timestamp);
    });

    const activityInterval = setInterval(() => {
      if (user) {
        const timestamp = Date.now();
        socket.current.emit("user-activity", user._id);
        Promise.allSettled([
          updateUserLastSeen(timestamp),
          getUserLastSeen(user._id),
        ]);
      }
    }, 30000);
    return () => {
      clearInterval(activityInterval);
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const timestamp = Date.now();
    socket.current.emit("user-activity", user._id);
    Promise.allSettled([
      updateUserLastSeen(timestamp),
      getUserLastSeen(user._id),
    ]);
  }, []);

  const getUserLastSeen = async () => {
    const { data } = await getLastSeen(user._id, authToken);
    const lastActive = getLastSeenString(data?.lastSeen);
    setLastSeen(lastActive);
  };

  const updateUserLastSeen = async (lastSeenValue) => {
    await updateLastSeen(user._id, lastSeenValue, authToken);
  };

  const getUserProfileDetails = async () => {
   try {
    const {data} = await getUserDetails(user?._id ?? "");
    setUserDetails(userProfileInitialState(data));
   } catch (error) {
    
   }
  };

  const updateUserProfileDetails = async () => {
   try {
    await updateUserDetails(user?._id ?? "", userDetails, authToken);
    setIsDescriptionEditable((prevState) => !prevState);
    getUserProfileDetails();
   } catch (error) {
    
   }
  };

  const createGroupDrawer_open = () => {
    toggleDrawer("createGroup")
    setIsGroupEditable(false);
    setTimeout(() => {
      document.getElementById("main").style.marginRight = "0%";
      document.getElementById("addmembergroup").style.display = "none";
      document.getElementById("addmembergroup").style.display = "none";
      document.getElementById("createGroup").style.display = "block";
    }, 2);
  };

  const handleProfileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("remark", "Profile picture");
      formData.append("title", file?.name);
      formData.append("refId", user?._id);
      formData.append("source", "user");
      formData.append("directory", "user");
      formData.append("by", user?._id);
      const {data} = await UploadFile(formData);
      if (data.data.url) {
        setUserDetails({
          ...userDetails,
          profilePicture: data.data.url,
        });

        await updateUserDetails(
          user?._id ?? "",
          { ...userDetails, profilePicture: data.data.url },
          authToken
        );
        dispatch(updateUserProfile("profilePicture", data.data.url));
        openSuccessNotification("Profile Picture updated Successfully.");
      }
    } catch (error) {
      openErrorNotification("failed To update profile Picture");
    }
  };

  const handleDescriptionChnage = (event) => {
    setUserDetails({
      ...userDetails,
      description: event.target.value,
    });
  };
  const handleClick = () => {
    setIsDescriptionEditable((prevState) => !prevState);
  };
  const handleSubmit = () => {
    updateUserProfileDetails();
  };
  return (
    <div className="chatlist">
      <div className="modal-dialog-scrollable">
        <div className="modal-content">
          <div className="chat-header">
            <div className="userstatus d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="chat-sidebar chat-animate-left" id="myProfile">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="slide-header border-0 cover-img">
                        <div className="bgcover-img">
                          <button className="coverBtncmra">
                            <i className="mdi mdi-camera"></i>
                          </button>
                        </div>
                        <div className="HeadSlidName d-flex justify-content-between align-items-center">
                          <div className="slidName font-24 text-white">
                            Profile
                          </div>
                          <button
                            className="bg-none text-white"
                            onClick={()=>{toggleDrawer()}}
                          >
                            &times;
                          </button>
                        </div>
                        <div className="profileupload text-center">
                          <div className="columnsProfile">
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  accept=".png, .jpg, .jpeg"
                                  name="profilePicture"
                                  onChange={handleProfileChange}
                                />
                                <label htmlFor="imageUpload"></label>
                              </div>
                              <div className="avatar-preview">
                                <div
                                  id="imagePreview"
                                  style={{
                                    backgroundImage: `url(${
                                      userDetails?.profilePicture ||
                                      "./assets/images/user.svg"
                                    })`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-body settinghndl border-0 mt-0">
                        <div className="text-center profText">
                          <div className="LastSeen font-12 color80 pt-0 pb-0">
                            {lastSeen === "Online"
                              ? "Online"
                              : "Last Seen: " + lastSeen}
                          </div>
                          <div className="userName font-18 color1a">
                            {`${userDetails?.firstName}   ${" "}  ${
                              userDetails?.lastName
                            } `}
                          </div>
                          <div className="userdec font-14 colorb">
                            {userDetails?.designation}
                          </div>
                          <div className="userprd font-12 color80 pt-1">
                            {userDetails?.department}
                          </div>
                        </div>
                        <div className="userdetails">
                          <div className="user-list pt-0 border-0">
                            <div className="font-12 color66 mb-3 d-flex justify-content-between align-items-center">
                              {!isDescriptionEditable &&
                                userDetails?.description}
                              {isDescriptionEditable && (
                                <textarea
                                  type="text"
                                  rows="5"
                                  cols="50"
                                  value={userDetails?.description}
                                  onChange={handleDescriptionChnage}
                                />
                              )}

                              {!isDescriptionEditable && (
                                <button
                                  className="penedit bg-none border-0 color1a ms-3"
                                  onClick={handleClick}
                                >
                                  <i className="mdi mdi-pencil-outline mdi-18px"></i>
                                </button>
                              )}
                            </div>
                            {isDescriptionEditable && (
                              <div className="text-center mt-3 mb-3">
                                <button
                                  type="button"
                                  className="btnblue"
                                  onClick={handleSubmit}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                            <div className="user-d pb-2 d-flex align-items-center">
                        <lable className="font-14 color80">
                           <img
                              src="/assets/images/profile/reporting-icon.svg"
                              className="img-fluid"
                              alt=""
                              width={17}
                              height={17}
                              />
                        </lable>
                        <a href="#" className="font-14 color1a d-block fw-500">
                        <span className="colorp d-block">{`${userDetails?.employeeID}`}</span>
                        </a> 
                  </div> 
                            <div className="user-d pb-2 d-flex align-items-center">
                              <lable className="font-14 color80">
                                <img
                                  src="assets/images/profile/email-icon.svg"
                                  className="img-fluid"
                                  alt=""
                                  width="15"
                                  height="11"
                                />
                              </lable>
                              <a
                                href=""
                                className="font-14 color1a d-block fw-500"
                              >
                                {userDetails?.email}
                              </a>
                            </div>
                            <div className="user-d pb-2 d-flex align-items-center">
                              <lable className="font-14 color80">
                                <img
                                  src="assets/images/profile/mobile-icon.svg"
                                  className="img-fluid"
                                  alt=""
                                  width="10"
                                  height="16"
                                />
                              </lable>
                              <a
                                href=""
                                className="font-14 color1a d-block fw-500"
                              >
                                {userDetails?.phone}
                              </a>
                            </div>
                            <div className="user-d pb-2 d-flex align-items-center">
                              <lable className="font-14 color80">
                                <img
                                  src="assets/images/profile/company-icon.svg"
                                  className="img-fluid"
                                  alt=""
                                  width="17"
                                  height="15"
                                />
                              </lable>
                              <span
                                href=""
                                className="font-14 color1a d-block fw-500"
                              >
                                {userDetails?.organization}
                              </span>
                            </div>
                            <div className="user-d pb-2 d-flex align-items-center">
                              <lable className="font-14 color1a">
                                <img
                                  src="assets/images/profile/location-icon.svg"
                                  className="img-fluid"
                                  alt=""
                                  width="11"
                                  height="16"
                                />
                              </lable>
                              <span className="font-14 color1a d-block fw-500">
                                {userDetails?.organizationLocation}
                              </span>
                            </div>
                          </div>
                          <div className="user-group border-0">
                            <h3 className="font-14 color66">
                              Created Group by you{" "}
                            </h3>
                            {userDetails?.groupsCreatedByMe?.map((group) => (
                              <div key={group._id} className="chat-list">
                                <div className="d-flex align-items-start">
                                  <div className="flex-shrink-0 position-relative">
                                    <img
                                      className="img-fluid userimg"
                                      src={
                                        group?.groupIcon?.url 
                                        ??
                                        "../../assets/images/no_group_image.jpg"
                                      }
                                      width="45"
                                      height="45"
                                      alt="user img"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between flex-grow-1 ms-3">
                                    <div className="">
                                      <h3>
                                        <span className="gropu-icon"></span>{" "}
                                        {group.title}
                                      </h3>
                                      <div className="clock font-14 textb3">
                                        {group.participants.length} member
                                      </div>
                                    </div>
                                    <div className="onlineOfine d-flex justify-content-between align-items-center">
                                      <button
                                        className="penedit bg-none border-0 color1a ms-3"
                                        onClick={() => {
                                          setEditGroup(group?._id);
                                          createGroupDrawer_open();
                                        }}
                                      >
                                        <i className="mdi mdi-pencil-outline mdi-18px"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Profile;

import React, { useEffect } from "react";

const TopTab = ({
  fetchOrangeUserList,
  searchFrom,
  totalUnReadGlobal,
  inactiveValue,
  searchFromList,
  tabValue,
  setTabValue,
  fetchUserList,
  fetchUnReadList,
  fetchAndAddUserGroupList,
  setUnReadChat,
  UnReadChat
}) => {
  const tabs = [
    {
      id: "AllMessage-tab",
      target: "#AllMessage",
      label: "Chats",
      count: totalUnReadGlobal || 0, // Default to 0 if totalUnReadGlobal is undefined
      ariaControls: "AllMessage",
      ariaSelected: true, // Ensure this reflects the actual state in your component
      onClick: () => {
        // setUnReadChat([]);
        searchFrom("chatHistory");
      },
      additionalClasses: "active",
    },
    {
      id: "Unread-tab",
      target: "#Unread", // Consider changing if different content section
      label: "Unread",
      count: tabValue === "UnReadChatHistory" ?  totalUnReadGlobal : 0,
      ariaControls: "Unread",
      ariaSelected: false,
      onClick: () => {
        fetchUnReadList();
        searchFrom("UnReadChatHistory");
      },
      additionalClasses: "",
    },
    {
      id: "employee-tab",
      target: "#employee",
      label: "Members",
      count: 0, // Default to 0 if no user count
      ariaControls: "employee",
      ariaSelected: false,
      onClick: () => {
        fetchUserList();
        searchFrom("userList");
      },
      additionalClasses: "",
    },
    {
      id: "Group-tab",
      target: "#Group",
      label: "Groups",
      count: 0, // Default to 0 if no group count
      ariaControls: "Group",
      ariaSelected: false,
      onClick: () => {
        fetchAndAddUserGroupList();
        searchFrom("groupList");
      },
      additionalClasses: "",
    },
    // Uncomment if needed
    // {
    //   id: "OrangeMember-tab",
    //   target: "#OrangeMember",
    //   label: "Orange Members",
    //   count: 0, // Default to 0 if no orange member count
    //   ariaControls: "OrangeMember",
    //   ariaSelected: false,
    //   onClick: () => {
    //     fetchOrangeUserList();
    //     searchFrom("orangeList");
    //   },
    //   additionalClasses: "",
    // },
  ];
  

  useEffect(() => {
  }, [searchFromList, tabValue]);

  return (
    <>
    {inactiveValue == "deActiveUsers" ? <h4>De-Active Users</h4>: inactiveValue =="deActiveGroups" ? <h4>De-Active Groups</h4>:(
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {tabs.map((tab) => (
        <li className="nav-item" role="presentation" key={tab.id} style={tab.style || {}}>
          <button
            className={`nav-link ${tab.additionalClasses}`}
            id={tab.id}
            data-bs-toggle="tab"
            data-bs-target={tab.target}
            type="button"
            role="tab"
            aria-controls={tab.ariaControls}
            aria-selected={tab.ariaSelected}
            onClick={tab.onClick || undefined}
          >
            {tab.label} 
            {(tab.count!==null && tab.count > 0)?<span className="unreadMsgs" title="All Unread Messages"
              data-bs-toggle="tooltip"
              data-bs-placement="right">{tab.count}</span>:null}
          </button>
        </li>
      ))}
    </ul>
  )}
    </>
  );
};

export default TopTab;

import { useState } from "react";

const TopSearchBox = ({
  SearchUser,
  fetchDeactivatedUsersList,
  fetchDeactivatedGroupList,
  setInactiveSearchValue,
  setInactiveValue,
  setDeActivatedUsers,
  setDeActivatedGroups,
  inactiveValue,
  resetAndToggleModal
}) => {
  return (
    <div className="msg-search">
      <input
        type="text"
        className="form-control"
        id="inlineFormInputGroup"
        placeholder="Search"
        aria-label="search"
        onChange={(e) => {
          setInactiveSearchValue(e.target.value);
          if(inactiveValue==="all")
            {
              SearchUser(e.target.value);
            }
        }}
      />
      <button>
        <i className="mdi mdi-magnify"></i>
      </button>
      {}
      <div className="dropdown tabslalsnmn">
        <button
          className="add"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="mdi mdi-dots-vertical"></i>
        </button>
        <ul
          className="dropdown-menu shadow"
          aria-labelledby="dropdownMenuButton1"
        >
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                if(inactiveValue !== "all")
               {
                setDeActivatedUsers([])
                setDeActivatedGroups([])
                 setInactiveValue("all");
                 resetAndToggleModal()
               }
              }}
              
            >
              All Chats{" "}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                fetchDeactivatedUsersList();
                setInactiveValue("deActiveUsers");
              }}
              
            >
              De-Activated User{" "}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                fetchDeactivatedGroupList();
                setInactiveValue("deActiveGroups");
              }}
            >
              Exited Groups
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopSearchBox;

import React, { useRef, useEffect } from "react";

const GoogleMapShare = ({ latitude, longitude }) => {
  const mapRef = useRef(null);
// console.log("latitude, longitude",latitude, longitude);
  useEffect(() => {
    if (!mapRef.current) return;

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: latitude, lng: longitude },
      zoom: 100,
    });

    new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });
  }, [latitude, longitude]);

  const shareLocation = () => {
    const shareUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <div>
      <div ref={mapRef} style={{ width: "500px", height: "250px" }}></div>
    </div>
  );
};

export default GoogleMapShare;

import React, { useEffect, useState } from "react";
import { handleLogin } from "./LoginService";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { loginSuccess } from "../home-container/actions/authAction";
import { useDispatch } from "react-redux";
import { useNotification } from "src/components/Notifination/ToastNotification";
import config from "src/config";
import {
  isMobile,
  isTablet,
  isDesktop,
  isBrowser,
  deviceType,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { setAccountSettingOnLogin } from "../account-container/actions/AccountActions";

const Login = () => {
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialState = {
    username: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    rememberme: {
      value: true,
      error: "",
    },
  };

  const [formData, setFormData] = useState(initialState);
  const [location, setLocation] = useState(null);
  const [publicIpAddress, setPublicIpAddress] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      setFormData(JSON.parse(userDataString));
    } else {
      setFormData(initialState);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };
  const fetchData = async () => {
    try {
      const platform = navigator.platform;
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const address = await fetchAddress(latitude, longitude);
      setLocation({ latitude, longitude, address, platform });
    } catch (error) {
      console.error(error.message);
    }
    try {
      const ip = await fetchIpAddress();
      setPublicIpAddress(ip);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchAddress = async (latitude, longitude) => {
    const api_Key = config.MAP_API_KEY;
    const url = `${config.Google_Map_URL}${latitude},${longitude}&key=${api_Key}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      const { results } = data;
      if (results.length > 0) {
        return results[0].formatted;
      }
    } catch (error) {
      console.error(error.message);
    }

    return "Location not found";
  };

  const fetchIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error(error.message);
      return "IP NA";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value: value,
        error: "",
      },
    }));
  };
  const handleRememberme = () => {
    setFormData({
      ...formData,
      rememberme: {
        ...formData.rememberme,
        value: !formData.rememberme.value,
      },
    });
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };
  const validateForm = () => {
    let valid = true;
    const updatedFormData = { ...formData };

    if (formData.username.value?.trim() === "") {
      updatedFormData.username.error = "Username is required";
      valid = false;
    } else if (formData.username.value?.includes('@')         ) {
      if( !validateEmail(formData.username.value))
      {
        updatedFormData.username.error = "Invalid email format";
        valid = false;
      }
    } else {
      updatedFormData.username.error = "";
    }
    if (formData.password.value?.trim() === "") {
      updatedFormData.password.error = "Password is required";
      valid = false;
    } else if (formData.password.value?.trim().length < 6) {
      updatedFormData.password.error =
        "Password must be at least 6 characters long";
      valid = false;
    } else {
      updatedFormData.password.error = "";
    }

    setFormData(updatedFormData);
    return valid;
  };
  const handleSubmitLoginForm = async () => {
    if (validateForm() === true) {
      try {
        const { data } = await handleLogin(
          formData.username.value,
          formData.password.value,
          formData.rememberme.value,
          {
            isMobile,
            isTablet,
            isDesktop,
            isBrowser,
            deviceType,
            browserName,
            browserVersion,
            osName,
            osVersion,
            location,
            publicIpAddress,
          }
        );
        const { token, user } = data;
        if (token) {
          localStorage.setItem("user", JSON.stringify({ ...user }));
          localStorage.setItem("token", JSON.stringify(token));
          setFormData(initialState);
          if (formData.rememberme.value) {
            localStorage.setItem("userData", JSON.stringify(formData));
          } else {
            localStorage.setItem("userData", JSON.stringify(initialState));
          }
          dispatch(loginSuccess({ ...user, jwtToken: token }));
          navigate("/home", { replace: true });
          openSuccessNotification("Welcome! Logged In Successfully.");
        }
      } catch (error) {
        navigate("/login");
        openErrorNotification(
          error?.response?.data?.message ??
            "Login failed. Please check your credentials and try again."
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitLoginForm();
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitLoginForm();
    }
  };

  const token = localStorage.getItem("token");
  if (token) navigate("/home", { replace: true });

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode === 'enabled') {
      setDarkMode(true);
      document.body.classList.add('darkmode');
    } else {
      setDarkMode(false);
      document.body.classList.remove('darkmode');
    }
  }, []);
  
  const enableDarkMode = () => {
    document.body.classList.add('darkmode');
    setDarkMode(true);
    localStorage.setItem('darkMode', 'enabled');
  };
  
  const disableDarkMode = () => {
    document.body.classList.remove('darkmode');
    setDarkMode(false);
    localStorage.removeItem('darkMode');
  };
  
  const handleToggle = () => {
    if (darkMode) {
      disableDarkMode();
    } else {
      enableDarkMode();
    }
  };
  return (
    <div>
      <>
        <title>SyncUp</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="/assets/css/mdi/css/materialdesignicons.min.css"
        />
        {}
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        <link href="/assets/css/sidebars.css" rel="stylesheet" />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n       \ndiv#wave {\n  position: relative; \n  text-align: center;\n  width: 150px;\n  height: 100px;\n  margin-left: auto;\n  margin-right: auto;\n}\ndiv#wave .dot {\n  display: inline-block;\n  width: 7px;\n  height: 7px;\n  border-radius: 50%;\n  margin-right: 3px;\n  background: #fff;\n  animation: wave 1.3s linear infinite;\n}\ndiv#wave .dot:nth-child(2) {\n  animation-delay: -1.1s;\n}\ndiv#wave .dot:nth-child(3) {\n  animation-delay: -0.9s;\n}\n\n@keyframes wave {\n  0%, 60%, 100% {\n    transform: initial;\n  }\n  30% {\n    transform: translateY(-15px);\n  }\n}\n           .signinFbg{position: relative;\n    width: 100%; float: left;\n    background-color: #fff;\n    border-radius: 0rem;\n    overflow: hidden;\n    min-height: calc(100vh - 56px); width:25%; }\n           \n           .bggradint{background-image: linear-gradient(135deg, #4DE5F4, #499EFF);} \n           \n           .form-check-input:checked {\n    background-color: #1c9dea;\n    border-color: #1c9dea;\n}\n           .welcomeLogin {height: 100%; display: flex;}\n           .welcomeLogin .loginDetails{height: 100%; width: 65%; padding: 30px; display: flex; justify-content: space-between; /*align-items: center;*/ flex-direction: column;}\n           .welcomeLogin .loginQRcode{height: 100%; width: 35%; padding: 30px; display: flex; justify-content: space-between; /*align-items: center;*/ flex-direction: column;}\n           .loginQRcode .scaner{background: #EEF8FE; padding: 20px; border-radius: 10px; margin-bottom: 15px;}\n           .igntopt{height: 100%; display: flex; justify-content: space-between; flex-direction: column;}\n           .signinbt{font-size: 18px;\n    font-weight: 500;\n    color: #fff;\n    background: #1C9DEA;\n    padding: 7px 35px;\n    border-radius: 50px;}\n           \n           .loginForm input{border: 1px solid #1a1a1a !important;}\n       ",
          }}
        />

        <header className="p-2">
          <div className="container-fluid">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start align-items-center navtop">
              <a
                href="#"
                className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
                onClick={(e) => e.preventDefault()}
              >
              <img src="/assets/images/logo.svg" class="img-fluid logoHder" alt=""/><img src="/assets/images/logow.svg" class="img-fluid logowHder d-none" alt=""/>
              </a>
              <ul
                className="nav col-12 col-lg-auto ms-lg-auto mb-2 justify-content-center align-items-center mb-md-0"
                style={{ display: "none" }}
              >
                <li>
                  <a href="#" className="nav-link px-3 py-1 quicktour">
                    <i className="mdi mdi-play" /> Quick tour
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="px-3 py-1 btn btn-primary rounded-pill mx-2 text-white"
                  >
                    Book a demo
                  </a>
                </li>
                <li>
                  <a href="#" className="nav-link px-2">
                    <i className="mdi mdi-fullscreen" id="fullscreen-button" />
                  </a>
                </li>
                <li>
                  <a href="#" className="nav-link px-2">
                    <i className="mdi mdi-window-minimize" />
                  </a>
                </li>
                <li>
                  <a href="#" className="nav-link px-2">
                    <i className="mdi mdi-window-maximize" />
                  </a>
                </li>
                <li>
                  <a href="#" className="nav-link px-2">
                    <i className="mdi mdi-close" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>

        <main className="d-flex flex-nowrap">
          <div className="chat-area">
            <div className="h-100 signinFbg" style={{ width: "30%" }}>
              <div className="modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-body bggradint text-center p-4">
                    <div className="igntopt">
                      <div className="signtoptext">
                        <h2 className="font-34 text-white fw-bold">
                          Start your journey with us.
                        </h2>
                        <p className="font-14 text-white fw-500">
                          It brings together your tasks, projects, timelines,
                          files and more
                        </p>
                      </div>
                      <div className="img my-4">
                        <img
                          src="assets/images/signin/start-your-journey-with-us.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div id="wave" className="text-white">
                        Typing
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chatbox">
              <div className="modal-dialog-scrollable">
                <div className="modal-content chatboxMsg" id="chatboxmsg">
                  <div className="modal-body p-4">
                    <div className="welcomeLogin">
                      <div className="loginDetails">
                        <div className="loginForm">
                          <div className="wcmhd">
                            <h3 className="font-30 fw-500 color1a">
                              Welcome Back
                            </h3>
                            <h4 className="font-22 fw-500 color80">
                              Sign in to continue to SyncUp!
                            </h4>
                          </div>
                          <form className="mt-2">
                            <div className="mb-1">
                              <label
                                htmlFor="Username"
                                className="form-label font-14 color1a fw-500"
                              >
                                Username *
                              </label>
                              <input
                                type="email"
                                className="form-control border"
                                id="Username"
                                name="username"
                                aria-describedby="emailHelp"
                                value={formData.username.value}
                                onChange={handleChange}
                              />
                            </div>
                            <p className="mb-1" style={{ color: "red" }}>
                              {formData.username.error}
                            </p>
                            <div className="mb-1">
                              <label
                                htmlFor="password"
                                className="form-label font-14 color1a fw-500"
                              >
                                Password *
                              </label>
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control border"
                                id="password"
                                name="password"
                                value={formData.password.value}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                              />
                            </div>
                            <p className="mb-1" style={{ color: "red" }}>
                              {formData.password.error}
                            </p>
                            <div className="form-check form-switch mb-1 mt-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="showpassward"
                                name="showpassward"
                                checked={showPassword}
                                onClick={handleTogglePassword}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="showpassward"
                              >
                                {!showPassword
                                  ? "Show Password"
                                  : "Hide Password"}
                              </label>
                            </div>
                            <div className="form-check form-switch mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="Rememberme"
                                name="rememberme"
                                checked={formData.rememberme.value}
                                onChange={handleRememberme}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Rememberme"
                              >
                                Remember me
                              </label>
                            </div>
                            <button
                              type="button"
                              className="btn signinbt"
                              onClick={handleSubmit}
                            >
                              Sign In
                            </button>
                          </form>
                        </div>
                        <div className="font-14 color1a fw-bold mt-4">
                          Create a New Company Account?{" "}
                          <a href="#" className="d-block colorb fw-normal">
                            Let’s Connect ⟶
                          </a>
                        </div>
                      </div>
                      <div className="loginQRcode">
                        <div className="scaner text-center">
                          <span className="scanerImg">
                            <img
                              src="assets/images/signin/scaner.png"
                              className="img-fluid"
                              width={217}
                              height={217}
                              alt=""
                            />
                          </span>
                          <div className="logQrcd">
                            <div className="font-18 color1a fw-bold mt-3 mb-2">
                              Login with QR Code
                            </div>
                            <ol className="ps-1 text-start mb-0">
                              <li className="font-12 color80 py-1">
                                On your phone, open{" "}
                                <strong className="color1a">SyncUp App</strong>
                              </li>
                              {}
                              <li className="font-12 color80 py-1">
                                Tap Profile and select{" "}
                                <strong className="color1a">
                                  "Login with QR Code”
                                </strong>
                              </li>
                              <li className="font-12 color80 py-1">
                                Scan the code with your phone
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div className="mt-0">
                          <div className="font-18 color1a fw-bold mb-2">
                            Download our App from
                          </div>
                          <button className="border-0 bg-none">
                            <img
                              src="assets/images/signin/google-play.svg"
                              className="img-fluid"
                              alt="google-play"
                            />
                          </button>
                          <button className="border-0 bg-none">
                            <img
                              src="assets/images/signin/app-store.svg"
                              className="img-fluid"
                              alt="google-play"
                            />
                          </button>
                          <p className="font-12 color1a pt-4 telohnbws fw-500">
                            *
                            <a
                              href="terms-and-conditions"
                              className="font-12 colorb fw-500"
                            >
                              Terms and condition
                            </a>{" "}
                            &amp;{" "}
                            <a
                              href="privacy-policy"
                              className="font-12 colorb fw-500"
                            >
                              Privacy policy
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {}
        <>
          <div
            className="modal modal-sheet"
            tabIndex={-1}
            role="dialog"
            id="modalChoice"
          ></div>
          {}
          <div
            className="modal fade lbtk"
            id="Groupsdeletemsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Delete Message
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete all messages and Media?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Delete from all Groups <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="deletemsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Delete Message
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete this message?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Also delete for sakshi <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="GroupPermanentlymsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Permanently Delete{" "}
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete this Group?
                  </p>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="ExitGroupmsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">Exit Group</h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to Exit this Group?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Make admin <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="groupdeletemsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Delete Message
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete this message?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Also delete from everyone <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="ParticipantsModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-header border-0 px-4 pb-0">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    5 Participants
                  </h5>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <i className="mdi mdi-close" />
                  </button>
                </div>
                <div className="modal-body px-4 pt-0 pb-2 text-start">
                  <div className="fstRightGroup pb-3 border-0">
                    <div className="pe-3 pb-3 bg-body">
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="GuestModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-header border-0 px-4 pb-0">
                  <h5 className="mb-2 font-16 color33 fw-bold">2 Guest</h5>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <i className="mdi mdi-close" />
                  </button>
                </div>
                <div className="modal-body px-4 pt-0 pb-2 text-start">
                  <div className="fstRightGroup pb-3 border-0">
                    <div className="pe-3 pb-3 bg-body">
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="AddMemberModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-header flex-column border-0 px-3 pb-0">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h5 className="mb-2 font-16 color33 fw-bold">
                      5 Members added
                    </h5>
                    <button
                      type="button"
                      className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a pe-0"
                      data-bs-dismiss="modal"
                    >
                      <i className="mdi mdi-close" />
                    </button>
                  </div>
                  <div className="msg-search w-100 mx-0">
                    <input
                      type="text"
                      className="form-control bg-none border-0"
                      id="inlineFormInputGroup"
                      placeholder="Search"
                      aria-label="search"
                    />
                    <button>
                      <i className="mdi mdi-magnify" />
                    </button>
                  </div>
                </div>
                <div className="modal-body px-4 pt-0 pb-2 text-start">
                  <div className="fstRightGroup pb-3 border-0">
                    <div className="pb-3 bg-body">
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {}
        <>
          <div
            className="modal modal-sheet"
            tabIndex={-1}
            role="dialog"
            id="modalChoice"
          ></div>
          {}
          <div
            className="modal fade lbtk"
            id="Groupsdeletemsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Delete Message
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete all messages and Media?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Delete from all Groups <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="deletemsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Delete Message
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete this message?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Also delete for sakshi <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="GroupPermanentlymsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Permanently Delete{" "}
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete this Group?
                  </p>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="ExitGroupmsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">Exit Group</h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to Exit this Group?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Make admin <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="groupdeletemsg"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-body px-4 pt-4 pb-2 text-start">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    Delete Message
                  </h5>
                  <p className="mb-0 font-14 color80">
                    Are you sure you want to delete this message?
                  </p>
                  <label className="cstCheck fcheck font-14 color4d mt-3 fw-500">
                    <input type="checkbox" />
                    Also delete from everyone <span className="checkmark" />
                  </label>
                </div>
                <div className="modal-footer flex-nowrap p-0 border-0">
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <strong>Cancel </strong>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                  >
                    <strong>Delete</strong>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="ParticipantsModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-header border-0 px-4 pb-0">
                  <h5 className="mb-2 font-16 color33 fw-bold">
                    5 Participants
                  </h5>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <i className="mdi mdi-close" />
                  </button>
                </div>
                <div className="modal-body px-4 pt-0 pb-2 text-start">
                  <div className="fstRightGroup pb-3 border-0">
                    <div className="pe-3 pb-3 bg-body">
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="GuestModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-header border-0 px-4 pb-0">
                  <h5 className="mb-2 font-16 color33 fw-bold">2 Guest</h5>
                  <button
                    type="button"
                    className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a"
                    data-bs-dismiss="modal"
                  >
                    <i className="mdi mdi-close" />
                  </button>
                </div>
                <div className="modal-body px-4 pt-0 pb-2 text-start">
                  <div className="fstRightGroup pb-3 border-0">
                    <div className="pe-3 pb-3 bg-body">
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-start flex-column align-items-start w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join Time
                            11:35
                          </div>
                          <div className="clock font-10 textb3">
                            <i className="mdi mdi-clock-outline" /> Join
                            Duration 30m
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {}
          <div
            className="modal fade lbtk"
            id="AddMemberModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content rounded-3 shadow">
                <div className="modal-header flex-column border-0 px-3 pb-0">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h5 className="mb-2 font-16 color33 fw-bold">
                      5 Members added
                    </h5>
                    <button
                      type="button"
                      className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a pe-0"
                      data-bs-dismiss="modal"
                    >
                      <i className="mdi mdi-close" />
                    </button>
                  </div>
                  <div className="msg-search w-100 mx-0">
                    <input
                      type="text"
                      className="form-control bg-none border-0"
                      id="inlineFormInputGroup"
                      placeholder="Search"
                      aria-label="search"
                    />
                    <button>
                      <i className="mdi mdi-magnify" />
                    </button>
                  </div>
                </div>
                <div className="modal-body px-4 pt-0 pb-2 text-start">
                  <div className="fstRightGroup pb-3 border-0">
                    <div className="pb-3 bg-body">
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <span className="imggrp1 img25" />
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="font-14 fw-500 color1a hdv">
                            <h3 className="font-14 fw-500 color1a mb-0">
                              Pradhumna Pachaury
                            </h3>{" "}
                            <span className="d-block font-10 fw-500 color80">
                              Research Associate
                            </span>
                          </div>
                          <div className="">
                            <input type="checkbox" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default Login;

import React, { useEffect } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

const AudioMessage = ({
  audioBlob,
  isRecording,
  isPaused,
  recordingDuration,
  handleStartRecording,
  handleStopRecording,
  handlePauseRecording,
  handleResumeRecording,
  handleSendData,
  handlePlayAudio,
  handleCancelRecording,
}) => {
  const recorderControls = useVoiceVisualizer();
    const {
        recordedBlob,
        error,
        audioRef,
    } = recorderControls;
    useEffect(() => {
        if (!recordedBlob) return;

    }, [recordedBlob, error]);

    
    useEffect(() => {
        if (!error) return;

        console.error(error);
    }, [error]);

  return (
    <div>
    
       {}
    </div>
  );
};

export default AudioMessage;

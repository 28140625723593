import React from "react";
import { clamp, get } from "lodash-es";
import ErrorPageComponent from "src/components/ErrorPageComponent";
import { format } from "timeago.js";
import moment from "moment";

export const isObject = (target) =>
  Object.prototype.toString.call(target) === "[object Object]";

export const groupBy = function (xs, key) {
  return xs.reduce((rv, x) => {
    
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const allPossiblePathOfObject = function (tree) {
  const leaves = [];
  const walk = function (obj, path) {
    
    path = path || "";
    for (const n in obj) {
      if (obj.hasOwnProperty(n)) {
        if (n[0] === "$") {
          leaves.push(`${path ? `${path}` : ""}`);
        } else if (
          (typeof obj[n] === "object" || obj[n] instanceof Array) &&
          !(obj[n] instanceof Date)
        ) {
          walk(obj[n], `${path ? `${path}.` : ""}${n}`);
        } else {
          leaves.push(`${path ? `${path}.` : ""}${n}`);
        }
      }
    }
  };
  walk(tree, "");
  return leaves;
};

export const buildSearchWithNoCase = ({
  where,
  fullTextSearch,
  caseSensitiveSearchProperties = [],
}) => {
  const paths = allPossiblePathOfObject(where);
  const dotNotationWhere = {};
  paths.forEach((path) => {
    const value = get(where, path);
    if (value !== undefined && value !== null) {
      if (!fullTextSearch || caseSensitiveSearchProperties.indexOf(path) > -1) {
        dotNotationWhere[path] = value;
      } else if (
        value === "false" ||
        value === "true" ||
        value === false ||
        value === true ||
        typeof value === "number" ||
        typeof value === "object" ||
        Array.isArray(value)
      ) {
        dotNotationWhere[path] = value;
      }
      
      else if (
        path[path.length - 1] === "d" &&
        (path[path.length - 2] === "i" || path[path.length - 2] === "I") &&
        value.toString().length === 24
      ) {
        dotNotationWhere[path] = value;
      } else {
        dotNotationWhere[path] = {
          $regex: `^${value}`,
          $options: "i",
        };
      }
    }
  });

  return dotNotationWhere;
};

export const setEmptyStringToNull = function (tree) {
  const walk = function (target) {
    for (const key in target) {
      if (Object.prototype.hasOwnProperty.call(target, key)) {
        if (typeof target[key] === "object" || target[key] instanceof Array) {
          walk(target[key]);
        } else if (typeof target[key] === "string" && target[key] === "") {
          target[key] = null;
        }
      }
    }
  };
  walk(tree);
  return tree;
};

export function permissionsToTreeStructure(permissions, path = "") {
  if (!permissions) {
    return [];
  }

  const keys = Object.keys(permissions);

  const scopesAtLevel = [];

  keys.forEach((key) => {
    const value = permissions[key];
    let children = null;
    const currentPath = path ? `${path}.${key}` : key;

    if (value.toString() === "[object Object]") {
      children = permissionsToTreeStructure(value, currentPath);
    }

    scopesAtLevel.push({
      value: currentPath,
      label: key,
      children,
    });
  });

  return scopesAtLevel;
}

export function hasPermission({ scope, scopeList }) {
  if (!scopeList.length) {
    return false;
  }

  if (!scope) {
    return true;
  }

  const scopeSplit = scope.split(".");

  const has = scopeList.find((permission) => {
    const permissionSplit = permission.split(".");
    const result = scopeSplit.every(
      (item, index) => item === permissionSplit[index]
    );
    return result;
  });

  return Boolean(has);
}

export const UnAuthorizedErrorPage = () => (
  <ErrorPageComponent code="401" title="Unauthorized" />
);


export const formatSize = (size) => {
  if (size < 1024) {
    return size + " KB";
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
};

export const getStorageUsageGroupBy = (data) => {
  const groupedData = {
    Total: { count: 0, totalSize: 0 },
    Text: { count: 0, totalSize: 0 },
    Images: { count: 0, totalSize: 0 },
    Video: { count: 0, totalSize: 0 },
    Audio: { count: 0, totalSize: 0 },
    Documents: { count: 0, totalSize: 0 },
    Others: { count: 0, totalSize: 0 },
    Messages: { count: 0, totalSize: 0 }, // Added Messages category
  };

  (data.files || []).forEach((item) => {
    const fileType = item._id;
    const count = item.count;
    const totalSize = item.totalSize;
    console.log(",,,file type", fileType);

    if (fileType === null) {
      groupedData.Messages.count += count;
      groupedData.Messages.totalSize += totalSize;
    } else if (fileType.startsWith("image/")) {
      groupedData.Images.count += count;
      groupedData.Images.totalSize += totalSize;
    } else if (fileType.startsWith("video/")) {
      groupedData.Video.count += count;
      groupedData.Video.totalSize += totalSize;
    } else if (fileType.startsWith("audio/")) {
      groupedData.Audio.count += count;
      groupedData.Audio.totalSize += totalSize;
    } else if (
      fileType.startsWith("application/pdf") ||
      fileType.startsWith("application/msword") ||
      fileType.startsWith("application/vnd.openxmlformats-officedocument")
    ) {
      groupedData.Documents.count += count;
      groupedData.Documents.totalSize += totalSize;
    } else {
      groupedData.Others.count += count;
      groupedData.Others.totalSize += totalSize;
    }

    groupedData.Total.count += count;
    groupedData.Total.totalSize += totalSize;
  });

  (data.chats || []).forEach((item) => {
    const count = item.count;
    const totalSize = item.totalSize;
    groupedData.Messages.count += count; // Count chats in Messages category
    groupedData.Messages.totalSize += totalSize; // Total size in Messages category
    groupedData.Total.count += count;
    groupedData.Total.totalSize += totalSize;
  });
console.log("groupedData",groupedData);

  for (const category in groupedData) {
    groupedData[category].totalSize = formatSize(groupedData[category].totalSize);
  }

  console.log("groupedData*****",groupedData);
  return groupedData;
};


// // Mock formatSize function
// const formatSize = (size) => {
//   if (size < 1024) return size + ' B';
//   else if (size < 1048576) return (size / 1024).toFixed(2) + ' KB';
//   else if (size < 1073741824) return (size / 1048576).toFixed(2) + ' MB';
//   else return (size / 1073741824).toFixed(2) + ' GB';
// };

export const generateStrongPassword = (length = 12) => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}:"<>?[];,./`~';
  return Array.from(
    { length },
    () => chars[Math.floor(Math.random() * chars.length)]
  ).join("");
};

export const copyToClipboard = (value) => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
};


const unitMap = {
  B: 1,
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
};

const parseSizeToBytes = (sizeString) => {
  const [size, unit] = sizeString.split(" ");
  return parseFloat(size) * unitMap[unit];
};

export const getTotalSize = (data) => {
  let totalBytes = 0;
  
  for (const key in data) {
    const size = data[key];
    totalBytes += parseSizeToBytes(size);
  }
  return totalBytes;
};

export const calculateOccupiedPercentage = (occupiedSize, totalAllocation) => {
  
  const occupiedBytes = parseSizeToBytes(occupiedSize);
  const totalAllocationBytes = parseSizeToBytes(totalAllocation);
  
  const percentage = (occupiedBytes / totalAllocationBytes) * 100;

  return percentage.toFixed(2);
};
export const time_ago = (date_time) => {
  return format(moment(date_time).format("x"));
};
export const getCurrentTime = (date) => {
  const currentDate = date ? new Date(date) : new Date();
  const today = new Date();
  const isToday =
    currentDate.getDate() === today.getDate() &&
    currentDate.getMonth() === today.getMonth() &&
    currentDate.getFullYear() === today.getFullYear();

  let timeString;

  if (isToday) {
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    timeString = hours + ":" + minutes + " " + ampm;
  } else {
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    minutes = minutes < 10 ? "0" + minutes : minutes;
    timeString = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  return timeString;
};



export const formatDate = (date) => {
  const givenDate = date ? new Date(date) : new Date();
  const weekDay = givenDate.toLocaleString("en-US", { weekday: "short" });
  const month = givenDate.toLocaleString("en-US", { month: "short" });
  const day = givenDate.getDate().toString().padStart(2, "0");
  const year = givenDate.getFullYear();
  return `${weekDay}, ${month} ${day}, ${year}`;
};

export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const getLast7Days = () => {
  const today = new Date();
  const last7Days = new Date();
  last7Days.setDate(today.getDate() - 6);
  return { start: last7Days, end: today };
};

export const getThisMonth = () => {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth(), 1);
  const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return { start, end };
};

export const getLastMonth = () => {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const end = new Date(today.getFullYear(), today.getMonth(), 0);
  return { start, end };
};

export const checkIfLinkType = (fileIds, message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  if (urlRegex.test(message)) {
    return fileIds?.length > 0 ? "media" : "link";
  } else {
    return fileIds?.length > 0 ? "media" : "text";
  }
};

export const debounceFun = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export default {
  isObject,
  groupBy,
  allPossiblePathOfObject,
  buildSearchWithNoCase,
  setEmptyStringToNull,
  permissionsToTreeStructure,
  hasPermission,
  UnAuthorizedErrorPage,
  getStorageUsageGroupBy,
  generateStrongPassword,
  copyToClipboard,
  getTotalSize,
  formatSize,
  calculateOccupiedPercentage,
  time_ago,
  getCurrentTime,
  checkIfLinkType,
  debounceFun,
};

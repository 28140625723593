import React from 'react';
import { getCurrentTime } from "src/utils/CommonUtils"; 

const MemberMessageTabList = (props) => {
  const {
    resetAndToggleModal,
    getRootProps,
    userList,
    filterUserList,
    startChat,
    getInputProps,
    searching,
    searchedUserItems,
    selectedUserOrGroup,
    inactiveSearchValue
  } = props;

  
// Render profile picture or initials
const renderProfilePicture = (participantsUser) => {
  const profilePicture = participantsUser?.otherInformation?.profilePicture;
  const firstNameInitial = participantsUser?.firstName?.charAt(0);
  const lastNameInitial = participantsUser?.lastName?.charAt(0);

  if (profilePicture) {
    return (
      <img
        className="img-fluid userimg"
        src={profilePicture}
        width="40"
        height="40"
        alt="user img"
        style={{
          border: `solid ${participantsUser?.isOrange ? '#ff7a00' : '#00a8ff'}`,
          borderWidth: '2px',
        }}
        loading='lazy'
      />
    );
  } else {
    return (
      <div
        className="placeholder-img"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: '#e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `solid ${participantsUser?.isOrange ? '#ff7a00' : '#00a8ff'}`,
          borderWidth: '2px',
          color: '#555',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {`${firstNameInitial || ''}${lastNameInitial || ''}`}
      </div>
    );
  }
};

  return (
    <div
    >
      {inactiveSearchValue?.length > 0 ? (
        <div
        className="tab-pane fade show active"
        id="employee"
        role="tabpanel"
        aria-labelledby="employee-tab"
      >
        <div
          className="chat-list"
          {...getRootProps()}
          onClick={(e) => {
            e.preventDefault();
            resetAndToggleModal();
          }}
        >
          {searchedUserItems}
        </div>
        </div>
      ) : (
        <div
          className="chat-list"
          {...getRootProps()}
          onClick={(e) => {
            e.preventDefault();
            resetAndToggleModal();
          }}
        >
          {userList &&
            filterUserList(userList).map((item, index) => (
              <div
              id="employee"
              role="tabpanel"
              aria-labelledby="employee-tab"
              className={`tab-pane fade show active ${(selectedUserOrGroup?._id === item?._id) ? "selectedChatBox" : ""}`}
            >
              <a
                href="#"
                className="d-flex align-items-start"
                key={index}
                onClick={(e) => startChat(item)}
                onDrop={(e) => startChat(item)}
              >
                <div className="flex-shrink-0 position-relative imgUserLs">
                {renderProfilePicture(item)}
                  {item.checkIcon && <span className="active"></span>}
                </div>

                <div className="flex-grow-1 ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <input {...getInputProps()} multiple />
                    <h3>
                      {item?.firstName} {item?.lastName}
                    </h3>
                  </div>
                  {item.action && (
                    <div
                      className={`font-14 d-flex align-items-center text66 ${
                        item.mdiIconClass ? "image" : "miscall"
                      }`}
                    >
                      {item.mdiIconClass && (
                        <i
                          className={`mdi ${item.mdiIconClass} font-20 pe-1`}
                        ></i>
                      )}
                      {item.action}
                    </div>
                  )}
                  <div className="onlineOfine d-flex justify-content-between align-items-center">
                    <div className="clock font-14 textb3">
                      {getCurrentTime(item?.otherInformation?.lastSeen)}
                    </div>
                    {item.checkIcon && (
                      <div className="checkonline font-14">
                        <i className="mdi mdi-check-all"></i>
                      </div>
                    )}
                  </div>
                </div>
              </a>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MemberMessageTabList;

import { createSlice } from "@reduxjs/toolkit";

const chatListSlice = createSlice({
  name: "chatList",
  initialState: { chats: [] },
  reducers: {
    addChat: (state, action) => {
      state.chats.push(action.payload);
    },
    updateChat: (state, action) => {
      const { updatedData } = action.payload;
      if (!updatedData?.roomId) {
        console.error("No roomId provided in updatedData");
        return;
      }
    
      const index = state?.chats.findIndex((chat) => chat?.roomId === updatedData?.roomId);
      if (index !== -1) {
        const existingChat = state.chats[index];
        state.chats[index] = {
          ...existingChat,
          ...updatedData,
          unRead: (existingChat.unRead || 0) + updatedData?.unRead 
        };
        const updatedChat = state.chats.splice(index, 1)[0];
        state.chats.unshift(updatedChat);
      } else {
        state.chats.unshift({
          ...updatedData,
          unRead:updatedData?.unRead 
        });
      }
    },
    resetUnReadCount: (state, action) => {
      const { roomId } = action.payload;
      if (!roomId) {
        console.error("No roomId provided for resetting unread count");
        return;
      }
      const index = state.chats.findIndex((chat) => chat.roomId === roomId);
      if (index !== -1) {
        state.chats[index].unRead = 0;
      } else {
        console.warn(`Room with roomId ${roomId} not found`);
      }
    },
    removeChat: (state, action) => {
      state.chats = state.chats.filter((chat) => chat._id !== action.payload);
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setUniqueChats: (state, action) => {
      const uniqueList = [
        ...new Map(action.payload.map((item) => [item["_id"], item])).values(),
      ];
      state.chats = uniqueList;
    },
    updateUnreadCount: (state, action) => {
      const { id, unreadCount } = action.payload;
      const index = state.chats.findIndex((chat) => chat._id === id);
      if (index !== -1) {
        state.chats[index].unRead = unreadCount;
      }
    },
  },
});

export const {
  addChat,
  updateChat,
  removeChat,
  setChats,
  setUniqueChats,
  updateUnreadCount,
  resetUnReadCount 
} = chatListSlice.actions;

export default chatListSlice.reducer;

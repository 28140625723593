import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Head from "../layout-container/Head";
import HeaderNav from "../layout-container/HeaderNav";
import Leftbar from "../layout-container/Leftbar";
import Chatlist from "../include-container/Chatlist";
import Chatbox from "../include-container/Chatbox";
import Profilepopup from "../include-container/Profilepopup";
import Notespopup from "../include-container/Notespopup";
import Rightbar from "../layout-container/Rightbar";
import { useDropzone } from "react-dropzone";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Profile from "../profile-container/Profile";
import InfoDrawer from "src/components/infoDrawer/index";
import DeleteMessage from "../include-container/DeleteMessage";
import AccountSettingContainer from "../account-container/AccountSettingContainer";
import {
  UploadFile,
  getDeactivateGroupsList,
  getDeactivateUserList,
  getSearchUserList,
  getUserDetails,
  getUserList,
  updateLastSeen,
} from "../profile-container/ProfileServices";
import useDebounce from "src/hooks/useDebounce";
import { useNotification } from "src/components/Notifination/ToastNotification";
import UserListPopup from "src/components/chatbox/UserListPopup";
import { userProfileInitialState } from "../profile-container/ProfileDetailsutils";
import AddGroupDrawer from "src/components/addGroupDrawer/index";
import {
  getUserGroupList,
  joinGroup,
  getDetails,
} from "../../containers/home-container/apis/Groupapis";
import GroupDetails from "../include-container/GroupDetails";
import Reminders from "../include-container/Reminders";
import PinViewPopUp from "../include-container/PinViewPopUp";
import NotificationPanel from "../include-container/NotificationPannel";
import { useVoiceVisualizer } from "react-voice-visualizer";
import NotificationView from "../include-container/NotificationView";
import {
  checkIfLinkType,
  formatDate,
  getCurrentTime,
  debounceFun,
} from "src/utils/CommonUtils";
import { resetFile } from "../Media-container/UserChatMedia/UserMedia";
import UserMedia from "../Media-container/UserChatMedia/UserMedia";
import UserAppMedia from "../Media-container/UserAppMedia/UserAppMedia";
import { unAcceptedExtensions } from "src/utils/utils";
import Todolist from "../include-container/TodoContainer/Todolist";
import FavList from "../include-container/FavList";
import { SocketContext } from "src/context/SocketContext";
import { cancelReminder } from "./apis/Remindersapis";
import MediaContainer from "./MediaContainer";
import { chatBoxUpdateCurrentRecoardAction } from "../chatbox-container/actions/ChatBoxActions";
import {
  currentGroup,
  setUserMessages,
  addNewMessage,
} from "./actions/ChatHistoryAction";
import UserStatus from "src/components/sideUserGroupList/UserStatus";
import DeleteMessageGroup from "../include-container/DeleteMessageGroup";
import { getFileExtension } from "src/libs/common";
import ConnectivityDialog from "src/components/UserStatus/ConnectivityDialog";
import PasswordRessetPopup from "../include-container/PasswordRessetPopup";
import { setUsers } from "src/redux/slices/UserSlice";
import { addGroup } from "src/redux/slices/groupsSlice";
import CheckInternet from "src/CheckInternet/CheckInternet";
import { setUniqueChats, updateChat } from "src/redux/slices/chatListSlice";
const audio = new Audio("./assets/media/syncupTone.mp3");
const audioPath = "/build/assets/media/syncupTone.mp3";
const apiKey = "a08fd5caf1d201";
let CurrentUserStatus = "auto";
let isCancelled = false;

const fetchData = async () => {
  try {
    const ipResponse = await fetch(
      "https://api.ipgeolocation.io/ipgeo?apiKey=9bef10d9cf88449f8ef4d8b2c4447407"
    );

    if (!isCancelled) {
      const deviceInfo = getDeviceInfo();
      const ipData = await ipResponse.json();
      ipData.deviceInfo = deviceInfo;
      localStorage.setItem("ipData", JSON.stringify(ipData));
    }
  } catch (error) {}
};

const groupDetails_close = () => {
  const mainElement = document.querySelector("#main");
  const groupDetailsElement = document.querySelector("#groupDetails");
  if (mainElement) {
    mainElement.style.marginRight = "0%";
  }

  if (groupDetailsElement) {
    groupDetailsElement.style.display = "none";
  }
};

fetchData();

function getDeviceInfo() {
  const deviceInfo = {
    browser: navigator?.appCodeName,
    platform: navigator.platform,
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
    },
    language: navigator.language,
    userAgent: navigator.userAgent,
  };
  return deviceInfo;
}

const Home = () => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const chatHistoryReducer = useSelector((state) => state.chatHistoryReducer);
  const state = useSelector((state) => state);

  const userMessages = chatHistoryReducer?.userMessages;
  const recipient = chatHistoryReducer.recipient;
  // console.log("Home userMessages ==================>", userMessages);
  // console.log("Home recipient ==================>", recipient);

  const [searchFromList, setSearchFromList] = useState("chatHistory");
  const [tabValue, setTabValue] = useState("chatHistory");
  const accountSetting = useSelector(
    (state) => state.AccountReducer.currentRecord,
    shallowEqual
  );
  if (!user) {
    navigate("/");
  }

  const initialMessage = {
    messageHtml: "",
    messageText: "",
  };
  const listRef = useRef(null);
  const chatListRef = useRef(null);
  const targetRef = useRef(null);
  const [mesageInfoFlag, setMesageInfoFlag] = useState(true);
  const [openedDrawer, setOpenedDrawer] = useState(null);
  const [open, setOpen] = useState(false);
  const [openedRightBarDrawer, setOpenedRightBarDrawer] = useState(null);
  const [isPinDrawerOpen, setIsPinDrawerOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(null);
  const [userId, setUserId] = useState(null);
  const [receiverData, setReceiverData] = useState("");
  const [message, setMessage] = useState("");
  const [lastReadMessageIdState, setLastReadMessageIdState] = useState(null);
  const [liveStatus, setLiveStatus] = useState(user?.liveStatus);
  const [searchActionUsersResponse, setSearchActionUsersResponse] = useState(
    []
  );
  const [UnReadChat, setUnReadChat] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [deActivatedUsers, setDeActivatedUsers] = useState([]);
  const [deActivatedGroups, setDeActivatedGroups] = useState([]);

  const [inactiveSearchValue, setInactiveSearchValue] = useState("");
  const [inactiveValue, setInactiveValue] = useState("all");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const allUser = useSelector((state) => state.chatList.chats);

  const scrollToItem = (id) => {
    setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        targetRef.current.focus();
      }
    }, 3000);
  };

  const [fileNames, setFileNames] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [selectedUserOrGroup, setSelectedUserOrGroup] = useState(null);
  const [selectedGroupOpen, setSelectedGroupOpen] = useState(false);
  const [chatMessage, setChatMessage] = useState(initialMessage);
  const [textMessage, setTextMessage] = useState("");
  const [messageActionFlag, setMessageActionFlag] = useState(false);
  const [messageFilterFlag, setMessageFilterFlag] = useState(false);
  const [selectMessages, setSelectMessages] = useState([]);
  const [mediaToForward, setMediaToForward] = useState(null);
  const [isMessageActionOpen, setIsMessageActionOpen] = useState(true);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const [replyToMessage, setReplyToMessage] = useState(null);
  const [replyToMedia, setReplyToMedia] = useState(null);
  const [isLoadingMessage, setIsLoadingMessage] = useState(true);
  const {
    openSuccessNotification,
    openErrorNotification,
    openWarningNotification,
  } = useNotification();

  const editorRef = useRef(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const [showAudioRecorder, setShowAudioRecorder] = useState(false);
  const userList = useSelector((state) => state?.activeUser?.users);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const debouncedSearch = useDebounce(searchText, 500);
  const [location, setLocation] = useState(null);
  const [conversationMessage, setConversationMessage] = useState({});
  const [searchMessageString, setSearchMessageString] = useState(null);
  const debouncedSearchMessageString = useDebounce(searchMessageString, 500);
  const [searchMessageType, setSearchMessageType] = useState("text");
  const [dateRange, setDateRange] = useState({
    start: formatDate(),
    end: formatDate(),
  });
  const debouncedSearchOnMessage = useDebounce(searchMessageString, 500);
  const [loader, setLoader] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [revelingConMessage, setRevelingConMessage] = useState(null);
  const [revelInConversationMessages, setRevelInConversationMessages] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [roomType, setRoomType] = useState("single");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState("");
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [taggedUserSelection, setTaggedUserSelection] = useState("");
  const [searchUsersResponse, setSearchUsersResponse] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const recorderControls = useVoiceVisualizer();
  const {
    recordedBlob,
    error,
    audioRef,
    isCleared,
    isPausedRecording,
    isRecordingInProgress,
    clearCanvas,
  } = recorderControls;
  const getUserProfileDetails = async (userChatIdInfo, rmTyp) => {
    setRoomType(rmTyp);
    if (rmTyp === "single") {
      try {
        const { data } = await getUserDetails(userChatIdInfo, userId);
        setUserDetails(() => userProfileInitialState(data));
      } catch (error) {}
    }
  };
  let repliedToConversation = false;
  const [groupList, setGroupList] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [isGroupEditable, setIsGroupEditable] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [noticeNotification, setNoticeNotification] = useState({});
  const [one2oneTyping, setOne2oneTyping] = useState(null);
  const [groupTyping, setGroupTyping] = useState(null);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [stream, setStream] = useState(null);
  const [selectedTaggedUserIds, setSelectedTaggedUserIds] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [totalUnReadGlobal, setTotalUnReadGlobal] = useState(0);
  const [isScrollToBottomDisable, SetIsScrollToBottomDisable] = useState(true);
  const [currentActiveTab, setSetCurrentActiveTab] = useState("chat");
  const [socketId, setSocketId] = useState(socket.id);
  // console.log("socket============>", socket)
  const profile_close = () => {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatProfile").style.display = "none";
  };

  const debouncedDeSearch = useDebounce(inactiveSearchValue, 500);
  const fetchDeactivatedUsersList = async () => {
    const { data } = await getDeactivateUserList(inactiveSearchValue);
    if (data?.user) {
      setDeActivatedUsers([...data?.user]);
    }
  };

  const fetchDeactivatedGroupList = async () => {
    const { data } = await getDeactivateGroupsList(inactiveSearchValue);
    if (data) {
      setDeActivatedGroups([...data]);
    }
  };
  useEffect(() => {
    const fetchDeactivatedData = async () => {
      if (inactiveValue === "deActiveUsers") {
        await fetchDeactivatedUsersList();
      }
      if (inactiveValue === "deActiveGroups") {
        await fetchDeactivatedGroupList();
      }
    };

    if (debouncedDeSearch) fetchDeactivatedData();
  }, [debouncedDeSearch, inactiveSearchValue]);

  const playAudio = () => {
    try {
      audio.play();
    } catch (error) {}
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 10) {
        openWarningNotification(
          "You can only upload up to 10 files at a time."
        );
        return;
      }
      const maxFileSize = 2 * 1024 * 1024 * 1024;
      const filteredFiles = [];
      const invalidFiles = [];
      const fileNamesSet = new Set();
      acceptedFiles.forEach((file) => {
        const extension = getFileExtension(file.name);
        if (fileNamesSet.has(file.name)) {
          openWarningNotification(
            `File ${file.name} is a duplicate and cannot be uploaded.`
          );
        }
        if (unAcceptedExtensions?.includes(extension?.toLocaleLowerCase())) {
          invalidFiles.push(file);
        } else if (file.size > maxFileSize) {
          openWarningNotification(
            `File ${file.name} is larger than 2 GB and cannot be uploaded.`
          );
        } else {
          filteredFiles.push(file);
          fileNamesSet.add(file.name);
        }
      });
      if (invalidFiles.length > 0) {
        const invalidExtensions = invalidFiles.map((file) =>
          getFileExtension(file.name)
        );
        openWarningNotification(
          `Unsupported file extensions: ${invalidExtensions.join(", ")}.`
        );
      } else if (filteredFiles.length === acceptedFiles.length) {
        handleFileUpload(filteredFiles);
      }
    },
  });

  async function requestNotificationPermission() {
    if (Notification.permission !== "granted") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  async function handlePermission() {
    try {
      const permissionGranted = await requestNotificationPermission();
      if (permissionGranted) {
        return;
      } else {
      }
    } catch (error) {}
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {}
      );
    } else {
    }
  };
  const fetchUserList = async () => {
    try {
      const { data } = await getUserList();
      if (data) {
        dispatch(setUsers(data?.user));
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchSearchUserList = async () => {
    const { data } = await getSearchUserList(searchText);
    if (data?.users) {
      setSearchedUsers([...data?.users]);
    }
    if (searchText === "") {
      setSearchedUsers([]);
      fetchUserList();
    }
  };

  useEffect(() => {
    if (debouncedSearch) fetchSearchUserList();
  }, [debouncedSearch]);

  const fetchUserChats = () => {
    if (!socket || !chatRoomId) return;
    const data = {
      userId: user?._id,
      roomId: chatRoomId,
    };
    if (searchMessageString && searchMessageString?.length > 0) {
      socket.emit("searchMessageByString", {
        ...data,
        messageString: searchMessageString,
        messageType: searchMessageType,
        startDate: dateRange.start.format("YYYY-MM-DD"),
        endDate: dateRange.end.format("YYYY-MM-DD"),
      });
    } else {
      if (searchMessageString !== null) {
        console.log("Fetching initial messages...");
        socket.emit("initialMessages", data);
        socket.on("initialMessages", handleInitialMessages);
      } else {
        socket.on("initialMessages", handleInitialMessages);
      }
    }
  };

  useEffect(() => {
    if (debouncedSearchOnMessage !== undefined) {
      fetchUserChats();
    }
    return () => {
      if (socket) {
        socket.off("initialMessages", handleInitialMessages);
      }
    };
  }, [debouncedSearchOnMessage, chatRoomId]); // Add chatRoomId as a dependency in case it changes

  useEffect(() => {
    fetchUserGroups();
  }, []);

  useEffect(() => {
    CurrentUserStatus = liveStatus;
  }, [liveStatus]);

  useEffect(() => {
    if (!selectedUserOrGroup) {
      localStorage.removeItem("chatRoomId");
    }
    localStorage.removeItem("searchMessageString");

    // localStorage.removeItem("selectedGroup");

    const selectedgrp = JSON.parse(localStorage.getItem("selectedGroup"));
    if (
      selectedgrp &&
      selectedgrp?.noParticipants &&
      !selectedgrp?.noParticipants?.includes(user?._id)
    ) {
      const handleGroupTypingResponse = ({ response }) => {
        setGroupTyping(response);
      };

      const handleStopGroupTypingResponse = ({ response }) => {
        setGroupTyping(response);
      };

      // Setting up the socket event listeners
      socket.on("groupTypingResponse", handleGroupTypingResponse);
      socket.on("stopGroupTypingResponse", handleStopGroupTypingResponse);

      // Cleanup function to remove the event listeners when the effect is re-run or component unmounts
      return () => {
        socket.off("groupTypingResponse", handleGroupTypingResponse);
        socket.off("stopGroupTypingResponse", handleStopGroupTypingResponse);
      };
    }
  }, [selectedUserOrGroup]);

  useEffect(() => {
    socket.on("deleteResponse", (data) => {
      dispatch(
        setUserMessages({
          userMessages: data?.messages?.map(formatMessage),
          unReadMessageCounter: null,
        })
      );
    });
  }, [userMessages]);

  // useEffect(() => {
  //   if (!selectedUserOrGroup) {
  //     localStorage.removeItem("chatRoomId");
  //   }
  //   let selectedgrp = JSON.parse(localStorage.getItem("selectedGroup"));
  //   if (
  //     selectedgrp &&
  //     selectedgrp?.noParticipants &&
  //     !selectedgrp?.noParticipants?.includes(user?._id)
  //   ) {
  //     socket.on("groupTypingResponse", ({ response }) => {
  //       setGroupTyping(response);
  //     });

  //     socket.on("stopGroupTypingResponse", ({ response }) => {
  //       setGroupTyping(response);
  //     });
  //   }
  // }, [selectedUserOrGroup]);

  // useEffect(() => {
  //   socket?.on("messageResponse", handleReceivedMessage);

  //   socket.on("groupTypingResponse", ({ response }) => {
  //     setGroupTyping(response);
  //   });

  //   socket.on("stopGroupTypingResponse", ({ response }) => {
  //     setGroupTyping(response);
  //   });
  // }, [socket]);

  const fetchUserGroups = async () => {
    // try {
    //   let selectedGroup = JSON.parse(localStorage.getItem("selectedGroup"));
    //   const { data } = await getUserGroupList("");
    //   setGroupList([...data]);
    //   if (selectedGroup) {
    //     let noGroup = null;
    //     data.map((itm, ind) => {
    //       if (selectedGroup?._id == itm?._id) {
    //         noGroup = itm;
    //       }
    //     });
    //     setSelectedUserOrGroup(noGroup);
    //     localStorage.setItem("selectedGroup", JSON.stringify(noGroup));
    //   }
    // } catch (error) {}
    return;
  };
  useEffect(() => {
    const fetchUserGroupList = async () => {
      try {
        const { data } = await getUserGroupList("");
        // console.log("useEffect group data", data);
        setGroupList([...data]);
      } catch (error) {
        console.log("Error fetching group list:", error);
      }
    };

    fetchUserGroupList();
  }, []);
  const fetchUserGroupsDetails = async () => {
    try {
      const { data } = await getUserGroupList("");
      // console.log("click group data", data);

      setGroupList([...data]);
      // let selectedGroup = JSON.parse(localStorage.getItem("selectedGroup"));
      // if (selectedGroup) {
      //   let noGroup = data.find((itm) => selectedGroup?._id === itm?._id) || null;
      //   console.log("noGroup",noGroup);
      //   setSelectedUserOrGroup(noGroup);
      //   localStorage.setItem("selectedGroup", JSON.stringify(noGroup));
      // }
    } catch (error) {
      console.error("Error fetching user groups:", error);
    }
  };

  useEffect(() => {
    const getUserIdsFromIndexes = (userList, selectedIndexes) => {
      return selectedIndexes
        ?.map((index) => userList[index]?._id)
        .filter(Boolean);
    };

    const ids = getUserIdsFromIndexes(userList, selectedIndexes);
    setSelectedTaggedUserIds(ids);
  }, [userList, selectedIndexes]);

  useEffect(() => {
    const updateSocketId = () => setSocketId(socket.id);
    updateSocketId();
    socket.on("connect", updateSocketId);
    return () => {
      socket.off("connect", updateSocketId);
    };
  }, [socket]);

  useEffect(() => {
    // console.log("useEffect socket============>",socket)
    if (user) {
      setUserId(user?._id);
      if (socket?.connected) {
        // console.log("useEffect socket handleInitialMessages============----------------------------------------->", socket?.connected)

        socket?.emit("userOnline", {
          userId: user?._id,
          organizationId: user?.organization,
        });

        socket?.emit("getMyStatus", {
          userId: user?._id,
          organizationId: user?.organization,
        });
        socket.on("myStatusResponse", handleStatusResponse);
        socket?.on(
          "searchActionUserResponse",
          handleSocketSearchedActionUserListResponse
        );
        socket?.on("messageResponse", handleReceivedMessage);

        // socket?.on("initialMessages", handleInitialMessages);

        socket?.on(
          "searchMessageByStringResponse",
          handleSearchMessagesResponse
        );
        socket?.emit("getChatList", {
          userId: user?._id,
          organizationId: user?.organization,
        });

        // console.log("useEffect selectedGroup============>", localStorage.getItem("selectedGroup"));
        // console.log("useEffect chatRoomId============>", localStorage.getItem("chatRoomId"));
        if (localStorage.getItem("chatRoomId") && socket) {
          if (localStorage.getItem("selectedGroup")) {
            let selectedgrp = JSON.parse(localStorage.getItem("selectedGroup"));
            joinGroupFun(selectedgrp);
          } else {
            setChatRoomId(localStorage.getItem("chatRoomId"));
            subscribeSocket(localStorage.getItem("chatRoomId"));
          }
        }
      }
      // Cleanup function to unsubscribe from socket events and close the socket if needed
      return () => {
        if (socket?.connected) {
          socket.off("messageResponse", handleReceivedMessage);
          socket.off("myStatusResponse", handleStatusResponse);
          socket.off(
            "searchActionUserResponse",
            handleSocketSearchedActionUserListResponse
          );
          socket.off("initialMessages", handleInitialMessages);
          socket.off(
            "searchMessageByStringResponse",
            handleSearchMessagesResponse
          );
        }
      };
    }
  }, [user, socketId]);

  useEffect(() => {
    const handleReloadUser = (data) => {
      const { organizationId, userUpdateId } = data;
      if (userUpdateId === user?._id) {
        window.location.reload();
      }
    };
    socket.on("reloadUser", handleReloadUser);
    return () => {
      socket.off("reloadUser", handleReloadUser);
    };
  }, [socket]);

  const joinGroupFun = async (selectedGroup) => {
    try {
      let { data } = await getDetails(selectedGroup?._id);
      if (socket && data) {
        setSelectedUserOrGroup(data.groups[0]);
        joinGroup(socket, user?._id, selectedGroup?._id, user?.organization);
        setReceiverData(data.groups[0]);
        localStorage.setItem(
          "selectedUserOrGroup",
          JSON.stringify(data.groups[0])
        );
      }
    } catch (error) {
      console.log("Error joining group:", error);
    }
  };

  const getStatusIcon = (liveStatus) => {
    switch (liveStatus) {
      case "auto":
      case "custom":
        return '<span class="d-inline-block mdi mdi-circle text-green"></span>';
      case "dnd":
        return '<span class="d-inline-block mdi mdi-minus-circle text-red wdhs"></span>';
      case "meeting":
        return '<span class="d-inline-block mdi mdi-clock text-yellow wdhs"></span>';
      case "wfh":
        return '<span class="d-inline-block mdi mdi-home text-blue wdhs"></span>';
      case "away":
        return '<span class="d-inline-block mdi mdi-run wdhs"></span>';
      default:
        return '<span class="d-inline-block rounded-circle p-1 mdi mdi-circle"></span>';
    }
  };

  const getStatusText = (liveStatus) => {
    switch (liveStatus) {
      case "auto":
        return "Active";
      case "custom":
        return "Custom";
      case "dnd":
        return "Do Not Disturb";
      case "meeting":
        return "Meeting";
      case "wfh":
        return "Work from Home";
      case "away":
        return "Away";
      default:
        return "Active";
    }
  };

  const handleStatusResponse = ({ status }) => {
    let userStElement = document.querySelector("#userSt");
    if (userStElement) {
      const icon = getStatusIcon(status?.liveStatus);
      const text = getStatusText(status?.liveStatus);
      userStElement.innerHTML = `${icon} ${text}`;
    }
  };

  const handleUnreadCounts = (count) => {
    setUnreadNotification(count);
  };
  useEffect(() => {
    socket.emit("getUnreadCounts");
    socket.on("unreadCounts", handleUnreadCounts);
  }, [unreadNotification]);

  // useEffect(() => {
  //  const groups =  groupList?.map(g=>g?._id)
  //  groups?.map(id=> joinGroup(socket, user?._id, id, user?.organization))
  //   // joinGroup(socket, user?._id, selectedGroup?._id, user?.organization);
  // }, [groupList]);

  // useEffect(() => {
  //   dispatch(setUserMessages({ userMessages: revelInConversationMessages.map(formatMessage), unReadMessageCounter: null }));
  // }, [revelInConversationMessages]);

  const startChat = (userInfo) => {
    // console.log("startChat=======================>")
    // setSelectedRoomId(userInfo?._id);
    setLastElementId(null);
    setScrollPosition(null);
    setMoreTopMessages(true);
    setNewMessage(null);
    setNewChatLoad(true);
    setDisplayChatCounter(0);
    if (userInfo) {
      localStorage.removeItem("selectedUserOrGroup");
      if (userInfo) {
        if (user) {
          setUserId(user?._id);
        } else {
          navigate("/");
        }
        setSelectedUserOrGroup(userInfo);
        setReceiverData(userInfo);
        checkRoomSocket(userInfo?._id);
        dispatch(chatBoxUpdateCurrentRecoardAction(userInfo));
        dispatch(currentGroup({}));
      }
    }
  };
useEffect(()=>{
  const totalUnReadCountsAllUsers = allUser?.reduce((total, item) => {
    return total + (item?.unRead || 0); 
  }, 0); 
  
  setTotalUnReadGlobal(totalUnReadCountsAllUsers);
},[allUser])
  const checkRoomSocket = (userID) => {
    const handleRoomResponse = (response) => {
      handleSocketResponse(response);
      socket.off("roomResponse", handleRoomResponse);
    };

    socket.emit("checkRoom", {
      users: [userId, userID],
      organization: user?.organization,
      type: "single",
    });

    socket.on("roomResponse", handleRoomResponse);


    // // console.log("checkRoomSocket")
    // if (socket?.connected) {
    //   socket?.emit("checkRoom", {
    //     users: [userId, userID],
    //     organization: user?.organization,
    //     type: "single",
    //   });
    //   socket?.on("roomResponse", handleSocketResponse);
    // }
    // // Cleanup function to unsubscribe from socket events and close the socket if needed
    // return () => {
    //   if (socket) {
    //     socket.off("roomResponse", handleSocketResponse);
    //   }
    // };
  };

  const handleSocketResponse = ({ message }) => {
    if (chatRoomId) {
      socket?.emit("leaveRoom", { roomId: chatRoomId });
    }
    setChatRoomId(message);
    subscribeSocket(message);
    localStorage.setItem("chatRoomId", message?.toString());
  };

  const subscribeSocket = (room_id) => {
    if (socket) {
      let userAgent = JSON.parse(localStorage.getItem("ipData"));
      // dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
      socket.emit("subscribe", {
        room_id: room_id,
        userId: userId,
        userAgentSeen: userAgent,
      });
    }
  };

  // console.log("Tahir socket", socket)

  const handleInitialMessages = ({
    initialMessages,
    roomId,
    lastReadMessageId,
  }) => {
    if (roomId !== localStorage.getItem("chatRoomId")) {
      return;
    }
    setLastReadMessageIdState(lastReadMessageId);
    const uniqueMessages = Array.from(
      new Map(initialMessages.map((message) => [message._id, message])).values()
    );
    dispatch(
      setUserMessages({
        userMessages: uniqueMessages.map(formatMessage),
        unReadMessageCounter: null,
      })
    );
    const unReadIndex = allUser?.findIndex(item => item?.roomId === roomId && item?.unRead > 0);
    if (unReadIndex !== -1) {
      const userAgent = JSON.parse(localStorage.getItem("ipData"));
      const selectedGroup = localStorage.getItem("selectedGroup");
      socket.emit("readAllMessage", {
        userId,
        roomId,
        organizationId: user?.organization,
        userAgent,
        isGroup: !!selectedGroup, 
      });
    }
  };
  const handleSearchMessagesResponse = ({ messages, isMuted }) => {
    if (messages) {
      if (messages.length > 0) {
        dispatch(
          setUserMessages({
            userMessages: messages.map(formatMessage),
            unReadMessageCounter: null,
          })
        );
      } else {
        dispatch(
          setUserMessages({ userMessages: [], unReadMessageCounter: null })
        );
      }
    } else {
      dispatch(
        setUserMessages({ userMessages: [], unReadMessageCounter: null })
      );
    }
  };

  const [newMessage, setNewMessage] = useState(null);
  const [newChatLoad, setNewChatLoad] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(null);
  const [moreTopMessages, setMoreTopMessages] = useState(true);
  const [isScrollUp, setIsScrollUp] = useState(false);
  const [lastElementId, setLastElementId] = useState(null);
  const [displayChatCounter, setDisplayChatCounter] = useState(0);

  const recipentRef = useRef(recipient);
  useEffect(() => {
    recipentRef.current = recipient;
  }, [recipient]);
  // console.log("state", state);

  function extractMessageDetails(data) {
    const senderName = `${data.senderDetails.firstName} ${data.senderDetails.lastName}`;
    const groupTitle = data?.groupTitle ? data?.groupTitle : null;
    const firstFileName =
      data?.filesData?.length > 0 ? data?.filesData[0]?.name : null;
    const message = data?.message;

    return {
      senderName,
      groupTitle,
      firstFileName,
      message,
    };
  }

  const scrollToBottom = () => {
    const listDiv = listRef.current;
    if (listDiv) {
      listDiv.scrollTop = listDiv.scrollHeight - listDiv.clientHeight;
      setIsScrolledToBottom(true);
    }
  };

  const handleReceivedMessage = ({ message, isMuted , result }) => {
    setReplyToMessage(null);
    setReplyToMedia(null);
    setSearchUsersResponse([]);

    const roomId = formatMessage(message[0])?.onetooneId
      ? formatMessage(message[0])?.onetooneId
      : formatMessage(message[0]).groupId;

    let chatRoomIdX = localStorage.getItem("chatRoomId");
    let selectedgrp = JSON.parse(localStorage.getItem("selectedGroup"));
    let userrID = (user?._id).toString();

    const condition1 = chatRoomIdX === roomId;
    const condition2 = selectedgrp?.participants?.includes(userrID);
    const condition3 = selectedUserOrGroup?.participants?.includes(userrID);
    const condition4 =
      selectedgrp &&
      selectedgrp?.noParticipants &&
      selectedgrp?.noParticipants?.length > 0 &&
      selectedgrp?.type === "group" &&
      !selectedgrp?.noParticipants?.includes(userrID);
    const condition5 =
      selectedUserOrGroup &&
      selectedUserOrGroup?.noParticipants?.length > 0 &&
      selectedUserOrGroup?.type === "group" &&
      !selectedUserOrGroup?.noParticipants?.includes(userrID);

    const condition6 =
      recipentRef?.current && recipentRef?.current?.type === "single";
    // console.log("recipentRef.current", recipentRef.current)
    dispatch(
      addNewMessage({
        newMessage: formatMessage(message[0]),
        roomId: roomId,
      })
    );
    const finalCondition =
      condition1 &&
      (condition2 || condition3 || condition4 || condition5 || condition6);

    if (finalCondition) {
      if (user?._id !== message[0]?.senderId) {
        setNewMessage(formatMessage(message[0]));
      }
    }

    // let selectedGroup = localStorage.getItem("selectedUserOrGroup");
    // if (selectedGroup) {
    //   selectedGroup = JSON.parse(selectedGroup);
    //   selectedGroup?.participants?.map((participant, ind) => {
    //     if (participant != user?._id) {
    //       socket.emit("groupPoke", {
    //         userId: participant,
    //         organizationId: user?.organization,
    //       });
    //     }
    //   });
    // }
    if (
      message[0]?.senderId != user?._id &&
      !(isMuted?.isMute?.includes(user?._id)) &&
      CurrentUserStatus !== "dnd" &&
      window.electron
    ) {
      const sender = `${message[0]?.senderDetails.firstName} ${message[0]?.senderDetails.lastName}`;
      const details = extractMessageDetails(message[0]);
      window.electron.sendNotification(sender, message[0]?.message);
      // window.electron.playAudio(audioPath);
      try {
        playAudio();
      } catch (error) {}
    }
    
    dispatch(updateChat({ updatedData: result }));
    setTimeout(() => {
      const listDiv = listRef.current;
      if (listDiv && message[0]?.senderDetails?._id === userId) {
        scrollToBottom();
      }
    }, 250);
  };

  const formatMessage = (message) => {
    const formattedMessage = {
      type:
        user?._id !== "" && user?._id === message?.senderId
          ? "reply"
          : "sender",
      time: getCurrentTime(message?.timestamp),
      content: message?.message,
      messageHtml: message?.messageHtml,
      reactions: message?.reactions,
      id: message?._id,
      pinned: message?.isPinned,
      read: message?.read,
      seen: message?.seen,
      delivered: message?.delivered,
      timestamp: message?.timestamp,
      userAgentSent: message?.userAgentSent,
      userAgentSeen: message?.userAgentSeen,
      parentConversation: message?.parentConversation,
      replyMediaDetails: message?.replyMediaDetails,
      replyToMedia: message?.replyToMedia,
      messageType: message?.messageType,
      filesData: message?.filesData,
      isText: message?.isText,
      isImage: message?.isImage,
      isAudio: message?.isAudio,
      isVideo: message?.isVideo,
      isDocument: message?.isDocument,
      isLink: message?.isLink,
      isCode: message?.isCode,
      isContact: message?.isContact,
      isLocation: message?.isLocation,
      senderDetails: message?.senderDetails,
      organizationId: message?.organizationId,
      groupId: message?.groupId ?? "",
      onetooneId: message?.onetooneId ?? "",
      contactDetails: message?.contactDetails,
      isNotification: message?.isNotification,
      repliedToConversation: message?.repliedToConversation,
      deliveredTo: message?.deliveredTo,
      readBy: message?.readBy,
      deleteForMe: message?.deleteForMe,
      deleteForAll: message?.deleteForAll,
    };
    return formattedMessage;
  };

  // const getChatList = () => {
  //   if (socket) {
  //     socket?.emit("getChatList", {
  //       userId: user?._id,
  //       organizationId: user?.organization,
  //     });
  //   }
  // };
  // const getSingleChatList = () => {
    // if (socket) {
    //   const handleResponse = (response) => {
    //     const chatId = response?.data?._id; 
    //     if (chatId) {
    //       dispatch(updateChat({ updatedData: response?.data }));
    //     }
    //   };
    //   socket.emit("getSingleChatList", {
    //     userId: user?._id,
    //     organizationId: user?.organization,
    //   });
    //   socket.on("singleChatListResponse", handleResponse);
    //   return () => {
    //     socket.off("singleChatListResponse", handleResponse);
    //   };
    // }
    // return ;
  // };
  
  const SearchActionUser = (keywords, tabValue = null) => {
    if (!keywords) {
      if (tabValue === "Groups") {
        setFilterGroup([]);
      }
    }

    if (tabValue === "Groups") {
      const filteredGroups = groupList.filter((group) =>
        group.title.toLowerCase().includes(keywords.toLowerCase())
      );
      setFilterGroup([...filteredGroups]);
    }
    if (socket && keywords !== "") {
      socket?.emit("searchActionUser", {
        excludeUsers: [user?._id],
        searchString: keywords,
      });
    }
  };

  const handleSocketSearchedActionUserListResponse = ({ list }) => {
    setSearchActionUsersResponse(list);
  };

  const handleForwardMessageResponse = ({ response }) => {
    socket?.on("searchMessageByStringResponse", handleSearchMessagesResponse);
    socket?.emit("getChatList", {
      userId: user?._id,
      organizationId: user?.organization,
    });
    return;
  };

  const showNotification = (title, body) => {
    return new Notification(title, {
      body: body,
      requireInteraction: true,
    });
  };

  const handleCancelExpire = async (reminderId) => {
    try {
      await cancelReminder(reminderId);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const expireAlarm = (reminderId) => {
    handleCancelExpire(reminderId);
  };

  const handleReminder = (data) => {
    if (data && data?.length > 0) {
      data.forEach((item) => {
        showNotification(item?.title, item?.description);

        expireAlarm(item?._id);
      });
    }
  };

  useEffect(() => {
    socket.on("reminders", handleReminder);
    return () => {
      socket.off("reminders", handleReminder);
    };
  }, []);
  const handleNewNotice = (data) => {
    const [
      notices,
      fetchedNotices,
      totalNotices,
      remainingNotices,
      information,
    ] = data;
    showNotification(
      information.title,
      `${information?.description?.slice(0, 30)}...`
    );
  };
  useEffect(() => {
    if (openedDrawer !== "notification") {
      socket.on("new_notice", handleNewNotice);
    }
    return () => {
      socket.off("new_notice", handleNewNotice);
    };
  }, []);

  const searchFrom = (fromList) => {
    if (fromList === "groupList") fetchUserGroupsDetails();

    setSearchFromList(fromList);
    setTabValue(fromList);
    const topTabSearch = document.querySelector("#inlineFormInputGroup");
    if (topTabSearch) {
      topTabSearch.value = "";
    }
  };
  function mergeParagraphs(messageHtml) {
    return messageHtml;
  }
  const sendMessage = (
    type = "",
    replyToMessage = null,
    replyToMedia = null
  ) => {
    
    if (socket.connected === false) {
      socket.connect();
      openWarningNotification(
        "Unstable internet connection detected. Please check your connection or refresh and try again."
      );
      return;
    }
    
    if (chatListRef.current) {
      chatListRef.current.scrollTop = 0;
    }
    // setSearchFromList("chatHistory");
    // searchFrom("chatHistory");
    // setTabValue("chatHistory");
    // setUnReadChat([])
    // const allMessageTab = document.getElementById("AllMessage-tab");
    // const employeeTab = document.getElementById("employee-tab");
    // const groupMessageTab = document.getElementById("Group-tab");
    // const orangeEmployeeTab = document.getElementById("OrangeMember-tab");
    // const unreadTab = document.getElementById("Unread-tab");
    // if (allMessageTab && employeeTab && groupMessageTab) {
    //   allMessageTab.classList.add("active");
    //   employeeTab.classList.remove("active");
    //   groupMessageTab.classList.remove("active");

    //   if (orangeEmployeeTab) {
    //     orangeEmployeeTab.classList.remove("active");
    //   }
    //   if (unreadTab) {
    //     unreadTab.classList.remove("active");
    //   }

    // } else {
    //   console.log("One or more required tab elements are missing!");
    // }

    //   setTimeout(()=>{
    //    if (listDiv) {
    //      listDiv.scrollTop = listDiv.scrollHeight - listDiv.clientHeight;
    //      setIsScrolledToBottom(true);
    //  }
    //   },1000)
    if (showModal && selectedUsers?.length > 0) {
      return forkedMessageToMultipleUser();
    }

    if (location?.latitude) {
      if (!replyToMessage && !replyToMedia) {
        repliedToConversation = false;
      } else {
        repliedToConversation = true;
      }
      let userAgent = JSON.parse(localStorage.getItem("ipData"));
      let data = {
        chatroom_id: chatRoomId,
        senderId: userId,
        receiverId: receiverData._id,
        receiver:
          selectedUserOrGroup?.remark === "Group"
            ? receiverData?.title
            : `${receiverData?.firstName} ${receiverData?.lastName}`,
        message: `${location?.latitude},${location?.longitude}`,
        messageType: "text",
        isLocation: true,
        files: fileIds?.length > 0 ? fileIds : [],
        fileTypes: fileTypes,
        messageHtml: "",
        userAgent: userAgent,
        organization: user?.organization,
        replyToMessage: replyToMessage,
        replyToMedia: replyToMedia,
        repliedToConversation: repliedToConversation,
        organizationId: user?.organization,
        group: selectedUserOrGroup?.remark === "Group" ? true : false,
        taggedUsers:
          selectedTaggedUserIds?.length > 0 ? selectedTaggedUserIds : [],
        isNotification: false,
        isTabVisible: isTabVisible,
      };

      const response = socket?.emit("message", data);
      setChatMessage(initialMessage);
      setConversationMessage({});
      setSearchText("");
      setSearchedUsers([]);
      setSelectedUsers([]);
      setLocation({});
      setConversationMessage({});
      setSelectedIndexes([]);
      setTaggedUsers([]);
      clearCanvas();
      setLocation({})
      // getChatList();
      return;
    }
    if (showAudioRecorder) {
      return handleSendData();
    } else {
      if (!replyToMessage && !replyToMedia) {
        repliedToConversation = false;
      } else {
        repliedToConversation = true;
      }
      const updatedHtmlString = removeEmptyPTags(conversationMessage?.msgHtml);
      const imageGif = conversationMessage?.imageGif;
      let userAgent = JSON.parse(localStorage.getItem("ipData"));
      let data = {
        chatroom_id: chatRoomId,
        senderId: userId,
        receiverId: receiverData._id,
        receiver:
          selectedUserOrGroup?.remark === "Group"
            ? receiverData?.title
            : `${receiverData?.firstName} ${receiverData?.lastName}`,
        message: conversationMessage?.messageText?.trim(),
        messageType:
          checkIfLinkType(fileIds, conversationMessage?.messageText?.trim()) ??
          "",
        image: imageGif,
        files: fileIds?.length > 0 ? fileIds : [],
        fileTypes: fileTypes,
        messageHtml: mergeParagraphs(updatedHtmlString),
        userAgent: userAgent,
        organization: user?.organization,
        replyToMessage: replyToMessage,
        replyToMedia: replyToMedia,
        repliedToConversation: repliedToConversation,
        organizationId: user?.organization,
        group: selectedUserOrGroup?.remark === "Group" ? true : false,
        taggedUsers:
          selectedTaggedUserIds?.length > 0 ? selectedTaggedUserIds : [],
        isNotification: false,
        isTabVisible: isTabVisible,
      };
      if (
        !(
          (data.message === "" || data.message === null) &&
          (data.messageHtml === "" || data.messageHtml === null) &&
          (data.image === null || data.image === "") &&
          Array.isArray(data.files) &&
          data?.files?.length === 0
        )
      ) {
        const response = socket?.emit("message", data);
        // console.log("data while sending message***",data);
        // setTimeout(() => {
        //   const data1 = {
        //     userId: user?._id,
        //     roomId: chatRoomId,
        //   };
        //   socket?.emit("initialMessages", data1);
        //   socket?.on("initialMessages", handleInitialMessages);
        // }, 1500);
      }
      if (showModal && selectedUsers?.length > 0) {
        return forkedMessageToMultipleUser();
      }
      setShowEmojiPicker(false)
      setChatMessage(initialMessage);
      setConversationMessage({});
      // setSearchText("");
      setSearchedUsers([]);
      setSelectedUsers([]);
      setFileNames([]);
      setFileIds([]);
      setSelectedIndexes([]);
      setTaggedUsers([]);
      clearCanvas();
    }
  };
  // const listDiv = listRef.current;
  // useEffect(() => {

  //   if (listDiv) {
  //     listDiv.scrollTop = listDiv.scrollHeight - listDiv.clientHeight;
  //     setIsScrolledToBottom(true);
  //   }
  // }, [listDiv, userMessages]);

  const [deleteMessageFlag, setDeleteMessageFlag] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [deleteMessageFlagGroup, setDeleteMessageFlagGroup] = useState(false);
  const [everyone, setEveryone] = useState(false);

  const deleteMessage = (msg) => {
    setMessageInfo(msg);
    setDeleteMessageFlag(true);
  };

  useEffect(() => {}, [searchFromList, tabValue]);

  const deleteMessageDone = (all, messageIds) => {
    if (messageIds && messageIds?.length > 0) {
      let data = {
        userId: user?._id,
        forAll: all,
        messageIds: messageIds,
        organization: user?.organization,
        deletedBy: userId,
        roomId: localStorage.getItem("chatRoomId"),
      };
      socket?.emit("deleteMessage", data);

      dispatch(
        setUserMessages({
          userMessages: userMessages,
          unReadMessageCounter: null,
        })
      );
    }
    setDeleteMessageFlag(false);
  };

  const pinMessage = (messageIds) => {
    let data = {
      messageIds: messageIds,
    };
    socket?.emit("pinMessage", data);
  };

  const unreadMessage = (messageId) => {
    let data = {
      messageId: messageId,
      userId: user?._id,
    };
    socket?.emit("unreadMessage", data);
    navigate("/home");
  };

  const unpinMessage = (messageIds) => {
    let data = {
      messageIds: messageIds,
    };
    socket?.emit("unpinMessage", data);
  };
  const toggleDrawer = (type) => {
    let bodyTag = document.querySelector("body");
    let openedModalClass = document.querySelector(".modal-dialog-scrollable");
    if (bodyTag) {
      bodyTag.classList.remove("BlurEffect");
    }
    if (openedModalClass) {
      openedModalClass.removeChild = `<div class="modal-backdrop fade show"></div>`;
    } else {
      openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`;
    }

    document.getElementById("main").style.marginRight = "0%";
    setOpenedDrawer((prevState) =>
      prevState === null || prevState != type ? type : null
    );

    if (type != "notification") {
      let AllNotifications = document.querySelector("#AllNotifications");
      let chatBoxModal = document.querySelector("#chatBoxModal");
      let showNotifctn = document.querySelector("#showNotifctn");
      let notificationTextshow = document.querySelector(
        "#notificationTextshow"
      );
      if (chatBoxModal) {
        chatBoxModal.style.display = "block";
      }
      if (AllNotifications) {
        AllNotifications.style.width = "0";
      }
      if (showNotifctn) {
        showNotifctn.style.display = "none";
      }
      if (notificationTextshow) {
        notificationTextshow.style.display = "none";
      }
    }

    if (
      type == "notification" ||
      type == "createGroup" ||
      type == "favoriteChats" ||
      type == "setting" ||
      type == "profile"
    ) {
      if (bodyTag && type != openedDrawer) {
        bodyTag.classList.add("BlurEffect");
      }
      if (openedModalClass) {
        openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`;
      }
    }
  };

  const toggleRightBarDrawer = (type) => {
    let bodyTag = document.querySelector("body");
    let openedModalClass = document.querySelector(".modal-dialog-scrollable");
    if (bodyTag) {
      bodyTag.classList.remove("BlurEffect2");
    }
    document.getElementById("main").style.marginRight = "0%";
    setOpenedRightBarDrawer((prevState) =>
      prevState === null || prevState !== type ? type : null
    );
    if (type == "appMedia") {
      if (bodyTag && type != openedRightBarDrawer) {
        bodyTag.classList.add("BlurEffect2");
      }
      if (openedModalClass) {
        openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`;
      }
    }
    if (type == "todo") {
      if (bodyTag && type != openedRightBarDrawer) {
        bodyTag.classList.add("BlurEffect2");
      }
      if (openedModalClass) {
        openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`;
      }
    }
    if (type == "notes") {
      if (bodyTag && type != openedRightBarDrawer) {
        bodyTag.classList.add("BlurEffect2");
      }
      if (openedModalClass) {
        openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`;
      }
    }
  };
  const messageinfo_open = (msg) => {
    setMessageInfo(msg);
    setMesageInfoFlag(true);
    const mainElement = document.getElementById("main");
    const myProfileElement = document.getElementById("messageInfo");

    if (mainElement && myProfileElement) {
      mainElement.style.marginRight = "265px";
      myProfileElement.style.width = "265px";
      myProfileElement.style.display = "block";
      myProfileElement.style.right = "109px";
      myProfileElement.style.top = "56px";
    }
  };

  const forkedMessageToMultipleUser = () => {
    const updatedHtmlString = removeEmptyPTags(conversationMessage?.msgHtml);
    let userAgent = JSON.parse(localStorage.getItem("ipData"));
    if (!replyToMessage && !replyToMedia) {
      repliedToConversation = false;
    } else {
      repliedToConversation = true;
    }
    const data = {
      chatroom_id: chatRoomId,
      senderId: userId,
      receiverId: receiverData._id,
      receiver:
        selectedUserOrGroup?.remark === "Group"
          ? receiverData?.title
          : `${receiverData?.firstName} ${receiverData?.lastName}`,
      message: conversationMessage?.messageText?.trim(),
      messageType:
        fileIds?.length > 0
          ? "media"
          : checkIfLinkType(
              fileIds,
              conversationMessage?.messageText?.trim()
            ) ?? "",
      files: fileIds?.length > 0 ? fileIds : [],
      fileTypes: fileTypes,
      messageHtml: mergeParagraphs(updatedHtmlString),
      userAgent: userAgent,
      organization: user?.organization,
      replyToMessage: replyToMessage,
      replyToMedia: replyToMedia,
      repliedToConversation: repliedToConversation,
      organizationId: user?.organization,
      selectedUsers: selectedUsers,
    };
    socket?.emit("forkMessagesToUsers", data);
    socket?.on("forkMessageResponse", (response) => {
      openSuccessNotification({ response });
    });

    // let data1 = {
    //   userId: user?._id,
    //   roomId: chatRoomId,
    // };
    //    socket?.emit("initialMessages", data1);
    //   socket?.on("initialMessages", handleInitialMessages);
    setChatMessage(initialMessage);
    setConversationMessage({});
    setSearchText("");
    setSearchedUsers([]);
    setSelectedUsers([]);
    setFileNames([]);
    setFileIds([]);
    setChatMessage(initialMessage);
    setSelectedIndexes([]);
    setTaggedUsers([]);
  };

  const forwardMessageToMultipleUser = () => {
    let userAgent = JSON.parse(localStorage.getItem("ipData"));
    let allIds = [];
    if (selectMessages && selectMessages?.length > 0) {
      allIds = selectMessages.map((item) => item.id);
    }
    let data = {
      selectedMessages: allIds,
      selectedUserList: selectedUsers,
      mediaToForward: mediaToForward,
      organization: user?.organization,
      senderId: userId,
      userAgent: userAgent,
    };

    socket?.emit("forwardMessageToMultipleUser", data);
    socket?.on("forwardResponse", handleForwardMessageResponse);
    setSelectMessages([]);
    setMessageActionFlag(!messageActionFlag);
    setIsUserPopupOpen(!isUserPopupOpen);
    setMediaToForward(null);
    setSelectedUsers([]);
  };

  const deleteMultipleMessagesCancel = () => {
    selectMessages.forEach((item) => {
      const elementId = "selectMessage_" + item.id;
      const element = document.getElementById(elementId);
      if (element) {
        element.checked = false;
      }
    });
    setMessageActionFlag(false);
    setMessageFilterFlag(false);
    setSelectMessages([]);
    setMediaToForward(null);
    setDeleteMessageFlagGroup(false);
  };

  const deleteMultipleMessages = (forAll) => {
    if (selectMessages && selectMessages?.length > 0) {
      const allIds = selectMessages.map((item) => item.id);

      let data = {
        userId: userId,
        forAll: forAll,
        messageIds: allIds,
        organization: user?.organization,
        senderId: userId,
        roomId: localStorage.getItem("chatRoomId"),
        deletedBy: userId,
      };

      socket?.emit("deleteMessage", data);
      // selectMessages.forEach((item) => {
      //   const elementId = `messageDiv_${item.id}`;
      //   const element = document.getElementById(elementId);
      //   if (element) {
      //     element.style.display = "none";
      //   }
      // });
    }
    setDeleteMessageFlagGroup(false);
    setMessageActionFlag(false);
    setMessageFilterFlag(false);
    setSelectMessages([]);
  };

  const handleStartRecording = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMicrophone = devices.some(
        (device) => device.kind === "audioinput"
      );
      if (!hasMicrophone) {
        openErrorNotification(
          "No microphone found. Please connect a microphone to use this feature."
        );
        return;
      }

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setStream(audioStream);
      if (audioStream) {
        const mediaRecorder = new MediaRecorder(audioStream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.ondataavailable = (e) => {
          chunksRef.current.push(e.data);
        };
        mediaRecorder.onstop = async () => {
          const blob = new Blob(chunksRef.current, { type: "audio/mpeg" });
          setAudioBlob(blob);
        };

        mediaRecorder.onstart = () => {
          setRecordingDuration(0);
          const timer = setInterval(() => {
            setRecordingDuration((prevDuration) => prevDuration + 1);
          }, 1000);

          mediaRecorderRef.current.timer = timer;
        };

        mediaRecorder.start();
        setIsRecording(true);
      }
    } catch (error) {
      openErrorNotification(
        "Error accessing microphone. Please try again later."
      );
    }
  };

  const handleStopRecording = () => {
    setIsRecording(true);
  };

  const handlePauseRecording = () => {
    setIsRecording(true);
  };

  const handleResumeRecording = () => {
    if (mediaRecorderRef.current && isPaused) {
      const timer = setInterval(() => {
        setRecordingDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      mediaRecorderRef.current.timer = timer;
      mediaRecorderRef.current.resume();
      setIsRecording(true);
      setIsPaused(false);
    }
  };

  const handleSendData = async () => {
    if (socket.connected === false) {
      socket.connect();
      openWarningNotification(
        "Unstable internnet connection detected. Please check your connection or refresh and try again."
      );
      return;
    }
    setShowAudioRecorder(false);
    if (!audioBlob) {
      return;
    }
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour12: true,
    });
    const fileName = `rec-${user?._id}-${currentDate}-${currentTime}.mpeg`;
    const formData = new FormData();
    formData.append("file", audioBlob);
    formData.append("remark", "chat audio message");
    formData.append("title", fileName);
    formData.append("refId", user?._id);
    formData.append("source", "user");
    formData.append("directory", "user");
    formData.append("by", user?._id);
    formData.append("chatRoomId", chatRoomId);
    let { data } = await UploadFile(formData, authToken);

    if (data) {
      if (!replyToMessage && !replyToMedia) {
        repliedToConversation = false;
      } else {
        repliedToConversation = true;
      }
      setFileIds(data?.data._id);
      const updatedHtmlString = removeEmptyPTags(conversationMessage?.msgHtml);
      const imageGif = removeEmptyPTags(conversationMessage?.imageGif);
      let userAgent = JSON.parse(localStorage.getItem("ipData"));
      let messageVoice = {
        chatroom_id: chatRoomId,
        senderId: userId,
        receiverId: receiverData._id,
        receiver:
          selectedUserOrGroup?.remark === "Group"
            ? receiverData?.title
            : `${receiverData?.firstName} ${receiverData?.lastName}`,
        message: conversationMessage?.messageText?.trim(),
        messageType: data?.data._id
          ? "media"
          : checkIfLinkType(
              fileIds,
              conversationMessage?.messageText?.trim()
            ) ?? "",
        files: [data?.data._id]?.length > 0 ? [data?.data._id] : [],
        fileTypes: ["audio", "blob"],
        messageHtml: mergeParagraphs(updatedHtmlString),
        image: imageGif,
        userAgent: userAgent,
        organization: user?.organization,
        replyToMessage: replyToMessage,
        replyToMedia: replyToMedia,
        organizationId: user?.organization,
        repliedToConversation: repliedToConversation,
        group: selectedUserOrGroup?.remark === "Group" ? true : false,
        taggedUsers:
          selectedTaggedUserIds?.length > 0 ? selectedTaggedUserIds : [],
        isAudio: true,
        isNotification: false,
        isTabVisible: isTabVisible,
      };

      socket?.emit("message", messageVoice);

      // let data1 = {
      //   userId: user?._id,
      //   roomId: chatRoomId,
      // };
      //    socket?.emit("initialMessages", data1);
      //   socket?.on("initialMessages", handleInitialMessages);
      if (showModal && selectedUsers?.length > 0) {
        forkedMessageToMultipleUser();
      }
      setChatMessage(initialMessage);
      setConversationMessage({});
      setSearchText("");
      setSearchedUsers([]);
      setSelectedUsers([]);
      setFileNames([]);
      setFileIds([]);
      setChatMessage(initialMessage);
      setSelectedIndexes([]);
      setTaggedUsers([]);
      clearCanvas();
    }
    clearCanvas();
    setChatMessage(initialMessage);
    setConversationMessage({});
    setSearchText("");
    setSearchedUsers([]);
    setSelectedUsers([]);
    setFileNames([]);
    setFileIds([]);
    setAudioBlob(null);
    clearCanvas();
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }
  };

  const handleSelect = (e, userId) => {
    const index = selectedUsers.indexOf(userId);
    setTimeout(() => {
      if (index === -1) {
        setSelectedUsers([...selectedUsers, userId]);
      } else {
        setSelectedUsers(selectedUsers.filter((id) => id !== userId));
      }
    }, 0);
  };
  const handleSelectForward = (e, userId, type) => {
    const userToToggle = { userOrGroupId: userId, type: type };
    const index = selectedUsers.findIndex(
      (user) => user.userOrGroupId === userId && user.type === type
    );
    setTimeout(() => {
      if (index === -1) {
        setSelectedUsers([...selectedUsers, userToToggle]);
      } else {
        setSelectedUsers(
          selectedUsers.filter(
            (user) => !(user.userOrGroupId === userId && user.type === type)
          )
        );
      }
    }, 0);
  };
  const isTimestamp = (name) => {
    const timestampPattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|([+-]\d{2}:\d{2}))$/;
    return timestampPattern.test(name);
  };

  const handleFileUpload = async (files) => {
    try {
      setLoader(true);
      if (!files || files.length === 0) {
        return;
      }
      const newFileNames = [];
      const newFileIds = [];
      const newFileTypes = [];

      for (let i = 0; i < Math.min(files.length, 10); i++) {
        const file = files[i];
        const modifiedFileName = isTimestamp(file.name)
          ? `screenshot_${Math.floor(Math.random() * 10000)}.png`
          : file.name;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("remark", "Chat Conversation");
        formData.append("title", modifiedFileName);
        formData.append("refId", user?._id);
        formData.append("source", "user");
        formData.append("directory", "user");
        formData.append("by", user?._id);
        formData.append("chatRoomId", chatRoomId);
        formData.append("originalname", modifiedFileName);
        formData.append("mimetype", file.type);
        formData.append("size", file.size);
        const { data } = await UploadFile(formData, (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setFileProgress(progress);
        });

        if (data) {
          newFileNames.push(data.data);
          newFileIds.push(data.data?._id);
          newFileTypes.push(data.data?.extension);
          setFileProgress(0);
        }
      }

      setFileNames([...fileNames, ...newFileNames]);
      setFileIds([...fileIds, ...newFileIds]);
      setFileTypes([...fileTypes, ...newFileTypes]);
      setLoader(false);
    } catch (error) {
      openErrorNotification("Failed to Attach Document");
      setLoader(false);
      setFileProgress(0);
    }
  };

  // useEffect(() => {
  //   if (chatRoomId) {
  //     searchMessage(searchMessageString);
  //   }
  // }, [searchMessageType, dateRange, searchMessageString]);
  // console.log("searchMessageString",searchMessageString);

  const searchMessage = async (msg) => {
    // dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
    // if (socket && msg && msg?.trim()?.length >= 0  && chatRoomId) {
    //   let data = {
    //     messageString: msg,
    //     messageType: searchMessageType,
    //     roomId: chatRoomId,
    //     startDate: dateRange.start.format("YYYY-MM-DD"),
    //     endDate: dateRange.end.format("YYYY-MM-DD"),
    //     userId: user?._id,
    //   };
    //   socket?.emit("searchMessageByString", data);
    // } else {
    //   let data = {
    //     userId: user?._id,
    //     roomId: chatRoomId,
    //   };
    //   socket?.emit("initialMessages", data);
    //   socket?.on("initialMessages", handleInitialMessages);
    // }
  };

  const removeEmptyPTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const emptyPTags = doc.querySelectorAll("p:empty");
    emptyPTags.forEach((emptyPTag) => {
      emptyPTag.parentNode.removeChild(emptyPTag);
    });
    const emptyLITags = doc.querySelectorAll("li:empty");
    emptyLITags.forEach((emptyLITag) => {
      emptyLITag.parentNode.removeChild(emptyLITag);
    });

    const updatedHtmlString = doc.body.innerHTML;
    return updatedHtmlString;
  };
  const resetAndToggleModal = () => {
    setIsLoadingMessage(true);
    setShowEmojiPicker(false)
    setLocation({})
    setShowAudioRecorder(!showAudioRecorder);
    setSearchMessageString(null);
    dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
    const myMediaElement = document.getElementById("userFileMedia");
    setMessageActionFlag(false);
    setMessageFilterFlag(false);
    setSelectMessages([]);
    setTextMessage("");
    setSearchedUsers([]);
    setSelectedUsers([]);
    setShowModal(false);
    setSearchText("");
    setSearchedUsers([]);
    clearCanvas();
    setIsRecording(false);
    setShowAudioRecorder(false);
    setSelectedSuggestions("");
    setTaggedUsers([]);
    setSelectedIndexes([]);
    setSuggestions([]);
    setSelectedSuggestions("");
    setTaggedUsers([]);
    if (myMediaElement) {
      resetFile();
    }
    groupDetails_close();
    setRevelingConMessage("");
    profile_close();
    setReplyToMessage(null);
    setReplyToMedia(null);
    //  setInactiveSearchValue("");
    //  setInactiveValue("");
    setReplyToMessage(null);
    setReplyToMedia(null);
    setFileNames([]);
    setFileIds([]);
    setFileTypes([]);
  };
  // useEffect(() => { }, [inactiveValue, deActivatedUsers, deActivatedGroups]);

  // const updateUserLastSeen = async (lastSeenValue) => {
  //   await updateLastSeen(user._id, lastSeenValue, authToken);
  // };

  // useEffect(() => {
  // setInterval(() => {
  //     if (user) {
  //       const timestamp = Date.now();
  //       socket.emit("user-activity", user._id);
  //       Promise.allSettled([
  //         updateUserLastSeen(timestamp),
  //       ]).catch((error) => {
  //         console.error('Failed to update last seen:', error);
  //       });
  //     }
  //   }, 60000 * 10);

  // }, [user, authToken]);
  const handleSocketUserListResponse = async ({ list }) => {
    const uniqueList = [
      ...new Map(list.map((item) => [item["_id"], item])).values(),
    ];
    const totalUnReadCounts = uniqueList.reduce((total, item) => {
      return total + (item?.unRead || 0);
    }, 0);
    setTotalUnReadGlobal(totalUnReadCounts);
    dispatch(setUniqueChats(uniqueList));
  };
  return (
    <>
      <Helmet>
        <section>
          <title>index - </title>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"
            rel="stylesheet"
          />
        </section>
      </Helmet>
      <Head />

      <body>
        <HeaderNav />
        <main className="d-flex flex-nowrap">
          {/* { socket.connected === false && (
  <CheckInternet
    isFromHome="It looks like you are offline. Please check your internet connection to  reconnect."
  />
)} */}

          <Leftbar
            toggleDrawer={toggleDrawer}
            unreadNotification={unreadNotification}
            socket={socket}
            totalUnReadGlobal={totalUnReadGlobal}
            currentActiveTab={currentActiveTab}
            setSetCurrentActiveTab={setSetCurrentActiveTab}
          />
          <div className="chat-area" id="main">
            <div className="chatlist resizable">
              {openedDrawer && openedDrawer === "profile" && (
                <Profile
                  setEditGroup={setEditGroup}
                  toggleDrawer={toggleDrawer}
                  setIsGroupEditable={setIsGroupEditable}
                />
              )}
              {openedDrawer && openedDrawer === "setting" && (
                <AccountSettingContainer toggleDrawer={toggleDrawer} />
              )}
              {openedDrawer && openedDrawer === "notification" && (
                <NotificationPanel
                  toggleDrawer={toggleDrawer}
                  setNoticeNotification={setNoticeNotification}
                  socket={socket}
                  openedDrawer={openedDrawer}
                />
              )}
              {!openedDrawer && (
                <Chatlist
                  socket={socket}
                  one2oneTyping={one2oneTyping}
                  groupTyping={groupTyping}
                  startChat={startChat}
                  getCurrentTime={getCurrentTime}
                  setChatRoomId={setChatRoomId}
                  groupList={groupList}
                  selectedUserOrGroup={selectedUserOrGroup}
                  setSelectedUserOrGroup={setSelectedUserOrGroup}
                  setReceiverData={setReceiverData}
                  // onlineUserList={onlineUserList}
                  // setOnlineUserList={setOnlineUserList}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  toggleDrawer={toggleDrawer}
                  setIsPinDrawerOpen={setIsPinDrawerOpen}
                  fetchUserGroups={fetchUserGroups}
                  resetAndToggleModal={resetAndToggleModal}
                  userId={userId}
                  receiverData={receiverData}
                  setLiveStatus={setLiveStatus}
                  totalUnReadGlobal={totalUnReadGlobal}
                  setTotalUnReadGlobal={setTotalUnReadGlobal}
                  fetchDeactivatedGroupList={fetchDeactivatedGroupList}
                  fetchDeactivatedUsersList={fetchDeactivatedUsersList}
                  setInactiveSearchValue={setInactiveSearchValue}
                  setInactiveValue={setInactiveValue}
                  deActivatedUsers={deActivatedUsers}
                  deActivatedGroups={deActivatedGroups}
                  inactiveValue={inactiveValue}
                  setDeActivatedUsers={setDeActivatedUsers}
                  setDeActivatedGroups={setDeActivatedGroups}
                  inactiveSearchValue={inactiveSearchValue}
                  searchFromList={searchFromList}
                  setSearchFromList={setSearchFromList}
                  searchFrom={searchFrom}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  fetchUserList={fetchUserList}
                  fetchAndAddUserGroupList={fetchUserGroupsDetails}
                  searchUsersResponse={searchUsersResponse}
                  setSearchUsersResponse={setSearchUsersResponse}
                  setSetCurrentActiveTab={setSetCurrentActiveTab}
                  selectedRoomId={selectedRoomId}
                  setSelectedRoomId={setSelectedRoomId}
                  UnReadChat={UnReadChat}
                  setUnReadChat={setUnReadChat}
                  isTabVisible={isTabVisible}
                  isScrolledToBottom={isScrolledToBottom}
                  handleSocketUserListResponse={handleSocketUserListResponse}
                  chatListRef={chatListRef}
                />
              )}
              {openedDrawer && openedDrawer === "favoriteChats" && (
                <FavList
                  setTotalUnReadGlobal={setTotalUnReadGlobal}
                  resetAndToggleModal={resetAndToggleModal}
                  socket={socket}
                  one2oneTyping={one2oneTyping}
                  groupTyping={groupTyping}
                  startChat={startChat}
                  getCurrentTime={getCurrentTime}
                  setChatRoomId={setChatRoomId}
                  groupList={groupList}
                  setSelectedUserOrGroup={setSelectedUserOrGroup}
                  setReceiverData={setReceiverData}
                  // onlineUserList={onlineUserList}
                  // setOnlineUserList={setOnlineUserList}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  toggleDrawer={toggleDrawer}
                  setIsPinDrawerOpen={setIsPinDrawerOpen}
                  setSetCurrentActiveTab={setSetCurrentActiveTab}
                  selectedRoomId={selectedRoomId}
                  setSelectedRoomId={setSelectedRoomId}
                />
              )}

              {openedDrawer && openedDrawer === "createGroup" && (
                <AddGroupDrawer
                  socket={socket}
                  toggleDrawer={toggleDrawer}
                  fetchUserGroups={fetchUserGroups}
                  editGroup={editGroup}
                  setEditGroup={setEditGroup}
                  isGroupEditable={isGroupEditable}
                  setIsGroupEditable={setIsGroupEditable}
                />
              )}
            </div>
            <div className="chatbox">
              {selectedUserOrGroup && !(toggleDrawer === "notification") ? (
                <Chatbox
                  displayChatCounter={displayChatCounter}
                  setDisplayChatCounter={setDisplayChatCounter}
                  newChatLoad={newChatLoad}
                  setNewChatLoad={setNewChatLoad}
                  lastElementId={lastElementId}
                  setLastElementId={setLastElementId}
                  isScrollUp={isScrollUp}
                  setIsScrollUp={setIsScrollUp}
                  moreTopMessages={moreTopMessages}
                  setMoreTopMessages={setMoreTopMessages}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                  newMessage={newMessage}
                  setNewMessage={setNewMessage}
                  setLastReadMessageIdState={setLastReadMessageIdState}
                  lastReadMessageIdState={lastReadMessageIdState}
                  fetchUserGroups={fetchUserGroups}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  one2oneTyping={one2oneTyping}
                  groupTyping={groupTyping}
                  // onlineUserList={onlineUserList}
                  setIsPinDrawerOpen={setIsPinDrawerOpen}
                  isPinDrawerOpen={isPinDrawerOpen}
                  selectedUserOrGroup={selectedUserOrGroup}
                  unreadMessage={unreadMessage}
                  searchMessageString={searchMessageString}
                  searchMessageType={searchMessageType}
                  getCurrentTime={getCurrentTime}
                  chatRoomId={chatRoomId}
                  socket={socket}
                  loading={loading}
                  setLoading={setLoading}
                  message={message}
                  setMessage={setMessage}
                  sendMessage={sendMessage}
                  chatMessage={chatMessage}
                  setChatMessage={setChatMessage}
                  editorRef={editorRef}
                  setTextMessage={setTextMessage}
                  textMessage={textMessage}
                  audioRef={audioRef}
                  audioBlob={audioBlob}
                  isRecording={isRecording}
                  isPaused={isPaused}
                  recordingDuration={recordingDuration}
                  handleStartRecording={handleStartRecording}
                  handleStopRecording={handleStopRecording}
                  handlePauseRecording={handlePauseRecording}
                  handleResumeRecording={handleResumeRecording}
                  handleSendData={handleSendData}
                  handlePlayAudio={() => {}}
                  handleCancelRecording={() => {}}
                  showAudioRecorder={showAudioRecorder}
                  setShowAudioRecorder={setShowAudioRecorder}
                  userList={userList}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  handleSelect={handleSelect}
                  selectedUsers={selectedUsers}
                  searchedUsers={searchedUsers}
                  setSelectedUsers={setSearchedUsers}
                  setSearchedUsers={setSearchedUsers}
                  getLocation={getLocation}
                  handleFileUpload={handleFileUpload}
                  conversationMessage={conversationMessage}
                  setConversationMessage={setConversationMessage}
                  setMessageInfo={setMessageInfo}
                  setDeleteMessageFlag={setDeleteMessageFlag}
                  setDeleteMessageFlagGroup={setDeleteMessageFlagGroup}
                  setEveryone={setEveryone}
                  setMesageInfoFlag={setMesageInfoFlag}
                  messageinfo_open={messageinfo_open}
                  deleteMessage={deleteMessage}
                  pinMessage={pinMessage}
                  unpinMessage={unpinMessage}
                  messageActionFlag={messageActionFlag}
                  messageFilterFlag={messageFilterFlag}
                  setMessageActionFlag={setMessageActionFlag}
                  setMessageFilterFlag={setMessageFilterFlag}
                  setSelectMessages={setSelectMessages}
                  setMediaToForward={setMediaToForward}
                  mediaToForward={mediaToForward}
                  selectMessages={selectMessages}
                  isMessageActionOpen={isMessageActionOpen}
                  setIsMessageActionOpen={setIsMessageActionOpen}
                  isUserPopupOpen={isUserPopupOpen}
                  setIsUserPopupOpen={setIsUserPopupOpen}
                  SearchActionUser={SearchActionUser}
                  deleteMultipleMessages={deleteMultipleMessages}
                  accountSetting={accountSetting}
                  setSearchMessageString={setSearchMessageString}
                  setSearchMessageType={setSearchMessageType}
                  searchMessage={searchMessage}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  fileNames={fileNames}
                  replyToMessage={replyToMessage}
                  setReplyToMessage={setReplyToMessage}
                  replyToMedia={replyToMedia}
                  setReplyToMedia={setReplyToMedia}
                  toggleRightBarDrawer={toggleRightBarDrawer}
                  revelingConMessage={revelingConMessage}
                  getUserProfileDetails={getUserProfileDetails}
                  location={location}
                  setLocation={setLocation}
                  setFileIds={setFileIds}
                  fileIds={fileIds}
                  setFileNames={setFileNames}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  loader={loader}
                  progress={fileProgress}
                  recordedBlob={recordedBlob}
                  error={error}
                  isCleared={isCleared}
                  isPausedRecording={isPausedRecording}
                  isRecordingInProgress={isRecordingInProgress}
                  recorderControls={recorderControls}
                  setAudioBlob={setAudioBlob}
                  clearCanvas={clearCanvas}
                  setIsRecording={setIsRecording}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  setSelectedSuggestions={setSelectedSuggestions}
                  selectedSuggestions={selectedSuggestions}
                  setTaggedUsers={setTaggedUsers}
                  taggedUsers={taggedUsers}
                  selectedIndexes={selectedIndexes}
                  setSelectedIndexes={setSelectedIndexes}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  setTaggedUserSelection={setTaggedUserSelection}
                  startChat={startChat}
                  scrollToItem={scrollToItem}
                  listRef={listRef}
                  targetRef={targetRef}
                  conversationData={conversationData}
                  openedRightBarDrawer={openedRightBarDrawer}
                  setConversationData={setConversationData}
                  setRevelingConMessage={setRevelingConMessage}
                  resetAndToggleModal={resetAndToggleModal}
                  isScrollToBottomDisable={isScrollToBottomDisable}
                  SetIsScrollToBottomDisable={SetIsScrollToBottomDisable}
                  setOne2oneTyping={setOne2oneTyping}
                  handleInitialMessages={handleInitialMessages}
                  isLoadingMessage={isLoadingMessage}
                  setIsLoadingMessage={setIsLoadingMessage}
                  setSelectedGroupOpen={setSelectedGroupOpen}
                  setIsTabVisible={setIsTabVisible}
                  setIsScrolledToBottom={setIsScrolledToBottom}
                  handleUnreadCounts={handleUnreadCounts}
                  setUnReadChat={setUnReadChat}
                  isScrolledToBottom={isScrolledToBottom}
                  handleSocketUserListResponse={handleSocketUserListResponse}
                  setShowEmojiPicker={setShowEmojiPicker}
                  showEmojiPicker={showEmojiPicker}
                />
              ) : null}
              {openedDrawer === "notification" && (
                <NotificationView noticeNotification={noticeNotification} />
              )}
            </div>
          </div>
          <Profilepopup userDetails={userDetails} />
          <PinViewPopUp
            chatRoomId={chatRoomId}
            setIsPinDrawerOpen={setIsPinDrawerOpen}
            isPinDrawerOpen={isPinDrawerOpen}
            userDetails={selectedUserOrGroup}
            socket={socket}
            setRevelingConMessage={setRevelingConMessage}
            getCurrentTime={getCurrentTime}
          />
          {selectedUserOrGroup &&
            selectedUserOrGroup.remark === "Group" &&
            selectedGroupOpen && (
              <GroupDetails
                groupDetails={selectedUserOrGroup}
                getCurrentTime={getCurrentTime}
                socket={socket}
                editGroup={editGroup}
                setEditGroup={setEditGroup}
                toggleDrawer={toggleDrawer}
                setChatRoomId={setChatRoomId}
                setSelectedUserOrGroup={setSelectedUserOrGroup}
                setReceiverData={setReceiverData}
                isGroupEditable={isGroupEditable}
                fetchUserGroups={fetchUserGroups}
                setIsGroupEditable={setIsGroupEditable}
                setSelectedGroupOpen={setSelectedGroupOpen}
              />
            )}

          {openedRightBarDrawer && openedRightBarDrawer === "media" && (
            <UserMedia
              toggleRightBarDrawer={toggleRightBarDrawer}
              chatRoomId={chatRoomId}
              roomType={roomType}
              setRevelingConMessage={setRevelingConMessage}
              formatMessage={formatMessage}
              socket={socket}
              openedRightBarDrawer={openedRightBarDrawer}
              scrollToItem={scrollToItem}
              setConversationData={setConversationData}
              conversationData={conversationData}
            />
          )}
          {openedRightBarDrawer && openedRightBarDrawer === "appMedia" && (
            <UserAppMedia
              toggleRightBarDrawer={toggleRightBarDrawer}
              chatRoomId={chatRoomId}
              socket={socket}
              openedRightBarDrawer={openedRightBarDrawer}
            />
          )}
          {openedRightBarDrawer && openedRightBarDrawer === "notes" && (
            <Notespopup
              toggleRightBarDrawer={toggleRightBarDrawer}
              openedRightBarDrawer={openedRightBarDrawer}
              chatRoomId={chatRoomId}
            />
          )}

          {openedRightBarDrawer && openedRightBarDrawer === "todo" && (
            <Todolist
              toggleRightBarDrawer={toggleRightBarDrawer}
              openedRightBarDrawer={openedRightBarDrawer}
            />
          )}

          {openedRightBarDrawer && openedRightBarDrawer === "reminder" && (
            <Reminders
              toggleRightBarDrawer={toggleRightBarDrawer}
              openedRightBarDrawer={openedRightBarDrawer}
              socket={socket}
            />
          )}

          <Rightbar
            toggleRightBarDrawer={toggleRightBarDrawer}
            openedRightBarDrawer={openedRightBarDrawer}
          />
        </main>
        {deleteMessageFlag && (
          <DeleteMessage
            deleteMessageDone={deleteMessageDone}
            deleteMessageFlag={deleteMessageFlag}
            selectedUserOrGroup={selectedUserOrGroup}
            messageInfo={messageInfo}
            user={user}
          />
        )}

        {deleteMessageFlagGroup && (
          <DeleteMessageGroup
            deleteMultipleMessagesCancel={deleteMultipleMessagesCancel}
            deleteMultipleMessages={deleteMultipleMessages}
            deleteMessageFlag={deleteMessageFlag}
            selectedUserOrGroup={selectedUserOrGroup}
            messageInfo={messageInfo}
            user={user}
            everyone={everyone}
          />
        )}

        {mesageInfoFlag && (
          <InfoDrawer
            messageInfo={messageInfo}
            setMesageInfoFlag={setMesageInfoFlag}
            selectedUserOrGroup={selectedUserOrGroup}
            getCurrentTime={getCurrentTime}
          />
        )}

        {isUserPopupOpen && (
          <UserListPopup
            searchActionUsersResponse={searchActionUsersResponse}
            setSearchActionUsersResponse={setSearchActionUsersResponse}
            SearchActionUser={SearchActionUser}
            getCurrentTime={getCurrentTime}
            handleSelectForward={handleSelectForward}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            isUserPopupOpen={isUserPopupOpen}
            setIsUserPopupOpen={setIsUserPopupOpen}
            forwardMessageToMultipleUser={forwardMessageToMultipleUser}
            groupList={groupList}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            filterGroup={filterGroup}
          />
        )}
        {user && !user?.isPasswordReset && <PasswordRessetPopup />}
      </body>
    </>
  );
};

export default Home;

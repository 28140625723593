import React, { useEffect, useState } from "react";
import { useNotification } from "src/components/Notifination/ToastNotification";

const MsgDropdown = ({
  message,
  setMessageInfo,
  deleteMessage,
  messageinfo_open,
  pinMessage,
  unpinMessage,
  setMessageActionFlag,
  setMessageFilterFlag,
  selectMessages,
  setSelectMessages,
  setMediaToForward,
  mediaToForward,
  isMessageActionOpen,
  setIsMessageActionOpen,
  replyToMessage,
  setReplyToMessage,
  unreadMessage,
  editor,
}) => {
  const [copied, setCopied] = useState(false);

  const { openSuccessNotification, openErrorNotification } = useNotification();

  const copyMessageToClipboard = () => {
    navigator.clipboard
      .writeText(message?.content)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 200);
      })
      .catch((error) => {
        console.error("Error copying message to clipboard:", error);
      });
  };

  const pinMe = (message) => {
    
    const elementId = "pinMe_" + message;
    const element = document.getElementById(elementId);
    element.style.display = "block";

    const pinLi = document.querySelector("#pinLi_" + message);
    if (pinLi) {
      pinLi.style.display = "none";
    }
  };

  const unpinMe = (message) => {
    let elementId = "pinMe_" + message + "_ok";
    let element = document.getElementById(elementId);

    if (element != undefined) {
      element.style.display = "none";
    } else {
      elementId = "pinMe_" + message;
      element = document.getElementById(elementId);
      element.style.display = "none";
    }

    const pinLi = document.querySelector("#pinLi_" + message);
    if (pinLi) {
      pinLi.style.display = "block";
    }
  };

  const checkMe = (id) => {
    const elementId = "selectMessage_" + id;
    const element = document.getElementById(elementId);
    if (element) {
      element.checked = !element.checked;
    }
  };

  const selectMessageFun = (message) => {
    checkMe(message.id);
    setSelectMessages((prev) => {
      if (prev.includes(message)) {
        return prev.filter((msgId) => msgId.id !== message.id);
      } else {
        return [...prev, message];
      }
    });
  };

  useEffect(() => {
    if (selectMessages.length > 0) {
      setMessageActionFlag(true);
      setIsMessageActionOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isMessageActionOpen) {
      setMessageActionFlag(false);
      setSelectMessages([]);
      setMediaToForward(null);
    }
  }, []);

  return (
    <>
      <i
        id={`pinMe_${message.id}`}
        style={{ display: "none" }}
        className="mdi mdi-pin-outline"
        onClick={() => {
          unpinMessage([message?.id]);
          unpinMe(message?.id);
        }}
      ></i>
      {message.pinned && (
        <i
          id={`pinMe_${message.id}_ok`}
          className="mdi mdi-pin-outline"
          onClick={() => {
            unpinMessage([message?.id]);
            unpinMe(message?.id);
          }}
        ></i>
      )}
      <div className="dropdown toggleBtns">
        <button
          className="toggledots"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="mdi mdi-dots-vertical"></i>
        </button>

        <ul
          className="dropdown-menu mx-0 shadow w-220px p-0"
          data-bs-theme="light"
        >
          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                editor?.chain().focus().run();
                setReplyToMessage(message);
              }}
            >
              <i className="mdi mdi-reply"></i>
              Reply
            </a>
          </li>
          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={copyMessageToClipboard}
            >
              <i className="mdi mdi-content-copy"></i>
              Copy
            </a>
          </li>
          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                messageinfo_open(message);
              }}
            >
              <i className="mdi mdi-information-outline"></i>
              Info
            </a>
          </li>

          <li
            id={`pinLi_${message.id}`}
            style={{ display: message.pinned ? "none" : "" }}
          >
            <a
              className="dropdown-item dropdown-item-danger d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                pinMessage([message?.id]);
                pinMe(message?.id);
              }}
            >
              <i className="mdi mdi-pin-outline"></i>
              Pin
            </a>
          </li>

          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                selectMessageFun(message);
              }}
            >
              <i className="mdi mdi-share"></i>
              Forward
            </a>
          </li>
          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                unreadMessage(message?.id);
              }}
            >
              <i className="mdi mdi-minus-circle-outline"></i>
              Unread Message
            </a>
          </li>
          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                selectMessageFun(message);
              }}
            >
              <i className="mdi mdi-check-circle-outline"></i>
              Select
            </a>
          </li>
          <li>
            <a
              className="dropdown-item d-flex gap-2 align-items-center"
              href="#"
              onClick={() => {
                deleteMessage(message);
              }}
            >
              <i className="mdi mdi-delete-outline"></i>
              Delete
            </a>
          </li>
        </ul>
      </div>
      {}
      {copied && openSuccessNotification("Message copied to clipboard!")}
    </>
  );
};
export default MsgDropdown;

import React, { useEffect, useState, useRef } from "react";
import { pinMessageList } from "../home-container/apis/Chatboxapis";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { truncateString } from "src/utils/utils";
import { currentGroup, setUserMessages, loadMoreChat } from "../home-container/actions/ChatHistoryAction";

const ViewPinMessageList = ({
  chatRoomId,
  isPinDrawerOpen,
  getCurrentTime,
  toggleDrawerX,
  anchor,
  unpinMessage,
  openDrawer,
  setOpenedDrawer,
  socket,
  formatMessage,
  setConversationData,
  setRevelingConMessage,
  scrollToItem,
}) => {

  const dispatch = useDispatch();

  const [pinnedMessage, setPinnedMessage] = useState([]);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const user = useSelector((state) => state.auth.user);
  const userMessages = useSelector((state) => state.chatHistoryReducer.userMessages);

  const getPinMessageList = async () => {
    try {
      let { data } = await pinMessageList(chatRoomId, user?._id, 0);
      setPinnedMessage(data);
    } catch (error) {
      console.error("Error fetching pinned messages:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (openDrawer.right  && chatRoomId) {
        await getPinMessageList();
      }
    };

    fetchData();

    return () => {
      
    };
  }, [openDrawer]);

  const [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
    msg: "",
    action: "unpin",
  });
  const { vertical, horizontal, msg, open } = state;

  
  
  

  const handleClick = (newState, item, isReveal) => async () => {
    setState({ ...newState, open: true });

    if (newState.unpinIds) {
      let res = await unpinMessage(newState.unpinIds);
      let pin1 = document.querySelector(`#pinMe_${newState.unpinIds[0]}_ok`);
      let pin2 = document.querySelector(`#pinMe_${newState.unpinIds[0]}`);
      let pin3 = document.querySelector(`#pp_${newState.unpinIds[0]}`);
      if (pin1) {
        pin1.style.display = "none";
      }
      if (pin2) {
        pin2.style.display = "none";
      }
      if (pin3) {
        pin3.style.display = "none";
      }
    }
    if (!isReveal) {
      setRevelingConMessage("");
    }
   if(isReveal)
   {
    setRevelingConMessage(item);
    const  msgInfo = {
      _id: item?._id,
      chatRoomId: item?.groupId ?item.groupId   : item?.onetooneId ,
      chatID:item?._id,
    };
    const exists = userMessages.some((item) => item.id === msgInfo?._id);
    if (exists) {
      scrollToItem(item?._id)
    } else {
      dispatch(setUserMessages({ userMessages:[], unReadMessageCounter: null }));
      setConversationData([]);
      socket.emit("revelInConversation", msgInfo);
      socket.on("revelInConversationResponse", handleConList);
    }
   
   }
    if (newState.action=="revel") {
      setTimeout(()=>{
        setOpenedDrawer({ right:false });
      },100)
    }
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  
  const observer = useRef(null);
  const lastItemRef = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMorePinnedMessages();
        }
      },
      { threshold: 1 }
    );

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (lastItemRef.current) {
        observer.current.unobserve(lastItemRef.current);
      }
    };
  }, [pinnedMessage]);

  const fetchMorePinnedMessages = async () => {
    try {
      if (!pinnedMessage.hasMore) {
        return; 
      }

      let { data } = await pinMessageList(
        chatRoomId,
        user?._id,
        pinnedMessage?.message?.length
      );
      if (data?.message?.length > 0) {
        setPinnedMessage((prevMessages) => ({
          message: [...prevMessages.message, ...data.message],
          hasMore: data.hasMore,
        }));
      }
    } catch (error) {
      console.error("Error fetching more pinned messages:", error);
    }
  };

  const checkFileType = (fileType) => {
    if (fileType.includes("image")) {
      return "image";
    } else if (fileType.includes("video")) {
      return "video";
    } else {
      return "other";
    }
  };

  const handleConList = ({ result }) => {
    // console.log("result", result);
    setTimeout(() => {
      dispatch(setUserMessages({ userMessages: result.map(formatMessage), unReadMessageCounter: null }));
      setConversationData(result.map(formatMessage));
    }, 2000);
  };

  return (
    <>
      <Box className="PinMessages">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Pin messages:</h4>
          <button className="PinClosed" onClick={toggleDrawerX(anchor, false)}>
            x
          </button>
        </div>
        <List>
          {pinnedMessage &&
            pinnedMessage.message &&
            pinnedMessage.message.map((item, index) => (
              <ListItem
                className="PinMessagesItem"
                key={index}
                disablePadding
                id={`pp_${item?._id}`}
                ref={
                  index === pinnedMessage.message.length - 1
                    ? lastItemRef
                    : null
                }
              >
                <ListItemButton>
                  <div className="ListBtnWText d-flex align-items-start justify-content-between gap-1">
                    <ListItemIcon className="PrImgne1">
                      <span
                        className="imggrp"
                        style={{
                          backgroundImage: `url(${item?.senderDetails?.otherInformation?.profilePicture})`,
                        }}
                      />{" "}
                    </ListItemIcon>

                    {item?.messageType == "link" && item?.isLink && (
                      <>{item?.message}</>
                    )}

                    <div className="ItemDtxt">
                      <ListItemText
                        className="textwing"
                        primary={
                          item?.messageType === "text" ? (
                            <>{item?.message}</>
                          ) : item?.messageType == "link" ? (
                            <a href={item?.message} target="_blank" rel="noreferrer">
                              {truncateString(item?.message, 20)}
                            </a>
                          ) : (
                            <>
                              {item?.filesData.map((file, ind) => {
                                const fileTyp = checkFileType(file.fileType); 

                                return (
                                  <a
                                    key={ind}
                                    href={file.url}
                                    className="d-block mb-2"
                                  >
                                    <i
                                      className={`mdi mdi-${
                                        fileTyp === "image"
                                          ? "file-image"
                                          : fileTyp === "video"
                                          ? "file-video"
                                          : "file"
                                      } font-20 pe-1`}
                                    ></i>{" "}
                                    {`${file.title.slice(0, 20)}${
                                      file.title.length > 20 ? "..." : ""
                                    }`}
                                  </a>
                                );
                              })}

                              {item?.isContact &&
                                item?.contactDetails &&
                                item?.contactDetails.map((itemx, ind) => {
                                  return (
                                    <div className="textmsg">
                                      <div className="bgretext">
                                        <div
                                          key={ind}
                                          className="miscall font-14 fw-bold d-flex align-items-center text-green bg-greenl"
                                        >
                                          <i className="mdi mdi-phone font-20 pe-1"></i>
                                          {itemx?.name}
                                          <br />
                                          {itemx?.contact}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          )
                        }
                      />
                      <ListItemText
                        className="textwdate"
                        primary={getCurrentTime(item?.timestamp)}
                      />
                    </div>
                  </div>

                  <div className="ListBtnPin d-flex align-items-center">
                    <Button
                      
                      
                      
                      onClick={handleClick(
                        {
                          vertical: "bottom",
                          horizontal: "left",
                          msg: `Unpin: ${item?.message}`,
                          unpinIds: [item?._id],
                          action: "unpin",
                        },
                        item,
                        false
                      )}
                    >
                      <i id={`pp_${item?._id}`} className="mdi mdi-pin-outline"></i>
                    </Button>
                    <Button
                      onClick={handleClick(
                        {
                          vertical: "bottom",
                          horizontal: "left",
                          msg: `Revel in chat: ${item?.message}`,
                          unpinIds: [],
                          action: "revel",
                        },
                        item,
                        true
                      )}
                    >
                      <i className="mdi mdi-eye"></i>
                    </Button>
                  </div>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>

      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal, msg }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msg}
          key={vertical + horizontal}
        />
      </Box>
    </>
  );
};
export default ViewPinMessageList;

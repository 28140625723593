import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik,Field, Form } from 'formik';
import FormikInput from 'src/components/formik/FormikInput';
import { UserYupSchema } from 'src/containers/admin-containers/user-container/UserPropTypes';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { userResetAction, userSearchAction, userUpsertAction,userUploadAction } from './actions/UserActions';
import { includeStamp } from 'src/utils/StampUtils';
import DesignationDropDown from 'src/business-components/DesignationDropDown';
import DepartmentDropDown from 'src/business-components/DepartmentDropDown';
import LocationDropDown from 'src/business-components/LocationDropDown';
import ReportingManagerDropDown from 'src/business-components/ReportingManagerDropDown';
import FormikCheckBox from 'src/components/formik/FormikCheckBox';
import {copyToClipboard, generateStrongPassword}  from "src/utils/CommonUtils";
import { toast } from 'react-toastify';
import FormikRadioBox from 'src/components/formik/FormikRadioBox';


const Render=(props)=>{
    const [isPasswordShow,setIsPasswordShow] = useState(false)
    const [isBulkUpload,setBulkUpload] =useState(false)
    const [file,setFile] = useState([]);
    const [imageSrc, setImageSrc] = useState('http://i.pravatar.cc/500?img=7');
    const {
        values, 
        handleClick,
        isSubmitting,
        setFieldValue,
        uploadUsers,
        onClear,
        resetForm,
    } = props;
    const handleOptionChange=(value)=>{
        setFieldValue('isOrange', value); 
    }
    const handleGeneratepass=()=>{
        const strongPassword = generateStrongPassword(6); 
        setFieldValue('password', strongPassword);
    }
    useEffect(()=>{
    },[values , onClear])
    const copyCredentialsToClipboard=()=>{
        if(values.email && values.password) 
        {
            const credentials = `user=>${values.email}\npassword=>${values.password}`;
            copyToClipboard(credentials);
            toast("credentials copied.")
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]; 
        if (file) { 
            const reader = new FileReader(); 
            reader.onload = (e) => {
                setImageSrc(e.target.result);
                setFieldValue('profilePicture', file);
            };
            reader.readAsDataURL(file);
        }
    };
    return(
        <>
            <div className="modal-body pt-0">
            <Form autoComplete="disabled">
                <div className="adduserbdyF" 
                style={{ display: `${(isBulkUpload)?"none":"block"}` }}
                id="adduserbdyF">
                    <div className="text-end">
                        <button
                        className="text-decoration-underline bg-none border-0 colorb font-14 fw-500"
                        type='button'
                        onClick={()=>{setBulkUpload(!isBulkUpload)}}
                        >
                        {`${(!isBulkUpload)?"Add Bulk Users":"Create Single User"}`}
                        </button>
                    </div>
                    <div className="avatar-upload adduserwdefine">
                        <div className="avatar-edit">
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={handleFileChange}
                            />
                        <label htmlFor="imageUpload" />
                        </div>
                        <div className="avatar-preview">
                        <div
                        id="imagePreview"
                        style={{
                        backgroundImage: `url(${imageSrc})`
                        }}
                        ></div>
                    </div>
                </div>
                <div className="todocreat">
                <label className="font-14 color1a fw-bold">User info</label>
                <FormikInput
                    value={values.firstName}
                    name="firstName"
                    placeholder="First Name"
                    className="form-control mb-2 border w-100"
                    /> 
                <FormikInput
                    value={values.lastName}
                    name="lastName"
                    placeholder="Last Name"
                    className="form-control mb-2 border w-100"
                    /> 
                <FormikInput
                   value={values.email}
                    name="email"
                    placeholder="Email"
                    className="form-control mb-2 border w-100"
                    />
                <DesignationDropDown
                    placeholder="Enter Designation"
                    name="otherInformation.designationId"
                    className="form-select mb-2"
                    value={values.otherInformation.designationId}
                    onChange={(id)=>{
                      values.otherInformation.designationId=id
                }}
                />
                <DepartmentDropDown
                    placeholder="Enter department"
                    name="otherInformation.departmentId"
                    className="form-select mb-2"
                    value={values.otherInformation.departmentId}
                    onChange={(id)=>{
                        values.otherInformation.departmentId=id
                }}
                />
                <LocationDropDown
                    placeholder="Enter Location"
                    name="otherInformation.locationId"
                    className="form-select mb-2"
                    value={values.otherInformation.locationId}
                    onChange={(id)=>{
                        values.otherInformation.locationId=id
                }}
                />
                <FormikInput
                    name="employeeID"
                    value={values.employeeID}
                    placeholder="Employee ID"
                    className="form-control mb-2 border w-100"
                    />
                <FormikInput
                    name="phone"
                    value={values.phone}
                    placeholder="Mobile Number"
                    className="form-control mb-2 border w-100"
                    /> 
                <ReportingManagerDropDown                    
                    name="otherInformation.reportingManagerId"
                    className="form-select mb-2"
                    value={values.otherInformation.reportingManagerId}
                    onChange={(id)=>{
                        values.otherInformation.reportingManagerId=id
                        }}
                />  
                <div className="yesnolable">
                <label className="font-14 color1a fw-bold mb-2 mt-3">
                Orange member
                </label>
                <div className="d-flex align-items-center">
                {" "}
                <label className="cstCheck fradio">
                <FormikRadioBox
                name="isOrange"
                value={true}
                label="Yes"
                chacked="chacked"
                />
                <span className="checkmark" />
                </label>
                <label className="cstCheck fradio">
                <FormikRadioBox
                name="isOrange"
                value={false}
                label="No" 
                />
                    <span className="checkmark" />
                </label>
                </div>
                </div>
                <div className="yesnolable mb-4">
                <label className="font-14 color1a fw-bold mb-2 mt-3">
                Role
                </label>
                <div className="d-flex align-items-center">
                {" "}
                <label className="cstCheck fradio">
                {}
                <FormikCheckBox name="isAdmin" chacked={(values.isAdmin)?true:false}/>
                Make Admin <span className="checkmark" />
                </label>
                </div>
                </div>
                <FormikInput
                    value={values.username}
                    name="username"
                    placeholder="User Name"
                    className="form-control mb-2 border w-100"
                    />  
                <div className="position-relative inpasswrd mb-4">
                <FormikInput
                    type={`${(isPasswordShow)?"text":"password"}`}
                    className="form-control mb-2 border w-100"
                    id="password-field1"
                    name="password"
                    />{" "}
                    <i
                    className="mdi mdi-eye-off field-icon toggle-password"
                    onClick={()=>{setIsPasswordShow(!isPasswordShow)}}
                    toggle="#password-field1"
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center font-14 fw-500 color33">
                Generate Password{" "}
                <button className="px-2 py-1 border-0 bg-white colorb" type='button' onClick={()=>{handleGeneratepass()}}>
                     <i className="mdi mdi-replay mdi-18px" />
                </button>
                </div>
                <div className="d-flex justify-content-between align-items-center font-14 fw-500 color33">
                Copy username and Password{" "}
                <button className="px-2 py-1 border-0 bg-white color33" type='button' onClick={()=>{copyCredentialsToClipboard()}}>
                <i className="mdi mdi-content-copy mdi-18px" />
                </button>
                </div>
                </div>
                <div
            className="modal-footer justify-content-center border-0 stickybottom"
            id="adduserfooter"
            >
            <div className="text-center w-100">
                <button type="submit" className="btnblue">
                Save
                </button>{" "}
            </div>
            </div>
            </div>
            </Form>
            {}
            <div
            className="addBulkuserbdyS"
            id="addBulkuserbdyS"
            style={{ display: `${(isBulkUpload)?"block":"none"}` }}>

            <div className="text-end">
            <button
            className="text-decoration-underline bg-none border-0 colorb font-14 fw-500"
            type='button'
            onClick={()=>{setBulkUpload(!isBulkUpload)}}
            >
                Create Singal User
            </button>
            </div> 
            <div className="text-center mt-4">
            <h3 className="font-14 color1a">Import Members</h3>
            <p className="font-12 color99">
                If you have large number of members, you can upload them using a
                XLSX file
            </p>
            <a className="font-12 color1a border bg-white py-1 px-2 rounded-1"
            href='/assets/doc/BulkUpload.users.csv'
            target='_blank'
            >
            Download a XLSX Sample{" "}
            <i className="mdi mdi-arrow-down colorb" />
            </a>
            </div>
            <div className="text-center fileuploadDe mt-4">
            <img
                src="/assets/images/admin/imgs/uploadfile.svg"
                width={53}
                height={45}
                className="img-fluid pt-4"
                />
            <div className="Upload pt-4">Upload the XLSX File</div>
            <input type="file" className="" name='usersSheet'
            onChange={(event) => {
                setFile(event.currentTarget.files[0]);
            }}
             />
            </div>
            <div className="text-center mt-3 mb-3">
            <button type="button" className="btnblue" onClick={()=>{uploadUsers(file)}}>
            Upload
            </button>{" "}
            </div>
            </div>
            {}
            </div>
            </>
  )
}

let setSubmitting = () => { };
const AddUserPopup=({setIsAddUser,isAddUser})=>{
  
  const dispatch = useDispatch();
  const {
      search,
      upsertReqStatus,
  } = useSelector((state) => state.UserReducer, shallowEqual);
  const {
     user,
  } = useSelector((state) => state.auth, shallowEqual);
  const currentRecord = useSelector((state) => state.UserReducer?.currentRecord);
  
  usePrevious({
    currentRecord,
    upsertReqStatus,
}, (prev) => {
    if (
        prev.currentRecord !== currentRecord
        || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
        setSubmitting(false);
    }
});

const onSubmit = (values, formikUtils) => {
    const { setSubmitting } = formikUtils;
    includeStamp(values); 
    Object.assign(values, { organization: user?.organization });
    dispatch(userUpsertAction(values));
    setIsAddUser(false);
    onClear();
    handleClose()
    dispatch(userResetAction());
};

 const onClear = () => {
    dispatch(userResetAction());
}
useEffect(()=>{

},[currentRecord])

const uploadUsers = useCallback((csvFile) => {
    dispatch(userUploadAction({data:{csvFile}}));
}, [
    
]);
useEffect(()=>{
 dispatch(userSearchAction());
},[dispatch ,isAddUser])

const handleClose = (()=>{
    setIsAddUser(false);
    dispatch(userSearchAction());
    onClear()
    dispatch(userResetAction());
})
const handleClick = ()=>{
    
    dispatch(userResetAction());
}

const UserForm = ({ currentRecord, onSubmit, onClear, uploadUsers, validationSchema }) => {
    useEffect(() => {
    }, [currentRecord , onSubmit,onClear ,isAddUser]);

    return (
        <Formik
            onSubmit={onSubmit}
            onClear={onClear}
            enableReinitialize
            initialValues={currentRecord}
            validationSchema={validationSchema}
        >
            {(formikProps) => (
                <Render
                    onClear={onClear} 
                    uploadUsers={uploadUsers}
                    {...formikProps}
                />
            )}
        </Formik>
    );
};
   return (
    <>
            <div
            className="chat-sidebar chat-animate-right"
            style={{ display: `${(isAddUser)?'block':'none'}`, right: 0, top: 0, marginTop: 43, zIndex: 10,width:'20%' }}
            id="adduser"
            >
            <div className="modal-dialog-scrollable">
            <div className="modal-content">
                <div className="slide-header border-bottom mb-2">
                    <div className="HeadSlidName d-flex justify-content-between align-items-center">
                        <div className="slidName">Add User</div>
                        <button className="" onClick={handleClose}>
                        ×
                        </button>
                    </div>
                </div>
             <UserForm
                currentRecord={currentRecord}
                onSubmit={onSubmit}
                onClear={onClear}
                uploadUsers={uploadUsers}
                validationSchema={UserYupSchema}
            />
            </div>
            </div>
            </div>
    </>
   )
}
export default AddUserPopup
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDetails,
  joinGroup,
  updateFavUser,
} from "../home-container/apis/Groupapis";
import { updateLiveStatus } from "../home-container/apis/UserApis";
import { setUserMessages, setReceiptent } from "../home-container/actions/ChatHistoryAction";
import UserStatus from "src/components/sideUserGroupList/UserStatus";
import AllMessageTabList from "src/components/sideUserGroupList/AllMessageTabList";
import UserChatProfile from "src/components/sideUserGroupList/profile/UserChatProfile";
import GroupChatProfile from "src/components/sideUserGroupList/profile/GroupChatProfile";
import PropTypes from "prop-types";
import { HOME_ACTIONS, onlineUserListAction } from "../home-container/actions/HomeActions";

const FavList = ({
  resetAndToggleModal,
  socket,
  startChat,
  getCurrentTime,
  groupList,
  setChatRoomId,
  setSelectedUserOrGroup,
  setReceiverData,
  onlineUserList,
  setOnlineUserList,
  one2oneTyping,
  groupTyping,
  getRootProps,
  getInputProps,
  toggleDrawer,
  setIsPinDrawerOpen,
  setSetCurrentActiveTab,
  selectedRoomId,
  setSelectedRoomId
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { jwtToken: authToken } = user || {};
  const [allUser, setAllUser] = useState([]);
  const [orangeUserList, setOrangeUserList] = useState([]);
  const [renderedItems, setRenderedItems] = useState([]);
  const [searchedUserItems, setSearchedUserItems] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchUsersResponse, setSearchUsersResponse] = useState([]);
  const [totalUnRead, setTotalUnRead] = useState(0);

  useEffect(() => {
    if (socket) {
      socket.emit("getChatList", {
        userId: user?._id,
        organizationId: user?.organization,
      });
      socket.on("getChatListSpecific", handleSocketUserListResponse);
      socket.on("getOnlineUserList", handleOnlineUserList);
      socket.on("searchUserResponse", handleSocketSearchedUserListResponse);
    }
  }, [socket]);

  useEffect(() => {
    if (allUser.length > 0) {
      setRenderedItems(renderUserItems(allUser));
    }
  }, [allUser, orangeUserList, onlineUserList, one2oneTyping, groupTyping]);

  useEffect(() => {
    if (searchUsersResponse.length > 0) {
      setSearchedUserItems(renderSearchUserItems(searchUsersResponse));
    }
  }, [searchUsersResponse]);

  useEffect(() => {
    if (window.electron && window.electron.setBadgeCount) {
      window.electron.setBadgeCount(totalUnRead > 0 ? totalUnRead : 0);
    }
  }, [totalUnRead]);

  const handleSocketUserListResponse = ({ list }) => {
    setAllUser(list);
  };
  const handleOnlineUserList = async ({ onlineUser }) => {
    // setOnlineUserList(onlineUser);
    dispatch(onlineUserListAction({
      type: HOME_ACTIONS.HOME_ONLINE_USER_LIST_SUCCESS,
      data: [...onlineUser],
    }
    ));
  };
  const handleSocketSearchedUserListResponse = ({ list }) => {
    setSearchUsersResponse(list);
  };
  const markAsFavUser = async (chatHistoryId) => {
    await updateFavUser(chatHistoryId?._id);
    let elem = document.getElementById(`str_${chatHistoryId?._id}`);
    let iconElem = elem.querySelector("i");
    iconElem.classList.toggle("active");

    let elemGroupSingle = document.getElementById(
      `str_chat_${chatHistoryId?.roomId}`
    );
    if (elemGroupSingle) {
      let iconElemGroupSingle = elemGroupSingle.querySelector("i");
      iconElemGroupSingle.classList.toggle("active");
    }

    if (chatHistoryId?.type == "single") {
      chatHistoryId?.participants.map((item) => {
        if (item?._id != user?._id) {
          let elemGroupSingle = document.getElementById(
            `str_chat_${item?._id}`
          );
          if (elemGroupSingle) {
            let iconElemGroupSingle = elemGroupSingle.querySelector("i");
            iconElemGroupSingle.classList.toggle("active");
          }
        }
      });
    }

    socket?.emit("getChatList", {
      userId: user?._id,
      organizationId: user?.organization,
    });
  };

  const renderUserItems = useCallback(
    (users) => {
      const filteredUsers = users
        .filter((item) => item.isFav && item.isFav.includes(user?._id))
        .map((item, index) => (
          <React.Fragment key={index}>
            {item.type === "single" && item.participants
              ? renderSingleUser(item, index)
              : renderGroupUser(item, index)}
          </React.Fragment>
        ));

      return filteredUsers;
    },
    [onlineUserList, one2oneTyping, groupTyping]
  );

  const renderSingleUser = useCallback(
    (item, index) => {
      return item.participants.map((participantsUser, ind) => {
        if (participantsUser?._id !== user?._id) {
          return (
            <UserChatProfile
              resetAndToggleModal={resetAndToggleModal}
              ind={ind}
              index={index}
              getRootProps={getRootProps}
              handleUserClick={handleUserClick}
              item={item}
              participantsUser={participantsUser}
              onlineUserList={onlineUserList}
              one2oneTyping={one2oneTyping}
              getInputProps={getInputProps}
              markAsFavUser={markAsFavUser}
              user={user}
              setTotalUnRead={setTotalUnRead}
            />
          );
        }
        return null;
      });
    },
    [
      onlineUserList,
      one2oneTyping,
      getCurrentTime,
      getInputProps,
      markAsFavUser,
    ]
  );

  const renderGroupUser = useCallback(
    (item, index) => {
      return item?.groupDetails?.map((grp, groupIndex) => (
        <GroupChatProfile
          resetAndToggleModal={resetAndToggleModal}
          groupIndex={groupIndex}
          getRootProps={getRootProps}
          grp={grp}
          handleGroupClick={handleGroupClick}
          groupTyping={groupTyping}
          user={user}
          item={item}
          setTotalUnRead={setTotalUnRead}
          markAsFavUser={markAsFavUser}
        />
      ));
    },
    [groupTyping, getCurrentTime, getInputProps]
  );

  const renderSearchUserItems = (users) => {
    const filteredUsers = users
      .filter((item) => item.isFav && item.isFav.includes(user?._id))
      .map((item, index) => (
        <div
          className="chat-user-list"
          key={index}
          {...getRootProps()}
          onClick={(e) => e.preventDefault()}
        >
          <a href="#" className="d-flex align-items-start">
            <div className="flex-shrink-0 position-relative">
              <img
                className="img-fluid userimg"
                src={item?.profilePicture || "./assets/images/user.svg"}
                width="45"
                height="45"
                alt="user img"
                style={{
                  border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
                  borderWidth: "2px",
                }}
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="d-flex align-items-center justify-content-between">
                <input {...getInputProps()} multiple />
                <h3>{`${item?.firstName} ${item?.lastName}`}</h3>
              </div>
              <div className="font-14 d-flex align-items-center text66">
                {item?.action}
              </div>
              <div className="text onelinetext font-14 d-flex align-items-center text66">
                {item?.message}
              </div>
              <div className="onlineOfine d-flex justify-content-between align-items-center">
                <div className="clock font-14 textb3">
                  { } {getCurrentTime(item?.lastSeen)}
                </div>
              </div>
            </div>
          </a>
          <div className="d-flex align-items-center actvnewi flex-column-reverse">
            <button
              className={`orangestar`}
              id={`str_${item?._id}`}
              onClick={() => markAsFavUser(item?._id)}
            >
              <i
                className={`mdi mdi-star mdi-18px ${item?.isFav && item?.isFav.includes(user?._id) ? "active" : ""
                  }`}
              ></i>
            </button>
          </div>
        </div>
      ));

    return filteredUsers;
  };


  const handleUserClick = (e, item, participantsUser) => {
    e.preventDefault();
    setSetCurrentActiveTab("chat")
    startChat(participantsUser);
    // socket.on("getChatListSpecific", handleSocketUserListResponse);
    setChatRoomId(item?.roomId);

    dispatch(setReceiptent({ receiver: item }));
    dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));

    setSelectedRoomId(item?._id);
    setAllUser(
      prevState =>
        prevState.map(user =>
          user._id === item?._id ? { ...user, unRead: 0 } : user
        ));
    toggleDrawer();
    setIsPinDrawerOpen(false);
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatProfile").style.display = "none";
  };
  const handleGroupClick = (e, item, grp) => {
    e.preventDefault();
    setSetCurrentActiveTab("chat")

    const superAdmin = item?.participants.find(elum => elum._id == grp?.userId);
    item.groupDetails[0].superAdmin = [superAdmin];
    item.groupDetails[0].participantsDetails = item?.participants.filter(elem => !item?.noParticipants.includes(elem._id));
    dispatch(setReceiptent({ receiver: item }));
    dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
    // console.log("joinGroupFun from here=========================>");
   
    joinGroup(socket, user?._id, grp?._id, user?.organization);
    startChat(null);

    localStorage.setItem("selectedUserOrGroup", JSON.stringify(grp));
    localStorage.setItem("selectedGroup", JSON.stringify(grp));
    setSelectedUserOrGroup(grp);
    setReceiverData(grp);

   
    localStorage.setItem("chatRoomId", (grp?._id).toString());
    setSelectedRoomId(grp?._id);

    // joinGroupFun(grp);
    setChatRoomId(item?.roomId);
    setAllUser(prevState => prevState.map(user =>
      user._id === item?._id ? { ...user, unRead: 0 } : user
    ));
    // socket.on("getChatListSpecific", handleSocketUserListResponse);
    toggleDrawer();
    setIsPinDrawerOpen(false);
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatProfile").style.display = "none";
  };
  const joinGroupFun = async (selectedGroup) => {

    console.log("Fav joinGroupFun", selectedGroup);

    let { data } = await getDetails(selectedGroup?._id);
    if (socket && data) {
      joinGroup(socket, user?._id, selectedGroup?._id, user?.organization);
      setChatRoomId(selectedGroup?._id);
      setSelectedRoomId(selectedGroup?._id);
      setSelectedUserOrGroup(data.groups[0]);
      setReceiverData(data.groups[0]);
      localStorage.setItem("chatRoomId", (selectedGroup?._id).toString());
      localStorage.setItem("selectedUserOrGroup", JSON.stringify(data.groups[0]));
    }
  };

  const getCurrentStatus = async () => {
    socket?.emit("userStatusUpdate", {
      userId: user?._id,
      organizationId: user?.organization,
    });
  };

  const changeStatus = async (status) => {
    const customStatus = "";
    let res = await updateLiveStatus(status, customStatus, authToken);
    if (res) {
      setTimeout(() => {
        getCurrentStatus();
      }, [2000]);
    }
  };

  const handleReload = () => {
    window.document.getElementById("spinner").classList.add("mdi-spin");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  return (
    <>
      <div className="modal-dialog-scrollable" id="favoriteChats">
        <div className="modal-content">
          <div className="chat-header">
            <div className="userstatus d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="userName">
                  Hi,{" "}
                  {user?.firstName?.replace(/\b\w/g, (char) =>
                    char?.toUpperCase()
                  )}
                  !
                </div>
                <UserStatus changeStatus={changeStatus} />
              </div>

              <div className="refrshbtn">
                <button className="">
                  <i
                    className="mdi mdi-flattr"
                    id="spinner"
                    onClick={handleReload}
                  ></i>
                </button>
              </div>
            </div>

            { }
            { }

            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName font-24 color1a">Favorite chats </div>
                <button
                  className=""
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  ×
                </button>
              </div>
            </div>
          </div>

          <div className="modal-body">
            { }
            <div className="chat-lists">
              <div className="tab-content">
                <AllMessageTabList
                  searching={searching}
                  searchedUserItems={searchedUserItems}
                  renderedItems={renderedItems}
                />
              </div>
            </div>
            { }
          </div>
        </div>
      </div>
      <div className="splitter" style={{ touchAction: "none" }}></div>
    </>
  );
};

FavList.propTypes = {
  resetAndToggleModal: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  startChat: PropTypes.func.isRequired,
  getCurrentTime: PropTypes.func.isRequired,
  groupList: PropTypes.array.isRequired,
  setChatRoomId: PropTypes.func.isRequired,
  setSelectedUserOrGroup: PropTypes.func.isRequired,
  setReceiverData: PropTypes.func.isRequired,
  onlineUserList: PropTypes.array.isRequired,
  setOnlineUserList: PropTypes.func.isRequired,
  one2oneTyping: PropTypes.object.isRequired,
  groupTyping: PropTypes.object.isRequired,
  getRootProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setIsPinDrawerOpen: PropTypes.func.isRequired,
};

export default FavList;

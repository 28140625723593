import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { getLastSeenString } from "../profile-container/ProfileDetailsutils";
import { Button, Snackbar, SnackbarContent } from "@mui/material";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import AudioMessage from "./AudioMessage";
import BulletList from "@tiptap/extension-bullet-list";
import CustomCheckbox from "src/components/chatbox/CustomCheckbox";
import MsgDropdown from "src/components/chatbox/MsgDropdown";
import MessageActionAndFilter from "src/components/chatbox/MessageActionAndFilter";
import Typography from '@mui/material/Typography';
import { useNotification } from "src/components/Notifination/ToastNotification";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { Chip } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from "@mui/icons-material/Image";
import CircularProgress from "@mui/material/CircularProgress";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import { deleteFile } from "../profile-container/ProfileServices";
import Code from "@tiptap/extension-code";
import CodeBlock from "@tiptap/extension-code-block";
import { VoiceVisualizer } from "react-voice-visualizer";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import EmojiReactions from "src/components/chatbox/EmojiReactions";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Image from "@tiptap/extension-image";
import { updateFavUser } from "../home-container/apis/Groupapis";
import parse from "html-react-parser";
import { common, createLowlight } from "lowlight";
import CarouselDialog from "./CarouselDialog";
import { getMediaSlides } from "../home-container/apis/Chatboxapis";
import {
  capitalizeFirstLetter,
  formatFileSizeUpload,
  getFileType,
  makeUniqueById,
} from "src/utils/utils";
import SkeletonLoaderMessage from "./SkeletonLoaderMessage";
import GoogleMapShare from "./GoogleMapShare";
import DeleteFiles from "./DeleteFiles";
import SkeletonLoaderSmall from "./SkeletonLoaderSmall";
import { redirectTargetBlank, titleCase } from "src/libs/common";
import { ReactRenderer } from "@tiptap/react";
// import { Link } from "react-router-dom";
import tippy from "tippy.js";
import InfiniteScroll from "react-infinite-scroll-component";
import GooMap from "./GooMap";
import MuteUnMute from "./ChatBox/Header/MuteUnMute";

import ViewPinMessageList from "./ViewPinMessageList";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import lamejs from "lamejs";
import Fancybox from "./Fancybox";
import ViewGiphy from "./ViewGiphy";
import Scroll2Bottom from "src/components/Scroll2Bottom";
import $ from "jquery";
import DownloadSnackbar from "./ChatBox/DownloadSnackbar";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { currentGroup, setUserMessages, loadMoreChat } from "../home-container/actions/ChatHistoryAction";
import { resetUnReadCount } from "src/redux/slices/chatListSlice";

const lowlight = createLowlight(common);
let isRendered = false;
let timeoutId = null;
const content = ``;
const ffmpeg = new FFmpeg();

const profile_open = () => {
  const mainElement = document.getElementById("main");
  const myProfileElement = document.getElementById("myChatProfile");
  if (mainElement && myProfileElement) {
    mainElement.style.marginRight = "265px";
    myProfileElement.style.width = "265px";
    myProfileElement.style.display = "block";
  }
};

const pinMessage_open = () => {
  const mainElement = document.getElementById("main");
  const myProfileElement = document.getElementById("myChatPinMessages");
  if (mainElement && myProfileElement) {
    mainElement.style.marginRight = "265px";
    myProfileElement.style.width = "265px";
    myProfileElement.style.display = "block";
  }
};
// Function to handle span click events
function handleSpanClick(event) {
  event.preventDefault();

  const url = event.target.innerHTML.trim();

  // Improved regex for URL validation
  const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;

  if (urlPattern.test(url) && !/<[^>]*>/.test(url)) {
    if (window.electron && window.electron.openExternal) {
      window.electron.openExternal(url);
    } else {
      window.open(url, '_blank');
    }
  } else {
    console.log('Invalid URL or HTML tag detected.');
  }
}


// Select the <span> element (assuming it has a specific ID or class)
const spanElement = document.querySelector('#clickable-span'); // Replace with your specific selector

// Attach the click event listener to the <span>
spanElement?.addEventListener('click', handleSpanClick);
function FileAvatar(fileName) {
  let iconComponent;
  if (fileName) {
    switch (fileName) {
      case "audio":
        iconComponent = <AudiotrackIcon style={{ color: "#1c9dea" }} />;
        break;
      case "video":
        iconComponent = <VideocamIcon style={{ color: "#1c9dea" }} />;
        break;
      case "image":
        iconComponent = <ImageIcon style={{ color: "#1c9dea" }} />;
        break;
      default:
        iconComponent = <InsertDriveFileIcon style={{ color: "#1c9dea" }} />;
        break;
    }
  } else {
    iconComponent = <InsertDriveFileIcon style={{ color: "#1c9dea" }} />;
  }

  return iconComponent;
}

const initialState = {
  roomId: "",
  fileId: "",
  skip: 0,
  limit: 10,
  slides: [],
  type: "",
  counts: 0,
};
const Chatbox = ({

  // const Chatbox = forwardRef(({
  displayChatCounter,
  setDisplayChatCounter,
  newChatLoad,
  setNewChatLoad,
  lastElementId,
  setLastElementId,
  isScrollUp,
  setIsScrollUp,
  moreTopMessages,
  setMoreTopMessages,
  scrollPosition,
  setScrollPosition,
  newMessage,
  setNewMessage,
  setLastReadMessageIdState,
  lastReadMessageIdState,
  searchMessageString,
  searchMessageType,
  selectedUserOrGroup,
  getCurrentTime,
  chatRoomId,
  message,
  setMessage,
  sendMessage,
  chatMessage,
  setChatMessage,
  editorRef,
  textMessage,
  setTextMessage,
  audioRef,
  audioBlob,
  isRecording,
  isPaused,
  recordingDuration,
  handleStartRecording,
  handleStopRecording,
  handlePauseRecording,
  handleResumeRecording,
  handleSendData,
  handlePlayAudio,
  handleCancelRecording,
  showAudioRecorder,
  setShowAudioRecorder,
  userList,
  searchText,
  setSearchText,
  handleSelect,
  selectedUsers,
  searchedUsers,
  setSelectedUsers,
  setSearchedUsers,
  getLocation,
  handleFileUpload,
  conversationMessage,
  setConversationMessage,
  setMessageInfo,
  setDeleteMessageFlag,
  setDeleteMessageFlagGroup,
  setEveryone,
  setMesageInfoFlag,
  messageinfo_open,
  deleteMessage,
  pinMessage,
  unpinMessage,
  unreadMessage,
  messageActionFlag,
  messageFilterFlag,
  setMessageActionFlag,
  setMessageFilterFlag,
  setSelectMessages,
  setMediaToForward,
  mediaToForward,
  selectMessages,
  isMessageActionOpen,
  setIsMessageActionOpen,
  isUserPopupOpen,
  setIsUserPopupOpen,
  SearchActionUser,
  deleteMultipleMessages,
  accountSetting,
  setSearchMessageString,
  setSearchMessageType,
  searchMessage,
  showModal,
  setShowModal,
  fileNames,
  replyToMessage,
  setReplyToMessage,
  replyToMedia,
  setReplyToMedia,
  setShowFileModal,
  revelingConMessage,
  loading,
  setLoading,
  getUserProfileDetails,
  location,
  setLocation,
  socket,
  setFileIds,
  fileIds,
  setFileNames,
  getRootProps,
  getInputProps,
  setIsPinDrawerOpen,
  isPinDrawerOpen,
  // onlineUserList,
  one2oneTyping,
  groupTyping,
  toggleRightBarDrawer,
  dateRange,
  setDateRange,
  loader,
  progress,
  recordedBlob,
  error,
  isCleared,
  isPausedRecording,
  isRecordingInProgress,
  recorderControls,
  setAudioBlob,
  clearCanvas,
  setIsRecording,
  suggestions,
  setSuggestions,
  selectedSuggestions,
  setSelectedSuggestions,
  taggedUsers,
  setTaggedUsers,
  setSelectedIndex,
  selectedIndex,
  setSelectedIndexes,
  selectedIndexes,
  setTaggedUserSelection,
  startChat,
  scrollToItem,
  listRef,
  targetRef,
  conversationData,
  openedRightBarDrawer,
  setConversationData,
  setRevelingConMessage,
  resetAndToggleModal,
  isScrollToBottomDisable,
  SetIsScrollToBottomDisable,
  setOne2oneTyping,
  handleInitialMessages,
  isLoadingMessage,
  setIsLoadingMessage,
  setSelectedGroupOpen,
  setIsTabVisible,
  setIsScrolledToBottom,
  handleUnreadCounts,
  setUnReadChat,
  isScrolledToBottom,
  handleSocketUserListResponse,
  setShowEmojiPicker,
  showEmojiPicker
}) => {
  // }, ref) => {
  const dispatch = useDispatch();

  const [messages, setMessages] = useState([]);
  const [hasMoreTop, setHasMoreTop] = useState(true);
  const [hasMoreBottom, setHasMoreBottom] = useState(true);
  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const topObserver = useRef(null);
  const bottomObserver = useRef(null);
  const allUser = useSelector((state) => state.chatList.chats);

  const chatRoomIdX = localStorage.getItem("chatRoomId");
  const [isTyping, setIsTyping] = useState(false);

  const authUser = useSelector((state) => state.auth.user);

  const chatHistoryReducer = useSelector((state) => state.chatHistoryReducer);
  const userMessages = chatHistoryReducer?.userMessages;
  const recipient = chatHistoryReducer.recipient;

  const onlineUserList = useSelector(
    (state) => state.HomeReducer.onlineUserList
  );
 
  const groupDetails_open = () => {
    setSelectedGroupOpen((prevState) => !prevState);
  
    setTimeout(() => {
      // Using querySelector to select elements
      const mainElement = document.querySelector("#main");
      const groupDetailsElement = document.querySelector("#groupDetails");
  
      if (mainElement && groupDetailsElement) {
        mainElement.style.marginRight = "300px";
        groupDetailsElement.style.width = "300px";
        groupDetailsElement.style.display = "block";
      }
    }, 0);
  };
  
  const [open, setOpen] = useState(false);

  const [isEmpty, setIsEmpty] = useState(true);
  const [minHeight, setMinHeight] = useState("45px");
  const user = useSelector((state) => state.auth.user);
  const { jwtToken: authToken } = user || {};
  const currentProfile = useSelector(
    (state) => state.ChatBoxReducer.currentRecord
  );
  const [copied, setCopied] = useState(false);
  const { openSuccessNotification, openErrorNotification } = useNotification();

  const [isReacted, setIsReacted] = useState(false);
  const [clickedMessageId, setClickedMessageId] = useState(null);
  const [slider, setSlider] = useState(initialState);
  // const [isScrollUp, setIsScrollUp] = useState(false);
  // const [scrollPosition, setScrollPosition] = useState(false);
  const [downloads, setDownloads] = useState([]);

  const truncateFileName = (fileName, maxLength = 15) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.slice(fileName.lastIndexOf("."));
    const baseName = fileName.slice(0, maxLength - extension.length);
    return `${baseName}...${extension}`;
  };

  const updateDownloadProgress = (id, progress, success = false) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id
          ? { ...download, progress, success, open: progress < 100 }
          : download
      )
    );
  };
  const isTimestamp = (name) => {
    const timestampPattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|([+-]\d{2}:\d{2}))$/;
    return timestampPattern.test(name);
  };
  const handleDownload = async (url, fileName, mediaItem) => {
    const modifiedFileName = isTimestamp(fileName)
      ? `screenshot_${Math.floor(Math.random() * 10000)}.png`
      : fileName;
    const id = new Date().getTime();
    const imagesType = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/webp",
      "image/svg+xml",
      "image/heif",
      "image/heic",
      "image/x-icon",
      "image/pipeg",
      "image/apng",
      "image/jxr",
    ];
    try {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: modifiedFileName,
        types: [{ description: "All Files" }],
      });

      const writableStream = await fileHandle.createWritable();

      if (isTimestamp(fileName) || imagesType.includes(mediaItem?.fileType)) {
        try {
          const response = await fetch(url, { mode: 'cors' });
          if (!response.ok) {
            throw new Error(`Failed to fetch the file. Status: ${response.status}`);
          }
          const blob = await response.blob();
          await writableStream.write(blob);
          await writableStream.close();

          openSuccessNotification("Download complete.");
        } catch (error) {
          console.log("Download error:", error);
          openErrorNotification(
            "An error occurred during the download. Please check your connection and try again."
          );
        }
      } else {
        setDownloads((prev) => [
          ...prev,
          {
            id,
            progress: 0,
            success: false,
            fileName: truncateFileName(modifiedFileName),
            open: true,
          },
        ]);
        let start = 0;
        let end = 1024 * 1024 - 1;
        let downloadedBytes = 0;
        const getFileSize = async () => {
          const response = await fetch(url);
          return parseInt(response.headers.get("Content-Length"), 10);
        };
        const totalSize = await getFileSize();
        const chunks = [];

        while (downloadedBytes < totalSize) {
          const range = `bytes=${start}-${end}`;
          const response = await fetch(url, { headers: { Range: range } });
          if (!response.ok) {
            throw new Error(`Failed to fetch range ${range}: ${response.statusText}`);
          }
          const blob = await response.blob();
          chunks.push(blob);
          downloadedBytes += blob.size;
          start = end + 1;
          end = start + 1024 * 1024 - 1;
          if (end >= totalSize) end = totalSize - 1;
          const percentCompleted = Math.round((downloadedBytes * 100) / totalSize);
          updateDownloadProgress(id, percentCompleted, downloadedBytes === totalSize);
        }
        const finalBlob = new Blob(chunks);
        await writableStream.write(finalBlob);
        await writableStream.close();
      }
    } catch (error) {
      console.error("Download error:", error);
      updateDownloadProgress(id, 0, false);
    }
  };

  const handleCloseSnackbar = (id) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id ? { ...download, open: false } : download
      )
    );
  };

  const [removedMentions, setRemovedMentions] = useState([]);

  const toggleReaction = (id) => {
    setIsReacted(!isReacted);
    setClickedMessageId(id);
  };
  const handleMouseEnter = (id) => {
    setIsReacted(true);
    setClickedMessageId(id);
  };

  const handleMouseLeave = () => {
    setIsReacted(false);
  };

  const handleMentionClick = (event) => {
    const mention = event.target;
    const mentionValue = mention.getAttribute("data-id");
    setTaggedUserSelection(mentionValue);
    const value1 = mentionValue?.split(" ")[0];
    const value2 = mentionValue?.split(" ")[1];
    if (authUser?.lastName !== value2) {
      const user = userList.find(
        (user) => user?.firstName === value1 && user?.lastName === value2
      );
      startChat(user);
    }
  };

  const addMentionShowClass = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    const mentions = tempDiv.querySelectorAll(
      'span.mention[data-type="mention"]'
    );
    mentions.forEach((mention) => {
      mention.classList.add("mentionShow");
    });
    return tempDiv.innerHTML;
  };
  const handleMobileLinks = (text) => {
    function addSpaceBeforeHttp(inputString) {
      return inputString.replace(/http/g, ' http', 'www');
    }
    const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
    const htmlTagPattern = /<[^>]*>/;
    const words = addSpaceBeforeHttp(text).split(' ');
    const filterWords = words.map(word => word.replace("\n", ""))
    const processedWords = filterWords.map(word => {
      if (urlPattern.test(word) && !htmlTagPattern.test(word)) {
        return `<a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`;
      } else {
        return `<span>${word}</span>`;
      }
    });
    return processedWords.join(' ');
  };

  function renderMessageContent(message) {
    if (
      message?.deleteForAll === true || (message?.deleteForMe && message.deleteForMe.includes(user?._id))
    ) {
      return (
        <div className="deletedMessageStyle">
          <img
            src="https://img.icons8.com/ios-glyphs/30/000000/cancel.png"
            alt="Deleted icon"
          />
          <span>This message was deleted</span>
        </div>
      );
    }

    if (message.content == "" && message.messageHtml != "") {
      return parse(message.messageHtml);
    }
    if (message?.isNotification) return null;
    const getContent = () => {
      return message.messageHtml?.length > 0
        ? parse(addMentionShowClass(message.messageHtml))
        : parse(addMentionShowClass(message.content));
    };

    return message?.messageType === "link" ? (
      <span id="clickable-span" onClick={(e) => handleSpanClick(e)}>
        {message.messageHtml?.length > 0 ? (
          parse(`${message.messageHtml}`)
        ) : (
          <>{parse(handleMobileLinks(message?.content))}</>
        )}
      </span>
    ) : (
      <div
        onMouseEnter={() => handleMouseEnter(message.id)}
        onMouseLeave={handleMouseLeave}
        onClick={handleMentionClick}
      >
        {getContent()}
      </div>
    );
  }

  const handleConList = ({ result }) => {
    setTimeout(() => {
      dispatch(setUserMessages({ userMessages: result.map(formatMessage), unReadMessageCounter: null }));
      setConversationData(result.map(formatMessage));
    }, 2000);
  };

  const handleClickOnReply = (msg, reveal) => {
    let msgInfo = {};
    const isFile = msg?.filesData?.length > 0;
    if (isFile && isFile > 0) {
      msgInfo = {
        _id: msg?._id,
        chatRoomId: msg?.groupId ? msg?.groupId : msg?.onetooneId,
        chatID: msg?._id,
      };
      scrollToItem(msg?._id);
    } else {
      msgInfo = {
        _id: msg?.parentConversation?._id,
        chatRoomId: msg?.parentConversation?.groupId
          ? msg?.parentConversation?.groupId
          : msg?.parentConversation?.onetooneId,
        chatID: msg?.parentConversation?._id,
      };
      scrollToItem(msg?.parentConversation?._id);
    }
    setRevelingConMessage(msg);
    const exists = userMessages.some((item) => item.id === msgInfo?._id);
    if (exists) {
      setTimeout(() => {
        return true;
      }, 1000);
    } else {
      dispatch(setUserMessages({ userMessages: [], unReadMessageCounter: null }));
      setConversationData([]);
      socket.emit("revelInConversation", msgInfo);
      socket.on("revelInConversationResponse", handleConList);
    }
  };

  const loadInitialMessages = () => {
    setLoading(true);
    setTimeout(() => {
      const initialMessages = Array.from({ length: 20 }, (_, i) => ({
        id: i + 1,
        text: `Initial message ${i + 1}`,
      }));
      setMessages(initialMessages);
      setLoading(false);
    }, 1000);
  };

  const loadMoreMessagesx = (position) => {
    if (loading) return;
    setLoading(true);

    setTimeout(() => {
      const newMessages = Array.from({ length: 20 }, (_, i) => ({
        id:
          position === "top"
            ? messages.length + i + 1
            : messages.length + i + 1,
        text: `Message ${position} - ${position === "top" ? messages.length + i + 1 : messages.length + i + 1
          }`,
      }));

      setMessages((prevMessages) =>
        position === "top"
          ? [...newMessages, ...prevMessages]
          : [...prevMessages, ...newMessages]
      );

      setLoading(false);
      if (newMessages.length < 20) {
        if (position === "top") {
          setHasMoreTop(false);
        } else {
          setHasMoreBottom(false);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    // loadInitialMessages();
    socket?.on("readAllMessageResponse", handleReadAllMessageResponse);
    return () => {
      socket?.off("readAllMessageResponse", handleReadAllMessageResponse);
    };
  }, []);



  const selectedUserOrGroupRef = useRef(selectedUserOrGroup);
  useEffect(() => {
    selectedUserOrGroupRef.current = selectedUserOrGroup;
  }, [selectedUserOrGroup]);

  const handleReadAllMessageResponse = ({ userId, message, roomId }) => {
    console.log("roomId", roomId);
    dispatch(resetUnReadCount({ roomId:roomId }));

    // console.log("chatRoomIdX", chatRoomIdX);
    // console.log("selected item inner", selectedUserOrGroupRef.current);
    // if (selectedUserOrGroupRef?.current) {
    //   if (chatRoomIdX === roomId && selectedUserOrGroupRef.current?._id === userId) {
    //     let elements = document.querySelectorAll(".readmsg");
    //     Array.from(elements).forEach((element) => {
    //       let iTag = element.querySelector("i");
    //       if (iTag) {
    //         iTag.classList = "mdi mdi-check-all active";
    //       }
    //     });
    //   }
    // }
  };



  useEffect(() => {
    const handleDeleteFileResponse = ({
      response,
      message,
      roomId,
      userId,
    }) => {
      if (chatRoomIdX && chatRoomIdX == roomId) {
        response.forEach((item) => {
          let mediaElement = document.querySelector(`#media_${item}`);
          if (mediaElement) {
            mediaElement.style.display = "none";
          }

          setTimeout(() => {
            let visibleFileOpenNewElements = document.querySelectorAll(
              ".fileOpenNew:not(.hidden)"
            );
            let visibleCount = 0;

            visibleFileOpenNewElements.forEach((element) => {
              if (window.getComputedStyle(element).display !== "none") {
                visibleCount++;
              }
            });

            if (visibleCount === 0 && mediaElement) {
              let liElement = mediaElement.closest("li");

              if (liElement) {
                liElement.style.display = "none";
              }
            } else {
              openErrorNotification(`There are ${visibleCount} visible 'fileOpenNew' elements. Not hiding the <li> element.`)
            }
          }, 300);
          setTimeout(() => {
            let mediaElementGallery = document.querySelector(`#md_${item}`);
            if (mediaElementGallery) {
              try {
                mediaElementGallery.style.display = "none";
              } catch (error) {

              }
            }
          }, 300);
        });
        if (userId == user?._id.toString()) {
          openSuccessNotification(`${message}`);
        }
      }
    };

    socket.on("deleteFileResponse", handleDeleteFileResponse);

    return () => {
      socket.off("deleteFileResponse", handleDeleteFileResponse);
    };
  }, [socket]);

  useEffect(() => {
    if (selectMessages.length > 0) {
      setMessageActionFlag(true);
      setIsMessageActionOpen(true);
    }
  }, [selectMessages, isMessageActionOpen]);

  useEffect(() => {
    selectMessages.forEach((item) => {
      const elementId = "selectMessage_" + item;
      const element = document.getElementById(elementId);
      if (element) {
        element.checked = true;
      }
    });
  }, [selectMessages]);

  const {
    startRecording,
    stopRecording,
    onStartAudioPlayback,
    onPausedAudioPlayback,
    onResumedAudioPlayback,
  } = recorderControls;
  const [loadingAudio, setLoadingAudio] = useState(false);

  useEffect(() => {
    if (showAudioRecorder) {
      stopRecording();
      let closeBtn = document.querySelector(".stopAudioButton");
      if (closeBtn) {
        closeBtn.style.display = "block";
      }

      setLoadingAudio(true);
      setTimeout(() => {
        startRecording();
        setLoadingAudio(false);
      }, 500);
    }
  }, [showAudioRecorder]);

  const handleDeleteUploadedFiles = (id) => {
    setFileIds(fileIds.filter((fileId) => fileId !== id));
    setFileNames(fileNames.filter((fileId) => fileId?._id !== id));
    deleteUploadedFiles(id);
  };

  const deleteUploadedFiles = async (id) => {
    try {
      await deleteFile(id, authToken);
    } catch (error) { }
  };
  const getMediaSlidesImages = async (slide) => {
    try {
      const { data } = await getMediaSlides(slide);
      setSlider((prevState) => ({
        ...prevState,
        roomId: slide.roomId,
        fileId: slide.fileId,
        skip: 0,
        limit: 10,
        slides: makeUniqueById([...data?.media]),
        counts: data?.counts,
      }));
    } catch (error) {
      handleClose();
      openErrorNotification("Something went wrong.");
    }
  };

  const handleImageClick = (mediaItem, message) => {
    const slide = {
      roomId: message?.onetooneId ? message?.onetooneId : message?.groupId,
      fileId: mediaItem?._id,
      skip: 0,
      limit: 10,
      type: message?.onetooneId ? "onetoone" : "group",
    };
    setSlider((prevState) => ({
      ...prevState,
      type: message?.onetooneId ? "onetoone" : "group",
    }));
    getMediaSlidesImages(slide);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSlider(initialState);
  };

  const handleForwardMedia = (mediaIds) => {
    let data = {
      forAll: false,
      mediaIds: mediaIds,
      deletedBy: user?._id,
      userId: user?._id,
      roomId: chatRoomId,
    };
  };

  const [deleteFilesFlag, setDeleteFilesFlag] = useState(false);
  const [mediaIds, setMediaIds] = useState(false);
  const [forAllOption, setForAllOption] = useState(false);
  const deleteFiles = (id, forAllOptionValidate) => {
    setMediaIds(id);
    setDeleteFilesFlag(true);
    setForAllOption(forAllOptionValidate);
  };
  const handleDeleteMedia = (all, allMediaIds) => {
    if (allMediaIds && allMediaIds.length > 0) {
      let data = {
        forAll: all,
        mediaIds: allMediaIds,
        deletedBy: user?._id,
        userId: user?._id,
        organizationId: user?.organization,
        roomId: chatRoomId,
      };
      allMediaIds.map((item, index) => {
        try {
          setTimeout(() => {
            let element = document.getElementById(`media_${item}`);
            const parentLi = element.closest("li");

            const multipleFileWithMessageDivs = parentLi.querySelectorAll(
              ".multipleFileWithMessage"
            );

            let visibleFileOpenNewCount = 0;

            if (multipleFileWithMessageDivs) {
              multipleFileWithMessageDivs.forEach((div) => {
                const fileOpenNewElements =
                  div.querySelectorAll(".fileOpenNew");
                fileOpenNewElements.forEach((fileOpenNewElement) => {
                  const style = window.getComputedStyle(fileOpenNewElement);
                  if (style.display !== "none") {
                    visibleFileOpenNewCount++;
                  }
                });
              });
            }

            if (visibleFileOpenNewCount <= 0) {
              if (parentLi) {
                parentLi.style.display = "none";
              }
            }
          }, 1000);
        } catch (error) {
        }
      });
      socket.emit("deleteMediaFiles", data);
    }
    setDeleteFilesFlag(false);
  };
  const openUrlInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const replyOnSingleMedia = (data) => {
    setReplyToMessage(data.parentmessage);
    setReplyToMedia(data.fileId);
  };

  const getRef = (message, mediaItem) => {
    if (message.id !== revelingConMessage?._id) {
      if (mediaItem._id && mediaItem._id === revelingConMessage?._id) {
        return targetRef;
      }
    } else {
      if (message.id) {
        if ((message.id || mediaItem._id) === revelingConMessage?._id) {
          return targetRef;
        }
      }
    }
    return null;
  };
  const getBG = (message, mediaItem) => {
    if (message.id !== revelingConMessage?._id) {
      if (mediaItem._id && mediaItem._id === revelingConMessage?._id) {
        return "filebgn1 reveal ";
      }
    } else {
      if (message.id) {
        if ((message.id || mediaItem._id) === revelingConMessage?._id) {
          return "filebgn1 reveal ";
        }
      }
    }
    return "filebgn1";
  };

  function renderMediaItems(message, reveal = false, replied = false) {
    if (
      message?.deleteForAll === true || (message?.deleteForMe && message.deleteForMe.includes(user?._id))
    ) {
      return (
        <div className="deletedMessageStyle bgretext">
          <img
            src="https://img.icons8.com/ios-glyphs/30/000000/cancel.png"
            alt="Deleted icon"
          />
          <span>This message was deleted</span>
        </div>
      );
    }
    return message?.filesData?.map((mediaItem, index) => {
      let fileType = getFileType(mediaItem);
      switch (fileType) {
        case "video":
          return (
            <div
              key={index}
              className="fileContainer"
              id={`media_${mediaItem?._id}`}
            >
              {message.type !== "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="right">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="right">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="right">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}

              <div className="vippmsg">
                <span className="time">{message.time}</span>
                <div
                  className={getBG(message, mediaItem)}
                  ref={getRef(message, mediaItem)}
                  id={`msg-body` + revelingConMessage?._id}
                >
                  <div
                    className={`d-flex justify-content-between align-items-center playbtnaudiobg ${reveal ? "hoverable" : ""
                      }`}
                    onClick={
                      reveal === true
                        ? () => handleClickOnReply(message, reveal)
                        : undefined
                    }
                  >
                    <video
                      onClick={(e) => e.stopPropagation()}
                      controls
                      style={{ width: "100%" }}
                    >
                      <source
                        onClick={(e) => e.stopPropagation()}
                        src={mediaItem?.url}
                        controls
                        autoPlay={false}
                        type="video/mp4"
                      />
                    </video>
                    { }
                  </div>
                  <div className="filebgntext">
                    <div className="text">{mediaItem?.title}</div>
                    <div className="format d-flex justify-content-between align-items-center">
                      <div className="filectegory">
                        {mediaItem?.extension?.toUpperCase()}
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="filesize">
                          {mediaItem?.size >= 1048576
                            ? `${(mediaItem?.size / 1048576)?.toFixed(2)} MB`
                            : mediaItem?.size >= 1024
                              ? `${(mediaItem?.size / 1024)?.toFixed(2)} KB`
                              : `${mediaItem?.size?.toFixed(2)} bytes`}{" "}
                        </div>{" "}
                        <button
                          onClick={() => {
                            handleDownload(
                              mediaItem?.url,
                              mediaItem?.title,
                              mediaItem
                            );
                          }}
                        >
                          <i className="mdi mdi-arrow-bottom-left"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {message.type !== "sender" && renderChatTick(message)}
              </div>
              {message.type === "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="right">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="right">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="right">
                    <a
                      className="openew"
                      href={mediaItem?.yrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </a>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        case "document":
          return (
            <div
              key={index}
              className="fileContainer"
              id={`media_${mediaItem?._id}`}
            >
              {message.type !== "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="left">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="left">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="left">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
              <div className="vippmsg">
                <span className="time">{message.time}</span>
                <div
                  className={getBG(message, mediaItem)}
                  id={`msg-body` + revelingConMessage?._id}
                  ref={getRef(message, mediaItem)}
                >
                  <div
                    className={`d-flex justify-content-between align-items-center playbtnaudiobg ${reveal ? "hoverable" : ""
                      }`}
                    onClick={
                      reveal === true
                        ? () => handleClickOnReply(message, reveal)
                        : undefined
                    }
                  >
                    <img
                      onClick={(e) => e.stopPropagation()}
                      src="assets/images/videoimg/zip-file.svg"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="filebgntext">
                    <div className="text">{mediaItem?.title}</div>
                    <div className="format d-flex justify-content-between align-items-center">
                      <div className="filectegory">
                        {mediaItem?.extension?.toUpperCase()}
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="filesize">
                          {mediaItem?.size >= 1048576
                            ? `${(mediaItem?.size / 1048576)?.toFixed(2)} MB`
                            : mediaItem?.size >= 1024
                              ? `${(mediaItem?.size / 1024)?.toFixed(2)} KB`
                              : `${mediaItem?.size?.toFixed(2)} bytes`}
                        </div>
                        <button
                          onClick={() => {
                            handleDownload(
                              mediaItem?.url,
                              mediaItem?.title,
                              mediaItem
                            );
                          }}
                        >
                          <i className="mdi mdi-arrow-bottom-left"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {message.type !== "sender" && renderChatTick(message)}
              </div>

              {message.type === "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="right">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="right">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="right">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        case "image":
          return (
            <div
              key={index}
              className="fileOpenNew"
              id={`media_${mediaItem?._id}`}
            >
              {message.type !== "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="left">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="left">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="left">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
              <div className="vippmsg">
                <span className="time">{message.time}</span>
                <div
                  className={getBG(message, mediaItem)}
                  id={`msg-body` + revelingConMessage?._id}
                  ref={getRef(message, mediaItem)}
                >
                  <div
                    className={`img mw330 ${reveal ? "hoverable" : ""}`}
                    onClick={
                      reveal === true
                        ? () => handleClickOnReply(message, reveal)
                        : undefined
                    }
                  >
                    {!replied ? (
                      <>
                        <a
                          href={mediaItem?.url}
                          data-fancybox="gallery"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img
                            src={mediaItem?.url}
                            width="200"
                            height="150"
                            alt=""
                            loading="lazy"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </a>

                        <div className="filebgntext">
                          <div className="text">{mediaItem?.title}</div>
                          <div className="format d-flex justify-content-between align-items-center">
                            <div className="filectegory"></div>
                            <div className="d-flex align-items-center">
                              <button
                                onClick={() => {
                                  handleDownload(
                                    mediaItem?.url,
                                    mediaItem?.title,
                                    mediaItem
                                  );
                                }}
                              >
                                <i className="mdi mdi-arrow-bottom-left"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={mediaItem?.url}
                          width="200"
                          height="150"
                          alt=""
                          loading="lazy"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <div className="filebgntext">
                          <div className="text">{mediaItem?.title}</div>
                          <div className="format d-flex justify-content-between align-items-center">
                            <div className="filectegory"></div>
                            <div className="d-flex align-items-center">
                              <button
                                onClick={() => {
                                  handleDownload(
                                    mediaItem?.url,
                                    mediaItem?.title,
                                    mediaItem
                                  );
                                }}
                              >
                                <i className="mdi mdi-arrow-bottom-left"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <>
                  {message.type !== "sender" && renderChatTick(message)}
                </>
              </div>
              {message.type === "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="right">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="right">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="right">
                    <a
                      className="openew"
                      href={message?.file}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </a>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        case "audio":
          return (
            <div
              key={index}
              className="fileContainer"
              id={`media_${mediaItem?._id}`}
            >
              {message.type !== "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="left">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="left">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="left">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
              <div className="vippmsg">
                <span className="time">{message.time}</span>

                <div
                  id={`msg-body` + revelingConMessage?._id}
                  className={getBG(message, mediaItem)}
                  ref={getRef(message, mediaItem)}
                >
                  {mediaItem?.url && (
                    <div
                      className={`${reveal ? "hoverable" : ""}`}
                      onClick={
                        reveal === true
                          ? () => handleClickOnReply(message, reveal)
                          : undefined
                      }
                    >
                      <a
                        onClick={(e) => e.stopPropagation()}
                        href={mediaItem.url}
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        <audio
                          preload="none"
                          controls
                          controlsList="nodownload noplaybackrate"
                          id="audioPlayer"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(mediaItem?.url, "_blank");
                          }}
                          className={
                            mediaItem?._id === revelingConMessage?._id
                              ? "reveal"
                              : ""
                          }
                        >
                          <source src={mediaItem.url} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                        <span className="timeduration"> </span>
                      </a>
                    </div>
                  )}

                  <div className="filebgntext">
                    <div className="text">{mediaItem?.title}</div>
                    <div className="format d-flex justify-content-between align-items-center">
                      <div className="filectegory">{mediaItem?.extension}</div>
                      <div className="d-flex align-items-center">
                        <div className="filesize">
                          {mediaItem?.size >= 1048576
                            ? `${(mediaItem?.size / 1048576)?.toFixed(2)} MB`
                            : mediaItem?.size >= 1024
                              ? `${(mediaItem?.size / 1024)?.toFixed(2)} KB`
                              : `${mediaItem?.size?.toFixed(2)} bytes`}{" "}
                        </div>{" "}
                        <button
                          onClick={() => {
                            handleDownload(
                              mediaItem?.url,
                              mediaItem?.title,
                              mediaItem
                            );
                          }}
                        >
                          <i className="mdi mdi-arrow-bottom-left"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {message.type !== "sender" && renderChatTick(message)}
                </>
              </div>
              {message.type === "sender" && (
                <div className="btnsforwopennew">
                  <a
                    className="openew"
                    href={message?.file}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <i className="mdi mdi-open-in-new"></i>
                  </a>
                  <button
                    className="share"
                    onClick={() => {
                      setSelectMessages([message]);
                      setMediaToForward(mediaItem?._id);
                    }}
                  >
                    <i className="mdi mdi-share"></i>
                  </button>
                </div>
              )}
            </div>
          );
        case "link":
          return <></>;
        default:
          return (
            <div
              key={index}
              className="fileContainer"
              id={`media_${mediaItem?._id}`}
            >
              {message.type !== "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="left">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="left">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="left">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
              <div className="vippmsg">
                <span className="time">{message.time}</span>
                <div
                  className={
                    mediaItem?._id === revelingConMessage?._id
                      ? "filebgn1 reveal "
                      : "filebgn1"
                  }
                  id={`msg-body` + revelingConMessage?._id}
                  ref={
                    mediaItem._id === revelingConMessage?._id ? targetRef : null
                  }
                >
                  <div className="d-flex justify-content-between align-items-center playbtnaudiobg">
                    <img
                      src="assets/images/videoimg/zip-file.svg"
                      className="img-fluid"
                      alt=""
                      loading="lazy"
                    />
                  </div>

                  <div className="filebgntext">
                    <div className="text">{mediaItem.title}</div>
                    <div className="format d-flex justify-content-between align-items-center">
                      <div className="filectegory">
                        {mediaItem?.extension?.toUpperCase()}
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="filesize">
                          {mediaItem?.size >= 1048576
                            ? `${(mediaItem?.size / 1048576)?.toFixed(2)} MB`
                            : mediaItem?.size >= 1024
                              ? `${(mediaItem?.size / 1024)?.toFixed(2)} KB`
                              : `${mediaItem?.size?.toFixed(2)} bytes`}{" "}
                        </div>{" "}
                        <button
                          onClick={() => {
                            handleDownload(mediaItem?.url, mediaItem?.title, mediaItem);
                          }}
                        >
                          <i className="mdi mdi-arrow-bottom-left"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {message.type !== "sender" && renderChatTick(message)}
                </>
              </div>

              {message.type === "sender" && (
                <div className="btnsforwopennew">
                  <Tooltip title="Forward media/file" placement="top">
                    <button
                      className="share"
                      onClick={() => {
                        setSelectMessages([message]);
                        setMediaToForward(mediaItem?._id);
                      }}
                    >
                      <i className="mdi mdi-share"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Reply media/file" placement="right">
                    <button
                      className="share"
                      onClick={() => {
                        replyOnSingleMedia({
                          fileId: mediaItem?._id,
                          parentmessage: message,
                        });
                        editor?.chain().focus().run();
                      }}
                    >
                      <i className="mdi mdi-reply"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="right">
                    <button
                      className=""
                      type="button"
                      onClick={() => {
                        deleteMessage(message);
                        // deleteFiles(
                        //   mediaItem?._id,
                        //   mediaItem?.userId == user?._id ? true : false
                        // );
                      }}
                    >
                      <i className="mdi mdi-delete-empty"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Open in new tab" placement="right">
                    <button
                      style={{ textDecoration: "none" }}
                      className="openew"
                      onClick={() => openUrlInNewTab(mediaItem?.url)}
                    >
                      <i className="mdi mdi-open-in-new"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>
          );
      }
    });
  }

  const messageDivRef = useRef(null);
  const [messageExists, setMessageExists] = useState(false);

  useEffect(() => {
    setMessageExists(!!messageDivRef.current);
  }, [message]);

  const renderChatTick = (msg) => {
    // console.log("msg", msg)
    return <div className="readmsg">
      {msg?.groupId ?
        msg?.readBy?.length >= selectedUserOrGroup?.participants?.length ?
          (<i className="mdi mdi-check-all active"></i>)
          : <i className="mdi mdi-check-all"></i>

        : msg?.readBy && msg?.readBy?.length == 2 ? (
          <i className="mdi mdi-check-all active"></i>
        ) : msg?.deliveredTo && msg?.deliveredTo?.length >= 2 && msg?.readBy?.length <= 1 ? (
          <i className="mdi mdi-check-all"></i>
        ) : msg?.deliveredTo && msg?.deliveredTo?.length < 2 ? (
          <i className="mdi mdi-check"></i>
        ) : null}
    </div>
  }

  const messageTemplate = (message, index, previousMessage) => {
    const { content, timestamp } = message;
    const currentDate = new Date(timestamp);
    const previousDate = previousMessage
      ? new Date(previousMessage.timestamp)
      : null;
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    let dateString = "";
    if (currentDate.toDateString() === today.toDateString()) {
      dateString = "Today";
    } else if (currentDate.toDateString() === yesterday.toDateString()) {
      dateString = "Yesterday";
    } else {
      dateString = currentDate.toLocaleDateString();
    }

    let dateDivider = null;
    if (
      !previousMessage ||
      currentDate.getDate() !== previousDate.getDate() ||
      currentDate.getMonth() !== previousDate.getMonth() ||
      currentDate.getFullYear() !== previousDate.getFullYear()
    ) {
      dateDivider = (
        <li key={`divider_${timestamp}`} className="divider">
          <h6>{dateString}</h6>
        </li>
      );
    }

    const Reaction = (message) => (
      <EmojiReactions
        socket={socket}
        messageId={message?.id}
        chatRoomId={
          message?.onetooneId?.length > 0
            ? message?.onetooneId
            : message?.groupId
        }
        userId={user?._id}
        organizationId={message?.organizationId}
        setClickedMessageId={setClickedMessageId}
        setIsReacted={setIsReacted}
      />
    );

    const ShowNotification = ({ message }) => {
      if (!message?.isNotification) return null;
      return (
        <li key={message.id} className="dividerNotification">
          {parse(message.messageHtml)}
        </li>
      );
    };

    // console.log("message",message)





    return (
      <>
        {dateDivider}
        { }
        {message?.isNotification && !messageExists ? (
          <ShowNotification key={message.id} message={message} />
        ) : (
          <li
            id={`messageDiv_${message?.id}`}
            key={message.id}
            ref={messageDivRef}
            className={message.type === "sender" ? "sender" : "repaly"}
          >
            {messageActionFlag && mediaToForward == null && (
              <CustomCheckbox
                user={user}
                setEveryone={setEveryone}
                message={message}
                setSelectMessages={setSelectMessages}
                selectMessages={selectMessages}
                isMessageActionOpen={isMessageActionOpen}
                setIsMessageActionOpen={setIsMessageActionOpen}
              />
            )}

            <div
              className={
                message.type === "sender"
                  ? "d-flex align-items-end usergbg"
                  : "usergbg"
              }
            >
              { }
              {selectedUserOrGroup?.remark === "Group" &&
                message.type === "sender" &&
                !message?.isNotification && (
                  <div className="imguser sticky-bottom">
                    {message?.senderDetails?.otherInformation
                      ?.profilePicture ? (
                      <span
                        className="imggrp"
                        style={{
                          backgroundImage: `url(${message.senderDetails.otherInformation.profilePicture})`,
                        }}
                      ></span>
                    ) : (
                      <span>
                        <div
                          className="imggrp"
                          style={{
                            backgroundColor: "#ccc", // Optional: Add a background color for initials
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "16px",
                            backgroundImage: "none",
                            background: "#9E9E9E",
                            fontWeight: "bold",
                            border: "2px solid rgb(0, 168, 255)",
                            color: "rgb(85, 85, 85)",
                          }}
                        >
                          {`${message?.senderDetails?.firstName
                            ?.charAt(0)
                            ?.toUpperCase() || ""
                            }${message?.senderDetails?.lastName
                              ?.charAt(0)
                              ?.toUpperCase() || ""
                            }`}
                        </div>
                      </span>
                    )}
                  </div>
                )}
              <div className="usertypetext">
                {message.type === "sender" &&
                  selectedUserOrGroup?.remark === "Group" &&
                  !message?.isNotification && (
                    <div className="name font-14 fw-bold color1a">
                      {message?.senderDetails?.firstName}{" "}
                      {message?.senderDetails?.lastName}
                    </div>
                  )}
                <div
                  className={
                    "d-flex align-items-start onhvr justify-content-" +
                    (message.type === "sender" ? "start" : "end")
                  }
                >
                  {message.type === "sender" && !message?.isNotification &&
                    !((message?.deleteForMe && message.deleteForMe.includes(user?._id)) || message?.deleteForAll) &&
                    (
                      <MsgDropdown
                        setMediaToForward={setMediaToForward}
                        mediaToForward={mediaToForward}
                        key={index}
                        message={message}
                        setMessageInfo={setMessageInfo}
                        deleteMessage={deleteMessage}
                        messageinfo_open={messageinfo_open}
                        pinMessage={pinMessage}
                        unpinMessage={unpinMessage}
                        unreadMessage={unreadMessage}
                        setMessageActionFlag={setMessageActionFlag}
                        setMessageFilterFlag={setMessageFilterFlag}
                        setSelectMessages={setSelectMessages}
                        selectMessages={selectMessages}
                        isMessageActionOpen={isMessageActionOpen}
                        setIsMessageActionOpen={setIsMessageActionOpen}
                        replyToMessage={replyToMessage}
                        setReplyToMessage={setReplyToMessage}
                        editor={editor}
                      />
                    )}

                  {message?.messageType == "media" && (
                    <div
                      className="multipleFileWithMessage suman"
                      style={{
                        background:
                          message?.id === revelingConMessage?.chatID
                            ? "#B6E0F9"
                            : "#effcff",
                        padding: "45px",
                        marginLeft: "5px",
                      }}
                    >
                      <div
                        onMouseOver={() => {
                          toggleReaction(message.id);
                        }}
                      >
                        {message?.isContact &&
                          message?.contactDetails &&
                          message?.contactDetails.map((item, ind) => {
                            return (
                              <div className="textmsg">
                                <span className="time">{message.time}</span>
                                <div
                                  className={
                                    message.id === revelingConMessage?._id ||
                                      message.id ===
                                      revelingConMessage?.parentConversation
                                        ?._id
                                      ? "bgretext reveal "
                                      : "bgretext"
                                  }
                                  id={
                                    `msg-body` + revelingConMessage?._id ||
                                    revelingConMessage?.parentConversation?._id
                                  }
                                  ref={
                                    message.id === revelingConMessage?._id ||
                                      message.id ===
                                      revelingConMessage?.parentConversation
                                        ?._id
                                      ? targetRef
                                      : null
                                  }
                                >
                                  <div
                                    key={ind}
                                    className="miscall font-14 fw-bold d-flex align-items-center text-green bg-greenl"
                                  >
                                    <i className="mdi mdi-phone font-20 pe-1"></i>
                                    {item?.name}
                                    <br />
                                    {item?.contact}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {message?.parentConversation &&
                          message?.parentConversation?.messageType == "media" ? (
                          <>
                            <div className="vippmsg">
                              <span className="time">{message.time}</span>
                              <div className="bgreplds hoverable">
                                <div className="name font-14 fw-bold color1a">
                                  {
                                    message?.parentConversation?.senderDetails
                                      ?.firstName
                                  }{" "}
                                  {
                                    message?.parentConversation?.senderDetails
                                      ?.lastName
                                  }
                                </div>
                                {renderMediaItems(
                                  message?.parentConversation,
                                  true,
                                  true
                                )}
                                <div className="bgretext">
                                  {message?.isContact &&
                                    message?.contactDetails &&
                                    message?.contactDetails.map((item, ind) => {
                                      return (
                                        <div className="textmsg">
                                          <span className="time">
                                            {message.time}
                                          </span>
                                          <div
                                            className={
                                              message.id ===
                                                revelingConMessage?._id ||
                                                message.id ===
                                                revelingConMessage
                                                  ?.parentConversation?._id
                                                ? "bgretext reveal "
                                                : "bgretext"
                                            }
                                            id={
                                              `msg-body` +
                                              revelingConMessage?._id ||
                                              revelingConMessage
                                                ?.parentConversation?._id
                                            }
                                            ref={
                                              message.id ===
                                                revelingConMessage?._id ||
                                                message.id ===
                                                revelingConMessage
                                                  ?.parentConversation?._id
                                                ? targetRef
                                                : null
                                            }
                                          >
                                            <div
                                              key={ind}
                                              className="miscall font-14 fw-bold d-flex align-items-center text-green bg-greenl"
                                            >
                                              <i className="mdi mdi-phone font-20 pe-1"></i>
                                              {item?.name}
                                              <br />
                                              {item?.contact}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {renderMediaItems(message)}
                                  {renderMessageContent(message)}
                                </div>
                              </div>

                              {message.type !== "sender" && (
                                <>
                                  {renderChatTick(message)}
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {renderMediaItems(message)}
                            {message?.content?.trim() !== "" &&
                              !message?.isNotification && (
                                <>
                                  {renderMessageContent(message)}
                                  {message.type !== "sender" && renderChatTick(message)}
                                </>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {message.messageType == "text" &&
                    message.content?.trim() != "" && (
                      <>
                        {message?.parentConversation &&
                          !message?.isNotification ? (
                          <>
                            <div className="vippmsg">
                              <span className="time">{message.time}</span>
                              <div
                                className={
                                  message.parentConversation &&
                                    message?.parentConversation?._id
                                    ? "bgreplds"
                                    : ""
                                }
                              >
                                <div className="name font-14 fw-bold color1a text-start mb-1">
                                  {
                                    message?.parentConversation?.senderDetails
                                      ?.firstName
                                  }{" "}
                                  {
                                    message?.parentConversation?.senderDetails
                                      ?.lastName
                                  }
                                </div>
                                {message?.parentConversation?.message == "" &&
                                  message?.parentConversation?.messageHtml !=
                                  "" && (
                                    <>
                                      {renderMessageContent(
                                        message?.parentConversation
                                      )}
                                    </>
                                  )}
                                {message?.parentConversation.messageType ==
                                  "link" ||
                                  message?.parentConversation.messageType ==
                                  "text" ? (
                                  <p
                                    className="hoverable"
                                    onClick={() => handleClickOnReply(message)}
                                  >
                                    {message?.parentConversation?.message}
                                  </p>
                                ) : (
                                  renderMediaItems(
                                    message?.parentConversation,
                                    true,
                                    true
                                  )
                                )}
                                {message.content !== null &&
                                  !message.isLocation && (
                                    <div
                                      className={
                                        message.id ===
                                          revelingConMessage?._id ||
                                          message.id ===
                                          revelingConMessage?.parentConversation
                                            ?._id
                                          ? "bgretext reveal "
                                          : "bgretext"
                                      }
                                      id={
                                        `msg-body` + revelingConMessage?._id ||
                                        revelingConMessage?.parentConversation
                                          ?._id
                                      }
                                      ref={
                                        message.id ===
                                          revelingConMessage?._id ||
                                          message.id ===
                                          revelingConMessage?.parentConversation
                                            ?._id
                                          ? targetRef
                                          : null
                                      }
                                    >
                                      {renderMessageContent(message)}
                                    </div>
                                  )}
                                {message.content !== null &&
                                  message?.isLocation && (
                                    <>
                                      <div className="ifrmLocation"></div>
                                      <GooMap
                                        lat={parseFloat(
                                          message.content?.split(",")[0]
                                        )}
                                        lng={parseFloat(
                                          message.content?.split(",")[1]
                                        )}
                                        texts={"SyncupTeams"}
                                      />
                                    </>
                                  )}
                              </div>

                              {/* {console.log("***************************************************************")}
                              {console.log("message.deliveredTo", message.deliveredTo)}
                              {console.log("message.readBy", message.readBy)}
                              {console.log("message.readBy", message.content)} */}

                              {message.type !== "sender" && (
                                <>{renderChatTick(message)}</>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {!message?.isNotification && (
                              <div className="textmsg">
                                <span className="time">{message.time}</span>
                                {message.content !== null &&
                                  !message.isLocation && (
                                    <div className="bgretext">
                                      {renderMessageContent(message)}
                                    </div>
                                  )}

                                {message.content !== null &&
                                  message?.isLocation && (
                                    <>
                                      <div className="ifrmLocation"></div>
                                      <GooMap
                                        lat={parseFloat(
                                          message.content?.split(",")[0]
                                        )}
                                        lng={parseFloat(
                                          message.content?.split(",")[1]
                                        )}
                                        texts={"SyncupTeams"}
                                      />
                                    </>
                                  )}
                                {message.type !== "sender" && (
                                  <>{renderChatTick(message)}</>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                  {message.content == "" &&
                    message?.messageHtml != "" &&
                    message?.messageType != "media" && (
                      <>{renderMessageContent(message)}</>
                    )}

                  {message.messageType == "link" &&
                    message.content?.trim() != "" && (
                      <>
                        {message?.parentConversation ? (
                          <>
                            <div className="vippmsg">
                              <span className="time">{message.time}</span>
                              <div className="">
                                <div className="name font-14 fw-bold color1a text-start mb-1">
                                  {
                                    message?.parentConversation?.senderDetails
                                      ?.firstName
                                  }{" "}
                                  {
                                    message?.parentConversation?.senderDetails
                                      ?.lastName
                                  }
                                </div>
                                {message?.parentConversation.messageType ==
                                  "link" ||
                                  message?.parentConversation.messageType ==
                                  "text" ? (
                                  <p>{message?.parentConversation?.message}</p>
                                ) : (
                                  <>
                                    {message?.replyMediaDetails &&
                                      renderMediaItems({
                                        filesData: [message?.replyMediaDetails],
                                      })}
                                    {!message?.replyMediaDetails &&
                                      renderMediaItems(
                                        message?.parentConversation
                                      )}
                                  </>
                                )}
                                {message.content !== null &&
                                  !message.isLocation && (
                                    <div
                                      className={
                                        message.id ===
                                          revelingConMessage?._id ||
                                          message.id ===
                                          revelingConMessage?.parentConversation
                                            ?._id
                                          ? "bgretext reveal "
                                          : "bgretext"
                                      }
                                      id={
                                        `msg-body` + revelingConMessage?._id ||
                                        revelingConMessage?.parentConversation
                                          ?._id
                                      }
                                      ref={
                                        message.id ===
                                          revelingConMessage?._id ||
                                          message.id ===
                                          revelingConMessage?.parentConversation
                                            ?._id
                                          ? targetRef
                                          : null
                                      }
                                    >
                                      {renderMessageContent(message)}
                                    </div>
                                  )}

                                {message.content !== null &&
                                  message?.isLocation && (
                                    <>
                                      <div className="ifrmLocation"></div>
                                      <GooMap
                                        lat={parseFloat(
                                          message.content?.split(",")[0]
                                        )}
                                        lng={parseFloat(
                                          message.content?.split(",")[1]
                                        )}
                                        texts={"SyncupTeams"}
                                      />
                                    </>
                                  )}
                              </div>

                              {message.type !== "sender" && (
                                <>{renderChatTick(message)}</>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="textmsg">
                              <span className="time">{message.time}</span>
                              {message.content !== null &&
                                !message.isLocation && (
                                  <div className="bgretext reveal ">
                                    {renderMessageContent(message)}
                                  </div>
                                )}
                              {message.content !== null &&
                                message?.isLocation && (
                                  <>
                                    <div className="ifrmLocation"></div>
                                    <GooMap
                                      lat={parseFloat(
                                        message.content?.split(",")[0]
                                      )}
                                      lng={parseFloat(
                                        message.content?.split(",")[1]
                                      )}
                                      texts={"SyncupTeams"}
                                    />
                                  </>
                                )}

                              {message.type !== "sender" && (
                                <>{renderChatTick(message)}</>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  {message.type !== "sender" && !message?.isNotification &&
                    !((message?.deleteForMe && message.deleteForMe.includes(user?._id)) || message?.deleteForAll) &&
                    (
                      <MsgDropdown
                        setMediaToForward={setMediaToForward}
                        mediaToForward={mediaToForward}
                        key={index}
                        message={message}
                        setMessageInfo={setMessageInfo}
                        deleteMessage={deleteMessage}
                        messageinfo_open={messageinfo_open}
                        pinMessage={pinMessage}
                        unpinMessage={unpinMessage}
                        unreadMessage={unreadMessage}
                        setMessageActionFlag={setMessageActionFlag}
                        setMessageFilterFlag={setMessageFilterFlag}
                        setSelectMessages={setSelectMessages}
                        selectMessages={selectMessages}
                        isMessageActionOpen={isMessageActionOpen}
                        setIsMessageActionOpen={setIsMessageActionOpen}
                        replyToMessage={replyToMessage}
                        setReplyToMessage={setReplyToMessage}
                        editor={editor}
                      />
                    )}
                </div>
                {message?.reactions && (
                  <div className="emoji-reaction" style={{ display: "flex" }}>
                    {message?.reactions.map((item, indx) => {
                      return (
                        <div
                          key={indx}
                          title={
                            item?.usersInfo &&
                            item.usersInfo
                              .map((usr, ind) => {
                                return `${usr?.firstName} ${usr?.lastName}\n`;
                              })
                              .join("")
                          }
                        >
                          <img
                            src={item?._id}
                            alt="Syncup"
                            style={{ width: "20px" }}
                            loading="lazy"
                          />
                          <span>{item?.count}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </li>
        )}
      </>
    );
  };


  const loadMoreData = (status, timestamp, limit) => {
    setTimeout(() => {
      let searchMessageString = localStorage.getItem("searchMessageString");
      // console.log("searchMessageString",searchMessageString)
      const loadMoreFunction = searchMessageString ? loadMoreSearchMessages : loadMoreMessages;

      loadMoreFunction(status, user?._id, chatRoomId, timestamp, limit);
    }, 500);
  };

  const loadMoreMessages = (status, userId, roomId, timestamp, limit = 20) => {
    socket.emit("loadMoreMessages", {
      status,
      userId,
      roomId,
      timestamp,
      limit,
    });
    socket.once("loadMoreMessages", handleLoadMoreData);
  };

  const loadMoreSearchMessages = (
    status,
    userId,
    roomId,
    timestamp,
    limit = 20
  ) => {
    let searchMessageString = localStorage.getItem("searchMessageString");
    if (socket && searchMessageString && roomId) {
      const data = {
        messageString: searchMessageString,
        messageType: searchMessageType,
        status: status,
        roomId: roomId,
        timestamp: timestamp,
        limit: limit,
        userId: userId,
      };

      socket.emit("loadMoreSearchMessages", data);
      socket.once("loadMoreSearchMessages", handleLoadMoreData);
    }
  };

  // useEffect(() => {
  //   if (searchMessageString && searchMessageString != "") {
  //     setLoading(true);
  //     const timestamp = userMessages[userMessages?.length - 1]?.timestamp || null;
  //     loadMoreData("up", timestamp, 20);

  //     const loadingTimeout = setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);

  //     return () => {
  //       clearTimeout(loadingTimeout);
  //     };
  //   }
  // }, [searchMessageString, searchMessageType]);


  const [moreBottomMessages, setMoreBottomMessages] = useState(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [messageCounter, setMessageCounter] = useState(0);


  const handleLoadMoreData = ({ loadMoreMessages }) => {
    // console.log("handleLoadMoreData loadMoreMessages", loadMoreMessages);
    if (loadMoreMessages && loadMoreMessages?.length > 0) {
      dispatch(loadMoreChat(loadMoreMessages.map(msg => formatMessage(msg))));
    }
    else {
      setMoreTopMessages(false);
    }
    setLoading(false);
  };

  const isElementScrolledToBottom = (element) => {
    if (!element) return false;
    return element.scrollTop + element.clientHeight >= element.scrollHeight;
};
const unreadUser = allUser?.find(item => item.roomId === localStorage.getItem("chatRoomId") && item.unRead > 0);

useEffect(() => {
    const listDiv = listRef.current;
    function checkUnreadForRoom() {
      const currentRoomId = localStorage.getItem("chatRoomId");
      const unreadUser = allUser?.find(item => item.roomId === currentRoomId && item?.unRead > 0);
      return Boolean(unreadUser);
  }
  
    const checkScrollPosition = () => {
        const atBottom = isElementScrolledToBottom(listDiv);
        setIsScrolledToBottom(atBottom);
    };
    
    let userAgent = JSON.parse(localStorage.getItem("ipData"));
    const handleScroll = () => {
        const atBottom = isElementScrolledToBottom(listDiv);
        setIsScrolledToBottom(atBottom);
        const result = checkUnreadForRoom();
        if (atBottom && result ) {
          socket.emit("readAllMessage", {
            userId: user?._id,
            roomId: localStorage.getItem("chatRoomId"),
            organizationId: user?.organization,
            userAgent: userAgent,
            isGroup: localStorage.getItem("selectedGroup") ? true : false
          });

          socket.emit("getChatList", {
            userId: user?._id,
            organizationId: user?.organization,
          });
          socket.on("getChatListSpecific", handleSocketUserListResponse);
         
         
        }
    };

    const handleVisibilityChange = () => {
        const isVisible = document.visibilityState === 'visible';
        setIsTabVisible(isVisible);
        if (isVisible) {
            console.log('The tab is active');
            checkScrollPosition(); 

        } else {
            console.log('The tab is not active');
        }
    };
    checkScrollPosition();
    handleVisibilityChange();
    listDiv.addEventListener('scroll', handleScroll);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
        listDiv.removeEventListener('scroll', handleScroll);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, [allUser]);



  useEffect(() => {
    try {
      if (listRef.current && userMessages.length > 0) {

        const handleScroll = () => {
          if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;
            const scrollBottom = Math.round(scrollHeight - scrollTop - clientHeight);

            if (scrollBottom < 10) {
              setNewMessage(null);
              setMessageCounter(0);
            }
            // console.log("handleScroll scrollBottom========>", scrollBottom);
            setDisplayChatCounter(scrollBottom);

            if (scrollTop == 0 && !loading && userMessages.length > 0) {
              // console.log("scrollTop>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", scrollTop)
              setScrollPosition(scrollHeight - scrollTop);
              setLoading(true);
              setIsScrollUp(true);
              setMoreTopMessages(true);
              setLastElementId(null);
              const timestamp = userMessages[0]?.timestamp;
              loadMoreData("up", timestamp, 20);
            }
            else if (scrollBottom == 0 && !loading && userMessages.length > 0) {
              // console.log("***************scrollBottom run*****************")
              setScrollPosition(null);
              setLoading(false);
              setIsScrollUp(false);
              setMessageCounter(0);
              setLastReadMessageIdState(null);
              setNewMessage(null);
              setLastElementId(userMessages[userMessages.length - 1]?.id);

              // const timestamp = userMessages[userMessages.length - 1]?.timestamp;
              // loadMoreData("down", timestamp, 20);
            }

          }
        };

        const listDiv = listRef.current;
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        const scrollBottom = Math.round(scrollHeight - scrollTop - clientHeight);
        if (scrollTop == 0 && revelingConMessage != null && revelingConMessage != "") {
          setRevelingConMessage(null);
        }
        if (revelingConMessage != null && revelingConMessage != "") {
          return;
        }
        if (listDiv && userMessages.length > 0) {
          listDiv.addEventListener('scroll', handleScroll);
          return () => listDiv.removeEventListener('scroll', handleScroll);
        }
      }
    } catch (error) {
      console.log("handleScroll error", error);
    }

  }, [loadMoreMessages]);


  useEffect(() => {

    try {

      if (revelingConMessage && listRef.current) {
        // setRevelingConMessage(null);
      }
      else if (listRef.current && !revelingConMessage) {

        const { scrollTop, scrollHeight, clientHeight } = listRef.current;

        let messageCounterTemp = 0;
        const unreadIndex = userMessages.findIndex(item => item.id === lastReadMessageIdState);
        if (unreadIndex > 0 && !newMessage) {
          messageCounterTemp = (userMessages.length - 1) - unreadIndex;
        }

        if (lastElementId && !newMessage) {
          // console.log("lastElementId if", lastElementId)
          const lastElement = document.getElementById(`messageDiv_${lastElementId}`);
          if (lastElement && listRef.current) {
            listRef.current.scrollTop = lastElement.offsetTop - listRef.current.offsetTop - listRef.current.clientHeight + lastElement.clientHeight + 100;
          }
        }
        else if (messageCounterTemp > 0) {
          // console.log("messageCounterTemp if", messageCounterTemp)
          setMessageCounter(messageCounterTemp);
          const messageElement = document.getElementById(`messageDiv_${lastReadMessageIdState}`);
          if (messageElement && listRef.current) {
            listRef.current.scrollTop = messageElement.offsetTop - listRef.current.offsetTop - listRef.current.clientHeight + messageElement.clientHeight + 180;
          }
        }
        else if (scrollPosition) {
          // console.log("scrollPosition else if", scrollPosition)
          listRef.current.scrollTop = scrollHeight - scrollPosition;
        }
        else if (newMessage && displayChatCounter > 0) {
          setMessageCounter((prevMessageCounter) => prevMessageCounter + 1);
        }
        else if (scrollPosition === null) {
          // console.log("messageCounter else if", messageCounterTemp)
          setMessageCounter(messageCounterTemp);
          listRef.current.scrollTop = listRef.current.scrollHeight;
        }
      }

    } catch (error) {
      console.log("handleScroll condition error", error);
    }
  }, [userMessages, scrollPosition, lastReadMessageIdState]);

  const scrollButton = () => {
    try {
      // if (messageCounter > 0) {
      //   const messageElement = document.getElementById(`messageDiv_${userMessages[(userMessages.length - 1) - messageCounter]?.id}`);
      //   if (messageElement && listRef.current) {
      //     listRef.current.scrollTop = messageElement.offsetTop - listRef.current.offsetTop - listRef.current.clientHeight + messageElement.clientHeight + 180;
      //   }
      // }
      // else {
      //   const messageElement = document.getElementById(`messageDiv_${userMessages[userMessages.length - 1]?.id}`)
      //   if (messageElement && listRef.current) {
      //     setTimeout(() => {
      //       listRef.current.scrollTop = listRef.current.scrollHeight;
      //     }, 0);
      //   }
      // }

      const listDiv = listRef.current;
        if (listDiv) {
          listDiv.scrollTop = listDiv.scrollHeight - listDiv.clientHeight;
          setIsScrolledToBottom(true);
        }
    } catch (error) {
      console.log("scrollButton error", error)
    }

  };



  const formatMessage = (message) => ({
    type:
      user?._id !== "" && user?._id === message?.senderId ? "reply" : "sender",
    time: getCurrentTime(message?.timestamp),
    content: message?.message,
    messageHtml: message?.messageHtml,
    reactions: message?.reactions,
    id: message?._id,
    pinned: message?.isPinned,
    read: message?.read,
    seen: message?.seen,
    delivered: message?.delivered,
    timestamp: message?.timestamp,
    userAgentSent: message?.userAgentSent,
    userAgentSeen: message?.userAgentSeen,
    parentConversation: message?.parentConversation,
    replyMediaDetails: message?.replyMediaDetails,
    replyToMedia: message?.replyToMedia,
    messageType: message?.messageType,
    filesData: message?.filesData,
    isText: message?.isText,
    isImage: message?.isImage,
    isAudio: message?.isAudio,
    isVideo: message?.isVideo,
    isDocument: message?.isDocument,
    isLink: message?.isLink,
    isCode: message?.isCode,
    isContact: message?.isContact,
    isLocation: message?.isLocation,
    senderDetails: message?.senderDetails,
    organizationId: message?.organizationId,
    groupId: message?.groupId ?? "",
    onetooneId: message?.onetooneId ?? "",
    contactDetails: message?.contactDetails,
    isNotification: message?.isNotification,
    repliedToConversation: message?.repliedToConversation,
    deliveredTo: message?.deliveredTo,
    readBy: message?.readBy,
    deleteForMe: message?.deleteForMe,
    deleteForAll: message?.deleteForAll,
  });

  const file_open_media = () => {
    const handleDisplay = () => {
      const mainElement = document.querySelector("#main");
      if (mainElement) {
        mainElement.style.marginRight = "265px";
      }

      const myProfileElement = document.querySelector("#userFileMedia");
      if (myProfileElement) {
        myProfileElement.style.width = "265px";
        myProfileElement.style.display = "block";
      }
    };

    if (openedRightBarDrawer === "media") {
      handleDisplay();
    } else {
      setTimeout(() => {
        handleDisplay();
      }, 100);
    }
    toggleRightBarDrawer("media");
  };


  const handleSearch = (e) => {
    const value = e.target.value;
    
    if (!value) {
      setSearchText("");
    } else {
      setSearchText(value);
    }
  };
  const handleMapcancel = () => {
    setLocation({});
  };

  useEffect(() => {
    handleTypingStatus();
    const typingTimeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [isTyping]);

  const handleTypingStatus = () => {
    const { firstName } = user || {};
    const { _id: senderId } = user || {};
    const { _id: receiverId } = selectedUserOrGroup || {};
    const groupId =
      selectedUserOrGroup?.remark === "Group" ? selectedUserOrGroup?._id : null;
    const organizationId = user?.organization;

    const eventType = isTyping ? "typing" : "stop typing";
    const eventPayload = groupId
      ? { senderId, groupId, organizationId, username: firstName }
      : { senderId, receiverId, organizationId, username: firstName };

    socket.emit(groupId ? `group ${eventType}` : eventType, eventPayload);
  };

  // useEffect(() => {
  //   socket.on("typingResponse", ({ response }) => {
  //     setOne2oneTyping(response);
  //   });
  //   socket.on("stopTypingResponse", ({ response }) => {
  //     setOne2oneTyping(response);
  //   });
  //   one2oneTyping = { one2oneTyping }
  // }, [isTyping, one2oneTyping]);

  useEffect(() => {

    const handleTypingResponse = ({ response }) => {
      setOne2oneTyping(response);
    };

    const handleStopTypingResponse = ({ response }) => {
      setOne2oneTyping(response);
    };

    // Setting up the socket event listeners
    socket.on("typingResponse", handleTypingResponse);
    socket.on("stopTypingResponse", handleStopTypingResponse);

    // Cleanup function to remove the event listeners
    return () => {
      socket.off("typingResponse", handleTypingResponse);
      socket.off("stopTypingResponse", handleStopTypingResponse);
    };

  }, [isTyping, one2oneTyping]);


  const handleGiphySend = (data) => {
    setConversationMessage(data);
  };

  const handleMessageSend = () => {
    const mesResponse = handleClick();
    setConversationMessage({ ...mesResponse });
  };

  useEffect(() => {
    if (
      (conversationMessage &&
        conversationMessage.messageText &&
        conversationMessage.messageText.trim() !== "") ||
      (conversationMessage.msgHtml &&
        conversationMessage.msgHtml.trim() !== "") ||
      audioBlob ||
      (fileIds && fileIds.length > 0) ||
      (location && location.latitude)
    ) {
      let username = user?.firstName;
      if (selectedUserOrGroup?.remark === "Group") {
        let senderId = user?._id;
        let groupId = selectedUserOrGroup?._id;
        let organizationId = user?.organization;
        socket.emit("group stop typing", {
          senderId,
          groupId,
          username,
          organizationId,
        });
      } else {
        let senderId = user?._id;
        let receiverId = selectedUserOrGroup?._id;
        let organizationId = user?.organization;
        socket.emit("stop typing", {
          senderId,
          receiverId,
          organizationId,
          username,
        });
      }
      editor?.commands?.setContent("");

      sendMessage("", replyToMessage?.id, replyToMedia);
      setShowEmojiPicker(false);
      setIsEmpty(true);
      setMinHeight("45px");
    }
  }, [conversationMessage]);

  const [openDrawer, setOpenedDrawer] = useState({
    right: false,
  });

  const anchorX = "right";

  const toggleDrawerX = (anchorX, open) => (event) => {
    setIsPinDrawerOpen(!open);
    setOpenedDrawer({ ...openDrawer, [anchorX]: open });
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  };

  const [openDrawerGiphy, setOpenedDrawerGiphy] = useState({
    right: false,
  });

  const anchorGiphy = "right";

  const toggleDrawerGiphy = (anchorGiphy, open) => (event) => {
    setOpenedDrawerGiphy({ ...openDrawerGiphy, [anchorGiphy]: open });
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  };

  const markAsFavUser = async (chatHistoryId) => {
    await updateFavUser(recipient?.roomId);
    let elem = document.getElementById(`str_chat_${chatHistoryId}`);
    let iconElem = elem.querySelector("i");
    iconElem.classList.toggle("active");

    socket?.emit("getChatList", {
      userId: user?._id,
      organizationId: user?.organization,
    });
  };

  const handleScreenSharing = () => {
    if (window.electron) {
      window.electron.ipcRenderer.send("start-share", {});
    }
  };
  const handleClickOnMediaView = () => {
    getUserProfileDetails(
      selectedUserOrGroup?._id,
      selectedUserOrGroup?.remark === "Group" ? "group" : "single"
    );
    file_open_media();
  };
  const singleChatMessageHeader = () => {
    return (
      <div className="msg-head" id="single-head">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            {selectedUserOrGroup?.remark === "Group" ? (
              <div className="d-flex align-items-center">
                <span className="chat-icon">
                  <img
                    className="img-fluid"
                    src={
                      selectedUserOrGroup?.groupDP?.url ||
                      "./assets/images/gropu-icon.svg"
                    }
                    alt={selectedUserOrGroup?.groupDP?.title || "chatwork"}
                    loading="eager"
                  />
                </span>
                <div
                  className="flex-shrink-0 pnter"
                  onClick={() => {
                    groupDetails_open();
                  }}
                >
                  <img
                    className="img-fluid w40"
                    src={
                      selectedUserOrGroup?.groupDP?.url ||
                      "./assets/images/gropu-icon.svg"
                    }
                    loading="eager"
                    alt={selectedUserOrGroup?.groupDP?.title || "chatwork"}
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex align-items-center usernameActb">
                    <h3
                      className="pnter"
                      onClick={() => {
                        groupDetails_open();
                      }}
                    >
                      {selectedUserOrGroup?.title || "No Name"}
                    </h3>
                    {recipient && (
                      <div className="ms-3">
                        <button
                          className="orangestar  me-2"
                          id={`str_chat_${selectedUserOrGroup?._id}`}
                          onClick={() => {
                            markAsFavUser(selectedUserOrGroup?._id);
                          }}
                        >
                          <i
                            className={`mdi mdi-star mdi-18px ${recipient?.isFav &&
                              recipient?.isFav.includes(user?._id)
                              ? "active"
                              : ""
                              }`}
                          />
                        </button>
                        <MuteUnMute
                          selectedUserOrGroup={selectedUserOrGroup}
                          recipient={recipient}
                          user={user}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center activeusertop">
                    { }
                    <span className="totalmember font-14" >
                      {selectedUserOrGroup?.participants.length || "0"} Members{" "}
                    </span>
                    <span style={{ color: "#02bc7d", fontSize: "14px", fontWeight: "500" }}>{groupTyping
                      ? groupTyping?.groupId == selectedUserOrGroup?._id &&
                        (groupTyping?.senderId != user?._id &&
                          groupTyping?.message?.trim()) != ""
                        ? groupTyping?.message
                        : ""
                      : ""}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className="chat-icon">
                  <img
                    className="img-fluid"
                    src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                    alt="image title"
                    loading="lazy"
                  />
                </span>
                <div
                  className="flex-shrink-0 pnter"
                  onClick={() => {
                    profile_open();
                    getUserProfileDetails(
                      selectedUserOrGroup?._id,
                      selectedUserOrGroup?.remark === "Group"
                        ? "group"
                        : "single"
                    );
                  }}
                >
                  {renderProfilePicture(currentProfile)}
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex align-items-center usernameActb">
                    <h3
                      className="pnter"
                      onClick={() => {
                        profile_open();

                        getUserProfileDetails(
                          selectedUserOrGroup?._id,
                          selectedUserOrGroup?.remark === "Group"
                            ? "group"
                            : "single"
                        );
                      }}
                    >
                      {titleCase(
                        `${currentProfile?.firstName} ${currentProfile?.lastName}`
                      )}
                    </h3>
                    {recipient && (
                      <div className="ms-3">
                        <button
                          className="me-2"
                          id={`str_chat_${selectedUserOrGroup?._id}`}
                          onClick={() => {
                            markAsFavUser(selectedUserOrGroup?._id);
                          }}
                        >
                          <i
                            className={`mdi mdi-star mdi-18px ${recipient?.isFav &&
                              recipient?.isFav.includes(user?._id)
                              ? "active"
                              : ""
                              }`}
                          />
                        </button>
                        <MuteUnMute
                          selectedUserOrGroup={selectedUserOrGroup}
                          recipient={recipient}
                          user={user}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center activeusertop">
                    <a className="d-flex align-items-center gap-2" href="#">
                      {onlineUserList &&
                        onlineUserList.includes(currentProfile?._id) ? (
                        <>
                          {currentProfile?.liveStatus == "auto" && (
                            <>
                              <span className="d-inline-block rounded-circle p-1 mdi mdi-checkbox-blank-circle  text-green" />
                              Online
                            </>
                          )}
                          {currentProfile?.liveStatus == "custom" && (
                            <>
                              <span className="d-inline-block rounded-circle p-1 mdi mdi-message-processing" />
                              {currentProfile?.liveCustomStatus}
                            </>
                          )}
                          {currentProfile?.liveStatus === "dnd" && (
                            <>
                              <span className="d-inline-block mdi mdi-minus-circle text-red wdhs" />
                              Do not disturb{" "}
                            </>
                          )}
                          {currentProfile?.liveStatus === "meeting" && (
                            <>
                              <span className="d-inline-block mdi mdi-clock text-yellow wdhs" />
                              Meeting{" "}
                            </>
                          )}
                          {currentProfile?.liveStatus === "wfh" && (
                            <>
                              <span className="d-inline-block mdi mdi-home text-blue wdhs" />
                              Work from home
                            </>
                          )}
                          {currentProfile?.liveStatus === "away" && (
                            <>
                              <span className="d-inline-block mdi mdi-run" />
                              Away{" "}
                            </>
                          )}
                        </>
                      ) : (
                        "Last Seen :" + " " + " " +
                        getLastSeenString(
                          currentProfile?.otherInformation?.lastSeen
                        )
                      )}
                    </a>
                    <span>
                      <i className="mdi mdi-desktop-mac" />
                    </span>
                    {
                      currentProfile.firebaseToken && currentProfile.firebaseToken.length === 0 ? (
                        <span>                         
                          <img src="/assets/images/cellphone-crossed.png" height={20} width={20}/>
                        </span>
                      ) : (
                        <span>
                          <i className="mdi mdi-cellphone" />
                        </span>
                      )
                     }
                    <span style={{ color: "#02bc7d", fontSize: "14px", fontWeight: "500" }}> {one2oneTyping
                      ? one2oneTyping?.senderId == currentProfile?._id &&
                        one2oneTyping?.message?.trim() != ""
                        ? one2oneTyping?.message
                        : ""
                      : ""}</span>
                  </div>
                </div>
              </div>
            )}

            <ul className="moreoption mb-0">
              <li>
                <button
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-original-title="Screen Share"
                  onClick={()=>{}}
                >
                  { }
                  <i className="mdi mdi-laptop"></i>
                </button>
              </li>
              <li>
                <button
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-original-title="Search"
                  onClick={() => {
                    setSearchMessageString(null)
                    if (!messageFilterFlag) {
                      setSearchMessageString(null);
                      localStorage.removeItem("searchMessageString");
                      setSearchMessageType("text");
                    } else {
                    }
                    setMessageFilterFlag(!messageFilterFlag);
                  }}
                >
                  {!messageFilterFlag ? (
                    <i className="mdi mdi-magnify" />
                  ) : (
                    <i className="mdi mdi-close" />
                  )}
                </button>
              </li>
              <li>
                <button
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-original-title="Voice Call"
                >
                  <i className="mdi mdi-phone" />
                </button>
              </li>
              <li>
                <button
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-original-title="Video Call"
                >
                  <i className="mdi mdi-video" />
                </button>
              </li>
              <div className="dropdown toggleBtns visible">
                <button
                  className="toggledots"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="mdi mdi-dots-vertical" aria-hidden="true" />
                </button>
                <ul
                  className="dropdown-menu mx-0 shadow overflow-hidden  p-0"
                  data-bs-theme="light"
                  data-popper-placement="bottom-start"
                  style={{
                    position: "absolute",
                    inset: "0px auto auto 0px",
                    margin: 0,
                    transform: "translate(0px, 39px)",
                  }}
                >
                  <li>
                    {selectedUserOrGroup?.remark === "Group" ? (
                      <a
                        className="dropdown-item d-flex gap-2 align-items-center font-14 color1a fw-500"
                        href="#"
                        onClick={() => {
                          groupDetails_open();
                        }}
                      >
                        <i className="mdi mdi-account-outline" /> View Group
                      </a>
                    ) : (
                      <a
                        className="dropdown-item d-flex gap-2 align-items-center font-14 color1a fw-500"
                        href="#"
                        onClick={() => {
                          profile_open();
                          getUserProfileDetails(
                            selectedUserOrGroup?._id,
                            selectedUserOrGroup?.remark === "Group"
                              ? "group"
                              : "single"
                          );
                        }}
                      >
                        <i className="mdi mdi-account-outline" /> View Profile
                      </a>
                    )}
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center font-14 color1a fw-500"
                      href="#"
                      onClick={handleClickOnMediaView}
                    >
                      <i className="mdi mdi-play-circle-outline" /> Media
                    </a>
                  </li>
                  <li onClick={toggleDrawerX(anchorX, true)}>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center font-14 color1a fw-500"
                      href="#"
                    >
                      <i className="mdi mdi-pin-outline" /> Pin
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          <MessageActionAndFilter
            setDeleteMessageFlagGroup={setDeleteMessageFlagGroup}
            messageActionFlag={messageActionFlag}
            messageFilterFlag={messageFilterFlag}
            setMessageActionFlag={setMessageActionFlag}
            setMessageFilterFlag={setMessageFilterFlag}
            isMessageActionOpen={isMessageActionOpen}
            setIsMessageActionOpen={setIsMessageActionOpen}
            isUserPopupOpen={isUserPopupOpen}
            setIsUserPopupOpen={setIsUserPopupOpen}
            SearchActionUser={SearchActionUser}
            deleteMultipleMessages={deleteMultipleMessages}
            setSearchMessageString={setSearchMessageString}
            setSearchMessageType={setSearchMessageType}
            searchMessage={searchMessage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            key={999}
            resetAndToggleModal={resetAndToggleModal}
            setSelectMessages={setSelectMessages}
            setMediaToForward={setMediaToForward}
          />
        </div>
      </div>
    );
  };

  function insertEmoji(editor, emoji) {
    editor.chain().focus().insertContent(emoji).run();
  }

  const filterUserList = (userList) => {
    let list = userList?.filter((listUser) => listUser?._id !== user?._id);
    return list?.filter((listUser) => listUser?._id !== currentProfile?._id);
  };
  const convertBlob = async (blob) => {
    try {
      await ffmpeg.load();

      await ffmpeg.writeFile("input.webm", await fetchFile(blob));
      await ffmpeg.exec(["-i", "input.webm", "output.mp3"]);
      const data = await ffmpeg.readFile("output.mp3");
      const convertedBlob = new Blob([data], { type: "audio/mp3" });

      return convertedBlob;
    } catch (error) {
      throw error;
    }
  };

  const customMethod = async () => {
    if (recordedBlob) {
      const convertedBlob = await convertBlob(recordedBlob);
      setAudioBlob(convertedBlob);
    }
  };
  useEffect(() => {
    if (recordedBlob) {
      customMethod();
    }
  }, [recordedBlob]);

  useEffect(() => {
    if (isCleared) {
      setAudioBlob(null);
    }
    if (isPausedRecording) {
    }
    if (isRecordingInProgress) {
      setIsRecording(false);
    }
  }, [isCleared, isPausedRecording, isRecordingInProgress, isRecording]);

  useEffect(() => {
    if (!recordedBlob) return;
  }, [recordedBlob, error]);

  useEffect(() => {
    if (!error) return;
  }, [error]);

  const UserDropDownList = () => {
    return (
      <ul
        className="dropdown-menu droplistNew pt-0"
        aria-labelledby="dropdownMenuClickableInside"
      >
        <li className="px-3 py-2">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h5 className="mb-2 font-16 color33 fw-bold">
              {selectedUsers?.length} Members added
            </h5>
          </div>
          <div className="msg-search w-100 mx-0">
            <input
              type="text"
              className="form-control bg-none border-0"
              id="inlineFormInputGroup"
              placeholder="Search"
              aria-label="search"
              value={searchText}
              onChange={(e) => handleSearch(e)}
            />
            <button>
              <i className="mdi mdi-magnify"></i>
            </button>
          </div>
        </li>
        {searchText?.length > 0
          ? filterUserList(searchedUsers).map((user) => UserListItem(user))
          : filterUserList(userList)?.map((user) => UserListItem(user))}
      </ul>
    );
  };

  const renderProfilePicture = (participantsUser) => {
    const profilePicture = participantsUser?.otherInformation?.profilePicture;
    const firstNameInitial = participantsUser?.firstName?.charAt(0);
    const lastNameInitial = participantsUser?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="40"
          height="40"
          alt="user img"
          style={{
            border: `solid ${participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
              }`,
            borderWidth: "2px",
          }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: "50",
            height: "50",
            borderRadius: "50%",
            backgroundColor: "#e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `solid ${participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
              }`,
            borderWidth: "2px",
            color: "#555",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {`${firstNameInitial || ""}${lastNameInitial || " "}`}
        </div>
      );
    }
  };
  const uniqueTextArray = Array.from(new Set(userMessages?.map(JSON.stringify))).map(JSON.parse);


  const UserListItem = (user) => {
    return (
      <li key={user?._id}>
        <label htmlFor={user?._id}>
          <div className="d-flex gap-2 align-items-center">
            <span className="img">{renderProfilePicture(user)}</span>
            <div className="font-14 fw-500 color1a mb-0">
              {`${user?.firstName} ${user?.lastName}`}
              <span className="d-block font-10 fw-500 color80">
                {`${user?.otherInformation?.designation ?? "NA"}  `}
              </span>
            </div>
          </div>
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={selectedUsers.indexOf(user?._id) !== -1}
            onChange={(e) => handleSelect(e, user?._id)}
          />
        </label>
      </li>
    );
  };
  const [mainMessage, setMainMessage] = useState("");
  const [mainMessageDate, setMainMessageDate] = useState(null);

  useEffect(() => {
    const mainElement = document.getElementById(
      `messageDiv_${replyToMessage?.id}`
    );
    if (mainElement) {
      const messageElem = mainElement.querySelector("p");
      if (typeof messageElem === "string") {
        setMainMessage(messageElem?.textContent);
      } else {
        const messageElem2 =
          mainElement.querySelector(".bgretext a") ||
          mainElement.querySelector(".bgretext div");
        if (messageElem2) {
          if (messageElem2?.textContent == "") {
            const imgElem = mainElement.querySelector("img");
            if (imgElem) {
              setMainMessage(imgElem.src); // Set the image URL as the main message
            }
          } else {
            setMainMessage(messageElem2?.textContent);
          }
        } else {
          const imgElem = mainElement.querySelector("img");
          if (imgElem) {
            setMainMessage(imgElem); // Set the image URL as the main message
          }
        }
      }

      const timeElem = mainElement?.querySelector(".time");
      if (timeElem) {
        setMainMessageDate(timeElem?.textContent);
      }
    }

    if (!replyToMessage) {
      setMainMessage("");
      setMainMessageDate(null);
    }
  }, [replyToMessage]);

  const getTheMedia = (message) => {
    return message?.filesData?.map((mediaItem, index) => {
      let fileType = getFileType(mediaItem);
      switch (fileType) {
        case "video":
          return (
            <video controls style={{ width: "100%" }} key={index}>
              <source
                src={mediaItem?.url}
                controls
                autoPlay={false}
                type="video/mp4"
              />
            </video>
          );
        case "document":
        case "image":
          return (
            <img
              src={mediaItem?.url}
              className="img-fluid"
              alt=""
              key={index}
              loading="lazy"
            />
          );
        case "audio":
          return (
            <audio
              src={mediaItem?.url}
              controls
              autoPlay={false}
              key={index}
              controlsList="nodownload noplaybackrate"
            />
          );
        case "link":
        default:
          return (
            <img
              src="assets/images/videoimg/zip-file.svg"
              className="img-fluid"
              alt=""
              key={index}
              loading="lazy"
            />
          );
      }
    });
  };
  const MentionList = forwardRef((props, ref) => {
    const selectItem = (index) => {
      const item = props.items[index];
      if (item) {
        setSelectedIndexes((prevState) => [...prevState, index]);
        props.command({ id: item });
      }
    };

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length
      );
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }
        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }
        if (event.key === "Enter") {
          enterHandler();
          return true;
        }
        return false;
      },
    }));

    return (
      <div className="menation-dropdown-menu shadow">
        {props.items.length ? (
          props.items.map((item, index) => (
            <button
              className={index === selectedIndex ? "is-selected" : ""}
              key={index}
              onClick={() => selectItem(index)}
            >
              {item}
            </button>
          ))
        ) : (
          <div className="item">No result</div>
        )}
      </div>
    );
  });

  const suggestion = {
    items: ({ query, userList }) => {
      return userList
        ?.map((user) => user?.firstName + " " + user?.lastName)
        .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 5);
    },
    render: () => {
      let component;
      let popup;
      return {
        onStart: (props) => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide();
            return true;
          }
          return component.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
  const extensions = [
    StarterKit,
    Image,
    Placeholder.configure({
      placeholder: "Write your message...",
    }),
    Underline,
    BulletList,
    Code,
    CodeBlock,
    CodeBlockLowlight.configure({
      lowlight,
    }),
    Document,
    Paragraph,
    Text,
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https',
    }),
    Mention.configure({
      HTMLAttributes: {
        class: "mention",
      },
      deleteTriggerWithBackspace: true,
      suggestion: {
        ...suggestion,
        items: ({ query }) =>
          suggestion.items({
            query,
            userList: userList,
          }),
      },
    }),
  ];
  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        class: "input-box-tip tiptap ProseMirror",
      },
    },
    content,
    onUpdate: ({ editor }) => {
      const mentions =
        editor
          .getJSON()
          .content?.flatMap((block) =>
            block.content?.filter((node) => node.type === "mention")
          ) || [];
      const mentionIds = mentions.map((mention) => mention?.attrs?.id);
      setRemovedMentions((prevMentions) =>
        prevMentions.filter((mention) => !mentionIds.includes(mention.id))
      );
    },
  });


  const handlePaste = useCallback(
    (event) => {
      if (!editor) return;
      const items = event.clipboardData.items;
      let imageFile = null;
      for (const item of items) {
        if (item.kind === "file" && item.type.startsWith("image/")) {
          if (!imageFile) {
            imageFile = item.getAsFile();
          } else {
            continue;
          }
        }
      }
      if (imageFile) {
        handlePastedImage(imageFile);
      }
    },
    [editor, handlePastedImage]
  );

  async function handlePastedImage(blob) {
    const reader = new FileReader();
    reader.onload = async (readerEvent) => {
      const base64Image = readerEvent.target.result;
      editor.chain().focus().setImage({ src: base64Image }).run();

      // Convert blob to file
      const fileName = new Date().toISOString();
      const file = blobToFile(blob, fileName);

      try {
        // Clear the image after file upload is complete
        const { state, dispatch } = editor.view;
        const { doc } = state;
        const lastNode = doc.lastChild;
        if (lastNode && lastNode.type.name === 'image') {
          const endPosition = doc.content.size;
          const startPosition = endPosition - lastNode.nodeSize;
          const newTransaction = state.tr.delete(startPosition, endPosition);
          dispatch(newTransaction);
        }

        // Perform additional actions
        editor.commands.clearContent();
        clearCanvas();
        // Call handleFileUpload and wait for it to complete
        await handleFileUpload([file]);


      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };
    reader.readAsDataURL(blob);
  }


  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      editor.chain().focus().setContent(content).run();
    }
  }, [content, editor]);



  useEffect(() => {
    // console.log("Tahir")
    if (editor) {
      editor?.commands?.setContent("");
      editor?.chain().focus().run();
      setReplyToMessage(null);
      setReplyToMedia(null);
      // setTimeout(() => {
      //   if (listRef.current) {
      //     listRef.current.scrollTop = listRef.current.scrollHeight;
      //   }
      // }, 1000);
    }
  }, [chatRoomId, editor]);




  if (!editor) {
    return null;
  }

  const handleMessageChange = (event) => {
    setIsTyping(true);
  };

  const handleClick = () => {
    const msgHtml = editor.getHTML();
    const div = document.createElement("div");
    div.innerHTML = msgHtml;
    const codeElement = div.querySelector("code");
    if (codeElement) {
      codeElement.classList.add("language-javascript");
    }
    const modifiedMsgHtml = div.innerHTML;
    const msg = editor.getJSON();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = msgHtml;
    const innerText = tempDiv.innerText;
    return (message = {
      messageText: innerText,
      msgHtml: modifiedMsgHtml,
      msgJson: msg,
      replyToMedia: replyToMedia,
      imageGif: "",
    });
  };

  const handleEditorKeyDown = (event) => {
 
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      handleMessageSend();
    }  if (
      event.key === "Enter" &&
      event.shiftKey &&
      editor.isActive("bulletList")
    ) {
      editor.chain().focus().splitListItem("listItem").run();
    }
  };


  const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type });
  };


  return (

    <>
      { }

      <div
        className="modal-dialog-scrollable"
        {...getRootProps()}
        onClick={(e) => e.preventDefault()}
        id="chatBoxModal"
      >
        <div className="modal-content chatboxMsg" id="chatboxmsg">
          <>
            {singleChatMessageHeader()}
            <div>
              {downloads.map(download => (
                <DownloadSnackbar
                  key={download.id}
                  download={download}
                  onClose={handleCloseSnackbar}
                />
              ))}

            </div>


            <div
              className="modal-body"
              style={{
                background: accountSetting.background?.includes("images")
                  ? `url(${accountSetting.background})`
                  : accountSetting.background,
              }}
              id="single-body"
              ref={listRef}
            >
              {loading && isScrollUp && moreTopMessages &&
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>  <CircularProgress size={30}/>  </div>
              }
              <div className="msg-body" id="msg-body">
              <Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
    Zoom: {
      enabled: true, // Enable zoom functionality
      position: "top", // You can set the position of the zoom button
    },
  }}
>
  <>
    {uniqueTextArray && uniqueTextArray.length > 0 && (
      <ul>
        {uniqueTextArray.map((message, index) => {
          const previousMessage =
            index > 0 ? uniqueTextArray[index - 1] : null;
          return messageTemplate(message, index, previousMessage);
        })}
      </ul>
    )}
  </>
</Fancybox>

              </div>
              {copied &&
                openSuccessNotification("Message copied to clipboard!")}
              { }
              { }
              {/* {console.log("displayChatCounter", displayChatCounter)} */}
              {unreadUser?.unRead > 0 && userMessages?.length > 10 && (
                <div className='text-end toptboymBtn'>
                  {messageCounter > 0 && (<span className='countMsgT'>{unreadUser?.unRead}</span>)}
                  <a id="Scroll2BottomButton" className="mdi mdi-chevron-double-down" onClick={scrollButton}></a>
                </div>
              )}

              {/* <Scroll2Bottom /> */}
            </div>
          </>

          {["right"].map((anchorX) => (
            <React.Fragment key={anchorX}>
              <SwipeableDrawer
                anchor={anchorX}
                open={openDrawer[anchorX]}
                onClose={toggleDrawerX(anchorX, false)}
                onOpen={toggleDrawerX(anchorX, true)}
              >
                { }
                <ViewPinMessageList
                  anchor={anchorX}
                  chatRoomId={chatRoomIdX}
                  setIsPinDrawerOpen={setIsPinDrawerOpen}
                  openDrawer={openDrawer}
                  setOpenedDrawer={setOpenedDrawer}
                  userDetails={selectedUserOrGroup}
                  socket={socket}
                  setRevelingConMessage={setRevelingConMessage}
                  getCurrentTime={getCurrentTime}
                  toggleDrawerX={toggleDrawerX}
                  unpinMessage={unpinMessage}
                  setConversationData={setConversationData}
                  formatMessage={formatMessage}
                  scrollToItem={scrollToItem}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}

          {/* {["right"].map((anchorGiphy) => (
            <React.Fragment key={anchorGiphy}>
              <SwipeableDrawer
                toggleDrawerGiphy={toggleDrawerGiphy}
                anchor={anchorGiphy}
                open={openDrawerGiphy[anchorGiphy]}
                onClose={toggleDrawerGiphy(anchorGiphy, false)}
                onOpen={toggleDrawerGiphy(anchorGiphy, true)}
              >
                <ViewGiphy
                  toggleDrawerGiphy={toggleDrawerGiphy}
                  anchorGiphy={anchorGiphy}
                  handleGiphySend={handleGiphySend}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))} */}

          <div className="send-box">
            {replyToMessage?.messageType == "text" && (
              <>
                <div className="replySbx">
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2 color99">
                      <button className="replyarrow p-1 border-0 bg-none">
                        <img
                          src={
                            currentProfile?.otherInformation?.profilePicture ||
                            "/assets/images/replyarrow.svg"
                          }
                          className="img-fluid"
                          alt=""
                          style={{ width: "20px", borderRadius: "0%" }}
                          loading="lazy"
                        />
                      </button>
                      <div className="d-flex align-items-center gap-2 color99 font-14 fw-500">
                        { }
                        { }
                        {currentProfile.firstName} {currentProfile.lastName}
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <div className="font-12 color66">{mainMessageDate}</div>{" "}
                      <button
                        className="closbtn"
                        onClick={() => {
                          setMainMessage("");
                          setReplyToMessage(null);
                          setReplyToMedia(null);
                        }}
                      >
                        <i className="mdi mdi-close m-0" />
                      </button>
                    </div>
                  </div>
                  <div className="rely_text">
                    {typeof mainMessage === "string" ? (
                      <p>{mainMessage}</p>
                    ) : (
                      <>
                        {mainMessage && (
                          <img src={mainMessage.src} alt="Main Message Image" loading="lazy" />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {replyToMessage?.messageType == "link" && (
              <>
                <div className="replySbx">
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2 color99">
                      <button className="replyarrow p-1 border-0 bg-none">
                        <img
                          src={
                            currentProfile?.otherInformation?.profilePicture ||
                            "/assets/images/replyarrow.svg"
                          }
                          className="img-fluid"
                          alt=""
                          style={{ width: "20px", borderRadius: "0%" }}
                          loading="lazy"
                        />
                      </button>
                      <div className="d-flex align-items-center gap-2 color99 font-14 fw-500">
                        { }
                        {currentProfile.firstName} {currentProfile.lastName}
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <div className="font-12 color66">{mainMessageDate}</div>{" "}
                      <button
                        className="closbtn"
                        onClick={() => {
                          setMainMessage("");
                          setReplyToMessage(null);
                          setReplyToMedia(null);
                        }}
                      >
                        <i className="mdi mdi-close m-0" />
                      </button>
                    </div>
                  </div>
                  <div className="rely_text">
                    <p>{mainMessage}</p>
                  </div>
                </div>
              </>
            )}
            {replyToMessage?.messageType == "media" && (
              <>
                <div className="replySbx">
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2 color99">
                      <button className="replyarrow p-1 border-0 bg-none">
                        <img
                          src={
                            currentProfile?.otherInformation?.profilePicture ||
                            "/assets/images/replyarrow.svg"
                          }
                          className="img-fluid"
                          alt=""
                          style={{ width: "20px", borderRadius: "0%" }}
                          loading="lazy"
                        />
                      </button>
                      <div className="d-flex align-items-center gap-2 color99 font-14 fw-500">
                        { }
                        {currentProfile.firstName} {currentProfile.lastName}
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <div className="font-12 color66">{mainMessageDate}</div>{" "}
                      <button
                        className="closbtn"
                        onClick={() => {
                          setMainMessage("");
                          setReplyToMessage(null);
                          setReplyToMedia(null);
                        }}
                      >
                        <i className="mdi mdi-close m-0" />
                      </button>
                    </div>
                  </div>
                  <div className="rely_text">
                    {replyToMedia ? (
                      <div className="DprSend">
                        {(() => {
                          const element = document
                            .getElementById(`media_${replyToMedia}`)
                            .querySelector(".filebgn1");
                          let content;
                          if (element) {
                            content = element.innerHTML;
                          } else {
                            content = "Content not found";
                          }
                          return (
                            <div
                              dangerouslySetInnerHTML={{ __html: content }}
                            />
                          );
                        })()}
                      </div>
                    ) : (
                      <div className="DprSend">
                        {getTheMedia(replyToMessage)}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {selectedUserOrGroup?.remark === "Group" &&
              ((!selectedUserOrGroup?.isDeactivated &&
                selectedUserOrGroup?.participants.includes(user?._id) &&
                selectedUserOrGroup?.isChatAllowed) ||
                selectedUserOrGroup?.moderators?.includes(user?._id) ||
                selectedUserOrGroup?.admins?.includes(user?._id)) && (
                <>
                  {showEmojiPicker && (
                    <Picker
                      showPreview={false}
                      showSkinTones={false}
                      emojiTooltip={true}
                      title="Pick your emoji…"
                      style={{
                        borderRadius: ".1rem",
                        maxWidth: "100%",
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                      onSelect={(emoji) => insertEmoji(editor, emoji.native)}
                    />
                  )}
                  <div className="editor-wrapper">
                    {loadingAudio && <SkeletonLoaderSmall />}
                    {showAudioRecorder && !loadingAudio && (
                      <div className="audioRecordComponent">
                        <VoiceVisualizer
                          isAudioProcessingTextShown={false}
                          fullscreen={true}
                          ref={audioRef}
                          height={"35px"}
                          controls={recorderControls}
                          secondaryBarColor
                          backgroundColor={"#fff"}
                          isDefaultUIShown={false}
                          defaultAudioWaveIconColor={"#fff"}
                          mainBarColor={"#000"}
                          width={"200"}
                          isDownloadAudioButtonShown={false}
                          speed={1}
                          barWidth={1}
                          gap={0}
                          rounded={1}
                          controlButtonsClassName={"helloRecord"}
                        />
                        <button
                          title="Audio Message"
                          type="button"
                          className="stopAudioButton"
                          onClick={() => {
                            stopRecording();
                            let closeBtn =
                              document.querySelector(".stopAudioButton");
                            if (closeBtn) {
                              closeBtn.style.display = "none";
                            }
                          }}
                        >
                          <i className="mdi mdi-stop" aria-hidden="true" />
                        </button>
                        <button
                          title="Audio Message"
                          type="button"
                          className="closeAudioButton"
                          onClick={() => {
                            setShowAudioRecorder(!showAudioRecorder);
                            clearCanvas();
                          }}
                        >
                          <i className="mdi mdi-close" aria-hidden="true" />
                        </button>

                        { }
                      </div>
                    )}

                    {fileNames.length > 0 && (
                      <div className="clientDocShow my-4">
                        {fileNames.map((file, index) => (
                          <div key={index} className="cleDocContent">
                            <div className="d-flex align-items-start gap-1">
                              <div className="fileFormat">
                                <span className="bgFilesg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="currentColor"
                                    class="bi bi-file-earmark-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                                  </svg>
                                </span>
                                <span className="filefmttext">{file.extension}</span>
                              </div>
                              <div>
                                <div className="FileName">
                                  {file?.title
                                    ?
                                    (() => {
                                      const dotIndex = file.title.lastIndexOf(".");
                                      const name = file.title.slice(0, dotIndex); // Name without extension
                                      const extension = file.title.slice(dotIndex); // Extension including the dot
                                      return name.length > 5
                                        ? name.slice(0, 5) + ".." + extension
                                        : file.title;
                                    })()
                                    : null}
                                </div>

                                <div className="FileSize">
                                  {formatFileSizeUpload(file.size)}{" "}
                                </div>

                                <div className="FileType">Type: {file.extension}</div>
                              </div>
                            </div>
                            <button
                              className="deletFilebtn px-3 py-3 d-flex justify-content-center align-items-center"
                              onClick={() => handleDeleteUploadedFiles(file?._id)}
                            >
                              <svg
                                width={16}
                                height={18}
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.30775 17.4997C2.81058 17.4997 2.385 17.3227 2.031 16.9687C1.677 16.6147 1.5 16.1892 1.5 15.692V2.99973H1.25C1.0375 2.99973 0.859417 2.92782 0.71575 2.78398C0.571917 2.64015 0.5 2.46198 0.5 2.24948C0.5 2.03682 0.571917 1.85873 0.71575 1.71523C0.859417 1.57157 1.0375 1.49973 1.25 1.49973H5C5 1.2549 5.08625 1.04624 5.25875 0.873735C5.43108 0.701402 5.63967 0.615234 5.8845 0.615234H10.1155C10.3603 0.615234 10.5689 0.701402 10.7413 0.873735C10.9138 1.04624 11 1.2549 11 1.49973H14.75C14.9625 1.49973 15.1406 1.57165 15.2843 1.71548C15.4281 1.85932 15.5 2.03748 15.5 2.24998C15.5 2.46265 15.4281 2.64073 15.2843 2.78423C15.1406 2.9279 14.9625 2.99973 14.75 2.99973H14.5V15.692C14.5 16.1892 14.323 16.6147 13.969 16.9687C13.615 17.3227 13.1894 17.4997 12.6923 17.4997H3.30775ZM13 2.99973H3V15.692C3 15.7818 3.02883 15.8556 3.0865 15.9132C3.14417 15.9709 3.21792 15.9997 3.30775 15.9997H12.6923C12.7821 15.9997 12.8558 15.9709 12.9135 15.9132C12.9712 15.8556 13 15.7818 13 15.692V2.99973ZM6.15425 13.9997C6.36675 13.9997 6.54483 13.9279 6.6885 13.7842C6.832 13.6404 6.90375 13.4622 6.90375 13.2497V5.74973C6.90375 5.53723 6.83183 5.35907 6.688 5.21523C6.54433 5.07157 6.36617 4.99973 6.1535 4.99973C5.941 4.99973 5.76292 5.07157 5.61925 5.21523C5.47575 5.35907 5.404 5.53723 5.404 5.74973V13.2497C5.404 13.4622 5.47583 13.6404 5.6195 13.7842C5.76333 13.9279 5.94158 13.9997 6.15425 13.9997ZM9.8465 13.9997C10.059 13.9997 10.2371 13.9279 10.3807 13.7842C10.5243 13.6404 10.596 13.4622 10.596 13.2497V5.74973C10.596 5.53723 10.5242 5.35907 10.3805 5.21523C10.2367 5.07157 10.0584 4.99973 9.84575 4.99973C9.63325 4.99973 9.45517 5.07157 9.3115 5.21523C9.168 5.35907 9.09625 5.53723 9.09625 5.74973V13.2497C9.09625 13.4622 9.16817 13.6404 9.312 13.7842C9.45567 13.9279 9.63383 13.9997 9.8465 13.9997Z"
                                  fill="#1C1B1F"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    {progress > 0 && progress <= 100 && loader && (
                      <Box position="relative" display="inline-flex">
                        <CircularProgress
                          variant="determinate"
                          value={progress}
                          sx={{}}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "12px",
                          }}
                        >
                          {`${progress}%`}
                        </Box>
                      </Box>
                    )}
                    <div className="editor-wrapper">
                      {location?.latitude && (
                        <>
                          <div className="ifrmLocation">
                            <GoogleMapShare
                              latitude={location?.latitude}
                              longitude={location?.longitude}
                            />
                            <button
                              onClick={handleMapcancel}
                              className="button cancel"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="icon"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 13.59L15.59 16 12 12.41 8.41 16 7 14.59 10.59 11 7 7.41 8.41 6 12 9.59 15.59 6 17 7.41 13.41 11 17 14.59z" />
                              </svg>
                            </button>
                          </div>
                        </>
                      )}

                      <EditorContent
                        editor={editor}
                        onKeyDown={handleEditorKeyDown}
                        onPaste={handlePaste}
                        onInput={handleMessageChange}
                        spellCheck={true}
                      />
                    </div>
                  </div>
                  <div className="send-btns">
                    <div className="attach d-flex justify-content-between align-items-center">
                      <div className="">
                        <span className="ql-formats d-flex">
                          <div className="">
                            <div className="button-wrapper" {...getRootProps()}>
                              <span className="label">
                                <i
                                  className="mdi mdi-paperclip"
                                  onClick={() => {
                                    editor?.chain().focus().run();
                                  }}
                                ></i>
                              </span>
                              <input {...getInputProps()} multiple />
                            </div>
                          </div>
                          <button
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          >
                            <i
                              className="mdi mdi-emoticon-happy"
                              style={{
                                background: showEmojiPicker ? "gray" : "",
                              }}
                            />
                          </button>

                          {/* <button
                            onClick={toggleDrawerGiphy(anchorGiphy, true)}
                          >
                            <i className="mdi mdi-sticker-emoji" />
                          </button> */}

                          <button
                            onClick={() =>
                              editor.chain().focus().toggleBold().run()
                            }
                            disabled={
                              !editor.can().chain().focus().toggleBold().run()
                            }
                            className={`button ${editor.isActive("bold") ? "is-active" : ""
                              } ql-bold`}
                          >
                            <i
                              className="mdi mdi-format-bold"
                              style={{
                                background: editor.isActive("bold")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                            onClick={() =>
                              editor.chain().focus().toggleItalic().run()
                            }
                            disabled={
                              !editor.can().chain().focus().toggleItalic().run()
                            }
                            className={
                              editor.isActive("italic") ? "is-active" : ""
                            }
                          >
                            <i
                              className="mdi mdi-format-italic"
                              style={{
                                background: editor.isActive("italic")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                            onClick={() =>
                              editor.chain().focus().toggleUnderline().run()
                            }
                            className={
                              editor.isActive("underline")
                                ? "is-active"
                                : "ql-underline"
                            }
                          >
                            <i
                              className="mdi mdi-format-underline"
                              style={{
                                background: editor.isActive("underline")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                            onClick={() =>
                              editor.chain().focus().toggleBulletList().run()
                            }
                            className={
                              editor.isActive("bulletList") ? "is-active" : ""
                            }
                          >
                            <i
                              className="mdi mdi-format-list-bulleted"
                              style={{
                                background: editor.isActive("bulletList")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                        </span>
                      </div>
                      <div className="sendbtnright">
                        <div className="btn-group dropup">
                          <button
                            className="btn"
                            type="button"
                            id="dropdownMenuClickableInside"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            title="Forkout"
                            onClick={() =>
                              setShowModal(true)
                            }
                          >
                            <i
                              className="mdi mdi-cursor-move"
                              aria-hidden="true"
                            />
                          </button>
                          {UserDropDownList()}
                        </div>
                        <button
                          title="Location Tracking"
                          type="button"
                          className=""
                          onClick={() => getLocation()}
                        >
                          <i
                            className="mdi mdi-map-marker"
                            aria-hidden="true"
                          />
                        </button>

                        <button
                          title="Voice Message"
                          type="button"
                          className=""
                          onClick={() => {
                            setShowAudioRecorder(!showAudioRecorder);
                            clearCanvas();
                          }}
                        >
                          <i
                            className="mdi mdi-microphone"
                            aria-hidden="true"
                          />
                        </button>
                        {!isRecordingInProgress && (
                          <button
                            type="button"
                            className="sendbtn"
                            onClick={() => {
                              handleMessageSend();
                              clearCanvas();
                              editor?.chain().focus().run();
                            }}
                          >
                            Send
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

            {selectedUserOrGroup?.remark === "Group" &&
              selectedUserOrGroup?.noParticipants &&
              selectedUserOrGroup?.noParticipants.includes(user?._id) && (
                <h6 className="bglonMan" style={{ textAlign: "center" }}>
                  You are no longer a participant
                </h6>
              )}
            {selectedUserOrGroup?.remark !== "Group" && (
              <>
                {selectedUserOrGroup?.remark !== "Group" && selectedUserOrGroup?.active === false ? <p>You cannot send message..</p> : <> {showEmojiPicker && (
                  <Picker
                    showPreview={false}
                    showSkinTones={false}
                    emojiTooltip={true}
                    title="Pick your emoji…"
                    style={{
                      borderRadius: ".1rem",
                      maxWidth: "100%",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                    onSelect={(emoji) => insertEmoji(editor, emoji.native)}
                  />
                )}
                  <div className="editor-wrapper">
                    {loadingAudio && <SkeletonLoaderSmall />}
                    {showAudioRecorder && !loadingAudio && (
                      <div className="audioRecordComponent">
                        <VoiceVisualizer
                          isAudioProcessingTextShown={false}
                          fullscreen={true}
                          ref={audioRef}
                          height={"35px"}
                          controls={recorderControls}
                          secondaryBarColor
                          backgroundColor={"#fff"}
                          isDefaultUIShown={false}
                          defaultAudioWaveIconColor={"#fff"}
                          mainBarColor={"#000"}
                          width={"200"}
                          isDownloadAudioButtonShown={false}
                          speed={1}
                          barWidth={1}
                          gap={0}
                          rounded={1}
                          controlButtonsClassName={"helloRecord"}
                        />
                        <button
                          title="Audio Message"
                          type="button"
                          className="stopAudioButton"
                          onClick={() => {
                            stopRecording();
                            let closeBtn =
                              document.querySelector(".stopAudioButton");
                            if (closeBtn) {
                              closeBtn.style.display = "none";
                            }
                          }}
                        >
                          <i className="mdi mdi-stop" aria-hidden="true" />
                        </button>
                        <button
                          title="Audio Message"
                          type="button"
                          className="closeAudioButton"
                          onClick={() => {
                            setShowAudioRecorder(!showAudioRecorder);
                            clearCanvas();
                          }}
                        >
                          <i className="mdi mdi-close" aria-hidden="true" />
                        </button>
                        { }
                      </div>
                    )}
                    {fileNames.length > 0 && (
                      <div className="clientDocShow my-4">
                        {fileNames.map((file, index) => (
                          <div key={index} className="cleDocContent">
                            <div className="d-flex align-items-start gap-1">
                              <div className="fileFormat">
                                <span className="bgFilesg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="currentColor"
                                    class="bi bi-file-earmark-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                                  </svg>
                                </span>
                                <span className="filefmttext">{file.extension}</span>
                              </div>
                              <div>
                                <div className="FileName">
                                  {file?.title
                                    ?
                                    (() => {
                                      const dotIndex = file.title.lastIndexOf(".");
                                      const name = file.title.slice(0, dotIndex);
                                      const extension = file.title.slice(dotIndex);
                                      return name.length > 20
                                        ? name.slice(0, 8) + ".." + extension
                                        : file.title;
                                    })()
                                    : null}
                                </div>

                                <div className="FileSize">
                                  {formatFileSizeUpload(file.size)}{" "}
                                </div>

                                <div className="FileType">Type: {file.extension}</div>
                              </div>
                            </div>
                            <button
                              className="deletFilebtn px-3 py-3 d-flex justify-content-center align-items-center"
                              onClick={() => handleDeleteUploadedFiles(file?._id)}
                            >
                              <svg
                                width={16}
                                height={18}
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.30775 17.4997C2.81058 17.4997 2.385 17.3227 2.031 16.9687C1.677 16.6147 1.5 16.1892 1.5 15.692V2.99973H1.25C1.0375 2.99973 0.859417 2.92782 0.71575 2.78398C0.571917 2.64015 0.5 2.46198 0.5 2.24948C0.5 2.03682 0.571917 1.85873 0.71575 1.71523C0.859417 1.57157 1.0375 1.49973 1.25 1.49973H5C5 1.2549 5.08625 1.04624 5.25875 0.873735C5.43108 0.701402 5.63967 0.615234 5.8845 0.615234H10.1155C10.3603 0.615234 10.5689 0.701402 10.7413 0.873735C10.9138 1.04624 11 1.2549 11 1.49973H14.75C14.9625 1.49973 15.1406 1.57165 15.2843 1.71548C15.4281 1.85932 15.5 2.03748 15.5 2.24998C15.5 2.46265 15.4281 2.64073 15.2843 2.78423C15.1406 2.9279 14.9625 2.99973 14.75 2.99973H14.5V15.692C14.5 16.1892 14.323 16.6147 13.969 16.9687C13.615 17.3227 13.1894 17.4997 12.6923 17.4997H3.30775ZM13 2.99973H3V15.692C3 15.7818 3.02883 15.8556 3.0865 15.9132C3.14417 15.9709 3.21792 15.9997 3.30775 15.9997H12.6923C12.7821 15.9997 12.8558 15.9709 12.9135 15.9132C12.9712 15.8556 13 15.7818 13 15.692V2.99973ZM6.15425 13.9997C6.36675 13.9997 6.54483 13.9279 6.6885 13.7842C6.832 13.6404 6.90375 13.4622 6.90375 13.2497V5.74973C6.90375 5.53723 6.83183 5.35907 6.688 5.21523C6.54433 5.07157 6.36617 4.99973 6.1535 4.99973C5.941 4.99973 5.76292 5.07157 5.61925 5.21523C5.47575 5.35907 5.404 5.53723 5.404 5.74973V13.2497C5.404 13.4622 5.47583 13.6404 5.6195 13.7842C5.76333 13.9279 5.94158 13.9997 6.15425 13.9997ZM9.8465 13.9997C10.059 13.9997 10.2371 13.9279 10.3807 13.7842C10.5243 13.6404 10.596 13.4622 10.596 13.2497V5.74973C10.596 5.53723 10.5242 5.35907 10.3805 5.21523C10.2367 5.07157 10.0584 4.99973 9.84575 4.99973C9.63325 4.99973 9.45517 5.07157 9.3115 5.21523C9.168 5.35907 9.09625 5.53723 9.09625 5.74973V13.2497C9.09625 13.4622 9.16817 13.6404 9.312 13.7842C9.45567 13.9279 9.63383 13.9997 9.8465 13.9997Z"
                                  fill="#1C1B1F"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}

                    { }
                    {progress > 0 && progress <= 100 && loader && (
                      <Box position="relative" display="inline-flex">
                        <CircularProgress
                          variant="determinate"
                          value={progress}
                          sx={{}}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "12px",
                          }}
                        >
                          {`${progress}%`}
                        </Box>
                      </Box>
                    )}
                    <div className="editor-wrapper">
                      {location?.latitude && (
                        <>
                          <div className="ifrmLocation">
                            <GoogleMapShare
                              latitude={location?.latitude}
                              longitude={location?.longitude}
                            />
                            <button
                              onClick={handleMapcancel}
                              className="button cancel"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="icon"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 13.59L15.59 16 12 12.41 8.41 16 7 14.59 10.59 11 7 7.41 8.41 6 12 9.59 15.59 6 17 7.41 13.41 11 17 14.59z" />
                              </svg>
                            </button>
                          </div>
                        </>
                      )}

                      <EditorContent
                        editor={editor}
                        onKeyDown={handleEditorKeyDown}
                        className="custom-editor-content"
                        onPaste={handlePaste}
                        onInput={handleMessageChange}
                        spellCheck={true}
                      />
                    </div>
                  </div>
                  <div className="send-btns">
                    <div className="attach d-flex justify-content-between align-items-center">
                      <div className="">
                        <span className="ql-formats d-flex">
                          <div className="">
                            <div className="button-wrapper" {...getRootProps()}>
                              <span className="label">
                                <i
                                  className="mdi mdi-paperclip sss"
                                  onClick={() => {
                                    editor?.chain().focus().run();
                                  }}
                                ></i>
                              </span>
                              <input {...getInputProps()} multiple />
                            </div>
                          </div>
                          <button
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          >
                            <i
                              className="mdi mdi-emoticon-happy"
                              style={{
                                background: showEmojiPicker ? "gray" : "",
                              }}
                            />
                          </button>

                          {/* <button onClick={toggleDrawerGiphy(anchorGiphy, true)}>
                            <i className="mdi mdi-sticker-emoji" />
                          </button> */}

                          <button
                            onClick={() =>
                              editor.chain().focus().toggleBold().run()
                            }
                            disabled={
                              !editor.can().chain().focus().toggleBold().run()
                            }
                            className={`button ${editor.isActive("bold") ? "is-active" : ""
                              } ql-bold`}
                          >
                            <i
                              className="mdi mdi-format-bold"
                              style={{
                                background: editor.isActive("bold") ? "gray" : "",
                              }}
                            />
                          </button>
                          <button
                            onClick={() =>
                              editor.chain().focus().toggleItalic().run()
                            }
                            disabled={
                              !editor.can().chain().focus().toggleItalic().run()
                            }
                            className={
                              editor.isActive("italic") ? "is-active" : ""
                            }
                          >
                            <i
                              className="mdi mdi-format-italic"
                              style={{
                                background: editor.isActive("italic")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                            onClick={() =>
                              editor.chain().focus().toggleUnderline().run()
                            }
                            className={
                              editor.isActive("underline")
                                ? "is-active"
                                : "ql-underline"
                            }
                          >
                            <i
                              className="mdi mdi-format-underline"
                              style={{
                                background: editor.isActive("underline")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                            onClick={() =>
                              editor.chain().focus().toggleBulletList().run()
                            }
                            className={
                              editor.isActive("bulletList") ? "is-active" : ""
                            }
                          >
                            <i
                              className="mdi mdi-format-list-bulleted"
                              style={{
                                background: editor.isActive("bulletList")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                        </span>
                      </div>

                      <div className="sendbtnright">
                        <div className="btn-group dropup">
                          <button
                            className="btn"
                            type="button"
                            id="dropdownMenuClickableInside"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            title="Forkout"
                            onClick={() =>
                              setShowModal(true)}
                          >
                            <i
                              className="mdi mdi-cursor-move"
                              aria-hidden="true"
                            />
                          </button>
                          {UserDropDownList()}
                        </div>
                        <button
                          title="Location Tracking"
                          type="button"
                          className=""
                          onClick={() => getLocation()}
                        >
                          <i className="mdi mdi-map-marker" aria-hidden="true" />
                        </button>
                        <button
                          title="Voice Message"
                          type="button"
                          onClick={() => {
                            setShowAudioRecorder(!showAudioRecorder);
                            clearCanvas();
                          }}
                        >
                          <i className="mdi mdi-microphone" aria-hidden="true" />
                        </button>
                        {!isRecordingInProgress && (
                          <button
                            type="button"
                            className="sendbtn"
                            onClick={() => {
                              handleMessageSend();
                              editor?.chain().focus().run();
                            }}
                          >
                            Send
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                }
              </>
            )}
          </div>
        </div>
        { }

        {deleteFilesFlag && (
          <DeleteFiles
            handleDeleteMedia={handleDeleteMedia}
            deleteFilesFlag={deleteFilesFlag}
            selectedUserOrGroup={selectedUserOrGroup}
            mediaIds={mediaIds}
            forAllOption={forAllOption}
          />
        )}
      </div>
    </>
  );

}
// });
// export default React.memo(Chatbox, (prevProps, nextProps) => {
//   return prevProps.displayChatCounter === nextProps.displayChatCounter &&
//     prevProps.moreTopMessages === nextProps.moreTopMessages &&
//     prevProps.senderId === nextProps.senderId &&
//     prevProps.newChatLoad === nextProps.newChatLoad &&
//     prevProps.lastElementId === nextProps.lastElementId &&
//     prevProps.isScrollUp === nextProps.isScrollUp &&
//     prevProps.scrollPosition === nextProps.scrollPosition &&
//     prevProps.newMessage === nextProps.newMessage &&
//     prevProps.setLastReadMessageIdState === nextProps.setLastReadMessageIdState &&
//     prevProps.loadMoreMessages === nextProps.loadMoreMessages &&
//     prevProps.revelingConMessage === nextProps.revelingConMessage &&
//     prevProps.lastReadMessageIdState === nextProps.lastReadMessageIdState &&
// });


export default Chatbox;

import { useSelector } from "react-redux";
import { getCurrentTime } from "src/utils/CommonUtils";

const GroupTabList = (props) => {
  const user = useSelector((state) => state.auth.user);
  const {
    selectedRoomId,
    getRootProps,
    getInputProps,
    groupList,
    joinGroupFun,
    socket,
    handleSocketUserListResponse,
    resetAndToggleModal,
    searching,
    searchedUserItems,
    inactiveValue,
    inactiveSearchValue
  } = props;
  return (
    <div
      className={`tab-pane fade ${inactiveValue === "deActiveGroups" ? "show active" : inactiveValue === "deActiveUsers" ? "" : "show active"}`}
      id="Group"
      role="tabpanel"
      aria-labelledby="Group-tab"
    >
      {inactiveSearchValue?.length > 0 ? (
        <div
          className="chat-list"
          {...getRootProps()}
          onClick={(e) => {
            e.preventDefault();
            resetAndToggleModal();
          }}
        >
          {searchedUserItems}
        </div>
      ) : (
        <div
          className="chat-list"
          {...getRootProps()}
          onClick={(e) => {
            e.preventDefault();
            resetAndToggleModal();
          }}
        >
          {/* {console.log("groupList", groupList)} */}
          {/* {console.log("selectedRoomId", selectedRoomId)}*/}
          {/* {console.log("groupList", groupList)} */}
          {groupList.filter((grp) => grp.participants.includes(user._id) || grp.noParticipants.includes(user._id)).map((grp, index) => (
            // <div
            //   className={`chat-list ${grp?.isDeactivated && !grp?.isDeleted ? "blurGroup" : ""} ${grp?.isDeleted ? "dangerGroup" : ""}`}
            //   key={index}
            // >
            <div
              className={`chat-user-list ${(selectedRoomId === grp?._id) ? "selectedChatBox" : ""}`}
              key={index}
            >
              <a
                href="#"
                className="d-flex align-items-start"
                onClick={(e) => {
                  e.preventDefault();
                  joinGroupFun(grp);
                  // socket.on("getChatListSpecific", handleSocketUserListResponse);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  joinGroupFun(grp);
                  // socket.on("getChatListSpecific", handleSocketUserListResponse);
                }}
              >
                <div className="flex-shrink-0 position-relative imgUserLs">
                  <img
                    className="img-fluid userimg"
                    loading="lazy"
                    src={
                      grp?.groupDP?.url ||
                      "../../assets/images/no_group_image.jpg"
                    }
                    alt={grp?.groupDP?.title || "Group Image"}
                    width={45}
                    height={45}
                    style={{
                      border: "solid #c890ef",
                      borderWidth: "2px",
                    }}
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <input {...getInputProps()} multiple />
                    <h3>
                      <span className="group-icon" /> {grp.title}
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between grptext">
                    <div className="text onelinetext font-14 d-flex align-items-center text66">
                      {`Details: ${grp.content || " ---"}`}
                    </div>
                  </div>
                  <div className="onlineOfine d-flex justify-content-between align-items-center">
                    <div className="clock font-14 textb3">
                      {`Created: ${getCurrentTime(grp?.createdAt)}`}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupTabList;

const AllMessageTabList = (props) => {
    const {
      searching,
      searchedUserItems,
      renderedItems,
      inactiveSearchValue
    } = props;
    return (
     
      
      <div
        className="tab-pane fade show active"
        id="AllMessage"
        role="tabpanel"
        aria-labelledby="AllMessage-tab"
      >
        <div className="chat-list">
          {inactiveSearchValue?.length > 0 ? (
            <div>{searchedUserItems}</div>
          ) : (
            <div>{renderedItems}</div>
          )}
        </div>
      </div>
    );
  };
  
  export default AllMessageTabList;
  

import { getCurrentTime } from "src/utils/CommonUtils";

const OrangeMemberTabList = (props) => {
  const {
    resetAndToggleModal,
    orangeUserList,
    startChat,
    searching,
    searchedUserItems,
  } = props;

  return (
    <div
      className="tab-pane fade"
      id="OrangeMember"
      role="tabpanel"
      aria-labelledby="OrangeMember-tab"
    >
      {searching ? (
        <div className="chat-list">
          {searchedUserItems}
        </div>
      ) : (
        <div className="chat-list">
          {orangeUserList && orangeUserList.length > 0 ? (
            orangeUserList.map((item, index) => (
              <a
                href="#"
                className="d-flex align-items-start"
                key={index}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  startChat(item);
                  resetAndToggleModal();
                }}
              >
                <div className="flex-shrink-0 position-relative imgUserLs">
                  <img
                    className="img-fluid userimg"
                    src={
                      item?.otherInformation?.profilePicture ||
                      "./assets/images/user.svg"
                    }
                    width="45"
                    height="45"
                    alt="user img"
                    style={{
                      border: "solid #ff7a00",
                      borderWidth: "2px",
                    }}
                  />
                  {item.checkIcon && <span className="active"></span>}
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3>
                      {item?.firstName} {item?.lastName}
                    </h3>
                  </div>
                  {item.action && (
                    <div
                      className={`font-14 d-flex align-items-center text66 ${
                        item.mdiIconClass ? "image" : "miscall"
                      }`}
                    >
                      {item.mdiIconClass && (
                        <i
                          className={`mdi ${item.mdiIconClass} font-20 pe-1`}
                        ></i>
                      )}
                      {item.action}
                    </div>
                  )}
                  <div className="onlineOfine d-flex justify-content-between align-items-center">
                    <div className="clock font-14 textb3">
                      {getCurrentTime(item?.otherInformation?.lastSeen)}
                    </div>
                    {item.checkIcon && (
                      <div className="checkonline font-14">
                        <i className="mdi mdi-check-all"></i>
                      </div>
                    )}
                  </div>
                </div>
              </a>
            ))
          ) : (
            <h5>No Record</h5>
          )}
        </div>
      )}
    </div>
  );
};

export default OrangeMemberTabList;

import React, { useEffect, useState } from "react";
import { getLastSeenString } from "../profile-container/ProfileDetailsutils";
import { useSelector } from "react-redux";
import {
  updateUserGroup,
  deleteUserGroupChat,
  joinGroup,
  removeFromGroup,
  getGroupActivity,
  deleteGroupForMe,
  getDetails,
} from "../home-container/apis/Groupapis";
import { useNotification } from "src/components/Notifination/ToastNotification";

const GroupDetails = ({
  getCurrentTime,
  groupDetails,
  socket,
  setEditGroup,
  toggleDrawer,
  setIsGroupEditable,
  setSelectedUserOrGroup,
  fetchUserGroups,
  srcIsAdmin = false,
  setGroupProfileView,
  setSelectedGroupOpen
}) => {
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const user = useSelector((state) => state.auth.user);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const [userList, setUserList] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [addRemove, setAddRemove] = useState(false);
  const [newGroupDetails, setNewGroupDetails] = useState();
  const [deleteMessageType, setDeleteMessageType] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [removeExit, setRemoveExit] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const groupDetails_close = () => {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("groupDetails").style.display = "none";
    setIsGroupEditable(false);
    if(setGroupProfileView)
    {
      setGroupProfileView(false)
    }
    if(setSelectedGroupOpen)
    {
      setSelectedGroupOpen(false)
    }

  };
  
async function fetchGroupDetails() {
  try {
    const { data } = await getDetails(groupDetails?._id);
    setNewGroupDetails(data?.groups[0]);
    setSelectedUserOrGroup(data?.groups[0])
  } catch (error) {
    console.log("error",error);
    
    // openErrorNotification("Error fetching group details")
  }
}

useEffect(() => {
  fetchGroupDetails()
}, [groupDetails?._id])


  const allowOrDisallowChat = async (id, isChatAllowed) => {
    let res = await updateUserGroup({
      groupId: id,
      isChatAllowed: !isChatAllowed,
    });
    if (res && newGroupDetails) {
      let participants = newGroupDetails.participants;
      if (participants) {
        participants?.map((participant, ind) => {
          socket.emit("groupPoke", {
            userId: participant,
            organizationId: user?.organization,
          });
        });
      }
    }
  };

  const deactivateGroup = async (id, isDeactivated) => {
    let res = await updateUserGroup({
      groupId: id,
      isDeactivated: !isDeactivated,
    });

    if (res && newGroupDetails) {
      let participants = newGroupDetails.participants;
      if (participants) {
        participants?.map((participant, ind) => {
          socket.emit("groupPoke", {
            userId: participant,
            organizationId: user?.organization,
          });
        });
      }
    }
  };

  const deleteGroup = async (id, isDeleted) => {
    let res = await updateUserGroup({
      groupId: id,
      isDeleted: !isDeleted,
    });
    if (!isDeleted) {
      newGroupDetails.isDeactivated = true;
      newGroupDetails.isDeleted = true;
    } else {
      newGroupDetails.isDeleted = false;
    }
    if (res && newGroupDetails) {
      let participants = newGroupDetails.participants;
      if (participants) {
        participants?.map((participant, ind) => {
          socket.emit("groupPoke", {
            userId: participant,
            organizationId: user?.organization,
          });
        });
      }
    }
  };

  const exitGroup = async (id) => {
    let res = await updateUserGroup({
      groupId: id,
      exit: true,
    });
  };

  const SearchUser = (keywords = "") => {
    if(keywords === "notSearch")
    {
      setAddRemove(true);
    }
    if(keywords === "")
{
  setAddRemove(false);
}else 
{
  setAddRemove(true);
}
    if (socket) {
      socket.emit("searchUsersInGroup", {
        userId: user?._id,
        organizationId: user?.organization,
        excludeUsers: [user?._id],
        searchString: keywords === "notSearch" ? "" : keywords,
        searchFromList: "searchUsersInGroup",
      });
      socket.on("searchUsersInGroupResponse", handleSocketUserListResponse);
    }
  };

  const handleSocketUserListResponse = ({ list }) => {
    setUserList(list);
  };

  const removeEmptyPTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const emptyPTags = doc.querySelectorAll("p:empty");
    emptyPTags.forEach((emptyPTag) => {
      emptyPTag.parentNode.removeChild(emptyPTag);
    });
    const emptyLITags = doc.querySelectorAll("li:empty");
    emptyLITags.forEach((emptyLITag) => {
      emptyLITag.parentNode.removeChild(emptyLITag);
    });
    const updatedHtmlString = doc.body.innerHTML;
    return updatedHtmlString;
  };
  function toggleButton(button) {
    if (button.innerHTML === "Add") {
      button.innerHTML = "Remove";
      button.classList.remove("addremvbtn");
      button.classList.add("removebtn");
    } else {
      button.innerHTML = "Add";
      button.classList.remove("removebtn");
      button.classList.add("addremvbtn");
    }
  }

  const selectUserFun = async (id) => {
    let participants = newGroupDetails.participants;
    if (newGroupDetails.participants.includes(id)) {
      newGroupDetails.participants = newGroupDetails.participants.filter(
        (uId) => uId !== id
      );

      let response = await updateUserGroup({
        groupId: newGroupDetails?._id,
        selectedUserId: id,
        remove: true,
      });

      const updatedHtmlString = removeEmptyPTags(
        response?.data.inputData?.messageHtml
      );
      // const input = {
      //   chatroom_id: response?.data.inputData.chatroom_id,
      //   senderId: response?.data.inputData.senderId,
      //   receiverId: response?.data.inputData.receiverId,
      //   message: response?.data.inputData?.message,
      //   messageType: "",
      //   files: [],
      //   fileTypes: [],
      //   messageHtml: updatedHtmlString,
      //   userAgent: JSON.parse(localStorage.getItem("ipData")),
      //   organization: response?.data.inputData?.organization,
      //   replyToMessage: null,
      //   replyToMedia: null,
      //   organizationId: response?.data.inputData?.organization,
      //   group: true,
      //   taggedUsers: [],
      //   isNotification: true,
      // };
      // socket.emit("message", input);
      fetchGroupDetails()
    } else {
      newGroupDetails.participants = [...newGroupDetails.participants, id];
      let response = await updateUserGroup({
        groupId: newGroupDetails?._id,
        selectedUserId: id,
        add: true,
      });

      // const updatedHtmlString = removeEmptyPTags(
      //   response?.data.inputData?.messageHtml
      // );
      // const input = {
      //   chatroom_id: response?.data.inputData.chatroom_id,
      //   senderId: response?.data.inputData.senderId,
      //   receiverId: response?.data.inputData.receiverId,
      //   message: response?.data.inputData?.message,
      //   messageType: "",
      //   files: [],
      //   fileTypes: [],
      //   messageHtml: updatedHtmlString,
      //   userAgent: JSON.parse(localStorage.getItem("ipData")),
      //   organization: response?.data.inputData?.organization,
      //   replyToMessage: null,
      //   replyToMedia: null,
      //   organizationId: response?.data.inputData?.organization,
      //   group: true,
      //   taggedUsers: [],
      //   isNotification: true,
      // };
      // socket.emit("message", input);
      fetchGroupDetails()
      participants = newGroupDetails.participants;
    }
    if (newGroupDetails) {
      participants?.map((participant, ind) => {
        socket.emit("groupPoke", {
          userId: participant,
          organizationId: user?.organization,
        });
      });
    }
    await fetchUserGroups();
  };

  const makeAdmin = async (id) => {
    let participants = newGroupDetails?.participants;
    if (newGroupDetails.admins.includes(id)) {
      newGroupDetails.admins = newGroupDetails.admins.filter(
        (uId) => uId !== id
      );
      let response = await updateUserGroup({
        groupId: newGroupDetails?._id,
        selectedUserId: id,
        adminsRemove: true,
      });

      document.getElementById(`userTag_A_${id}`).style.display = "none";
      document.getElementsByClassName(`ara_${id}`)[0].textContent = "Add Admin";
      const updatedHtmlString = removeEmptyPTags(
        response?.data.inputData?.messageHtml
      );
      // const input = {
      //   chatroom_id: response?.data.inputData.chatroom_id,
      //   senderId: response?.data.inputData.senderId,
      //   receiverId: response?.data.inputData.receiverId,
      //   message: response?.data.inputData?.message,
      //   messageType: "",
      //   files: [],
      //   fileTypes: [],
      //   messageHtml: updatedHtmlString,
      //   userAgent: JSON.parse(localStorage.getItem("ipData")),
      //   organization: response?.data.inputData?.organization,
      //   replyToMessage: null,
      //   replyToMedia: null,
      //   organizationId: response?.data.inputData?.organization,
      //   group: true,
      //   taggedUsers: [],
      //   isNotification: true,
      // };
      // socket.emit("message", input);
      fetchGroupDetails()
    } else {
      newGroupDetails.admins = [...newGroupDetails.admins, id];
      let response = await updateUserGroup({
        groupId: newGroupDetails?._id,
        selectedUserId: id,
        adminsAdd: true,
      });
      document.getElementById(`userTag_A_${id}`).style.display = "block";
      document.getElementsByClassName(`ara_${id}`)[0].textContent =
        "Remove Admin";
      const updatedHtmlString = removeEmptyPTags(
        response?.data.inputData?.messageHtml
      );
      // const input = {
      //   chatroom_id: response?.data.inputData.chatroom_id,
      //   senderId: response?.data.inputData.senderId,
      //   receiverId: response?.data.inputData.receiverId,
      //   message: response?.data.inputData?.message,
      //   messageType: "",
      //   files: [],
      //   fileTypes: [],
      //   messageHtml: updatedHtmlString,
      //   userAgent: JSON.parse(localStorage.getItem("ipData")),
      //   organization: response?.data.inputData?.organization,
      //   replyToMessage: null,
      //   replyToMedia: null,
      //   organizationId: response?.data.inputData?.organization,
      //   group: true,
      //   taggedUsers: [],
      //   isNotification: true,
      // };
      // socket.emit("message", input);
      fetchGroupDetails()
    }

    if (newGroupDetails) {
      participants?.map((participant, ind) => {
        socket.emit("groupPoke", {
          userId: participant,
          organizationId: user?.organization,
        });
      });
    }
    await fetchUserGroups();
  };

  const removeUserFromGroup = async () => {
    
    let participants = newGroupDetails?.participants;
    let superAdmin = newGroupDetails?.superAdmin[0];
    let admins = newGroupDetails.admins;
    let status = "Left";
    if (selectedUser === user?._id) {
      if (admins.includes(user?._id.toString()) && admins?.length <= 1) {
        openErrorNotification(
          "First make someone an admin before removing yourself."
        );
        setRemoveExit(null);
        return false;
      }
    } else {
      status = "KickedOut";
      setRemoveExit(null);
    }

    if (
      admins.includes(selectedUser) ||
      newGroupDetails.moderators.includes(selectedUser) ||
      superAdmin?._id === user?._id
    ) {
      status = "KickedOut";
    }

    let data = {
      groupId: newGroupDetails?._id,
      selectedUserId: selectedUser,
      status: status,
    };
    if (user?._id === selectedUser) {
      data.status = "Left";
    }

    if (newGroupDetails?.admins?.includes(user?._id) || user?.isAdmin) {
      let response = await deleteGroupForMe(data);
      if (newGroupDetails) {
        newGroupDetails?.noParticipants?.map((participant, ind) => {
          socket.emit("groupPoke", {
            userId: participant,
            organizationId: user?.organization,
          });
        });
      }
      // const updatedHtmlString = removeEmptyPTags(response?.data.inputData?.messageHtml);
      // const input = {
      //   chatroom_id: response?.data.inputData.chatroom_id,
      //   senderId: response?.data.inputData.senderId,
      //   receiverId: response?.data.inputData.receiverId,
      //   message: response?.data.inputData?.message,
      //   messageType: "",
      //   files: [],
      //   fileTypes: [],
      //   messageHtml: updatedHtmlString,
      //   userAgent: JSON.parse(localStorage.getItem("ipData")),
      //   organization: response?.data.inputData?.organization,
      //   replyToMessage: null,
      //   replyToMedia: null,
      //   organizationId: response?.data.inputData?.organization,
      //   group: true,
      //   taggedUsers: [],
      //   isNotification: true,
      // };
      // socket.emit("message", input);
      fetchGroupDetails()
    } else {
      let response = await removeFromGroup(data);
      const updatedHtmlString = removeEmptyPTags(
        response?.data.inputData?.messageHtml
      );
      // const input = {
      //   chatroom_id: response?.data.inputData.chatroom_id,
      //   senderId: response?.data.inputData.senderId,
      //   receiverId: response?.data.inputData.receiverId,
      //   message: response?.data.inputData?.message,
      //   messageType: "",
      //   files: [],
      //   fileTypes: [],
      //   messageHtml: updatedHtmlString,
      //   userAgent: JSON.parse(localStorage.getItem("ipData")),
      //   organization: response?.data.inputData?.organization,
      //   replyToMessage: null,
      //   replyToMedia: null,
      //   organizationId: response?.data.inputData?.organization,
      //   group: true,
      //   taggedUsers: [],
      //   isNotification: true,
      // };
      // socket.emit("message", input);
      fetchGroupDetails()
      if (newGroupDetails) {
        participants?.map((participant, ind) => {
          socket.emit("groupPoke", {
            userId: participant,
            organizationId: user?.organization,
          });
        });
      }
    }

    await fetchUserGroups();
    setRemoveExit(null);
  };

  const makeModerator = async (id) => {
    try {
      if (!newGroupDetails?._id || !user?._id) {
        console.error("Group details or user details are missing");
        return;
      }
  
      let isCurrentUserAdmin = newGroupDetails?.admins?.includes(user?._id) || user?.isAdmin;
      let moderators = newGroupDetails.moderators || [];
      const isModerator = moderators.includes(id);
  
      if (isCurrentUserAdmin) {
        if (isModerator) {
          newGroupDetails.moderators = moderators.filter((uId) => uId !== id);
          const response = await updateUserGroup({
            groupId: newGroupDetails._id,
            selectedUserId: id,
            moderatorsRemove: true,
          });
  
          document.getElementById(`userTag_M_${id}`).style.display = "none";
          document.getElementsByClassName(`arm_${id}`)[0].textContent = "Add Moderator";
          updateMessageHtml(response);
        } else {
          // Add moderator
          newGroupDetails.moderators = [...moderators, id];
          const response = await updateUserGroup({
            groupId: newGroupDetails._id,
            selectedUserId: id,
            moderatorsAdd: true,
          });
  
          document.getElementById(`userTag_M_${id}`).style.display = "block";
          document.getElementsByClassName(`arm_${id}`)[0].textContent = "Remove Moderator";
          updateMessageHtml(response);
        }
        let participants = newGroupDetails.participants || [];
        participants.forEach((participant) => {
          socket.emit("groupPoke", {
            userId: participant,
            organizationId: user?.organization,
          });
        });
      } else {
        console.error("User does not have permission to modify moderators");
      }
  
      await fetchUserGroups();
    } catch (error) {
      console.error("Error updating moderators:", error);
    }
  };
  
  const updateMessageHtml = (response) => {
    const updatedHtmlString = removeEmptyPTags(response?.data.inputData?.messageHtml);
    // const input = {
    //   chatroom_id: response?.data.inputData?.chatroom_id,
    //   senderId: response?.data.inputData?.senderId,
    //   receiverId: response?.data.inputData?.receiverId,
    //   message: response?.data.inputData?.message,
    //   messageType: "",
    //   files: [],
    //   fileTypes: [],
    //   messageHtml: updatedHtmlString,
    //   userAgent: JSON.parse(localStorage.getItem("ipData")),
    //   organization: response?.data.inputData?.organization,
    //   replyToMessage: null,
    //   replyToMedia: null,
    //   organizationId: response?.data.inputData?.organization,
    //   group: true,
    //   taggedUsers: [],
    //   isNotification: true,
    // };
    // socket.emit("message", input);
  };
  

  const handleEditGroup = () => {
    let participants = newGroupDetails?.participants;
    if (
      participants &&
      (participants?.admins?.includes(user?._id) || user?.isAdmin)
    ) {
      setEditGroup(newGroupDetails?._id);
      createGroupDrawer_open();
    } else {
      openErrorNotification(
        "You don't have the permission to update group info!"
      );
    }
  };

  const createGroupDrawer_open = () => {
    let participants = newGroupDetails?.participants;
    if (participants && (participants?.admins?.includes(
      user?._id
    ) ||
      user?.isAdmin)) {
      toggleDrawer("createGroup");
    }
  };

  const GroupGary_open = () => {
    document.getElementById("GroupGaryTab").style.width = "100%";
    document.getElementById("GroupGaryTab").style.display = "block";
    document.getElementById("GroupActivitytext").style.display = "none";
    document.getElementById("opengallerygrp").style.display = "none";
    document.getElementById("GroupDelete").style.display = "none";
    document.getElementById("closegallerygrp").style.display = "block";
  };

  const GroupGary_close = () => {
    document.getElementById("GroupGaryTab").style.width = "0";
    document.getElementById("GroupGaryTab").style.display = "none";
    document.getElementById("GroupActivitytext").style.display = "none";
    document.getElementById("opengallerygrp").style.display = "block";
    document.getElementById("closegallerygrp").style.display = "none";
  };

  const GroupActivity_open = async () => {
    document.getElementById("GroupActivitytext").style.width = "100%";
    document.getElementById("GroupActivitytext").style.display = "block";
    document.getElementById("closeActivitygrp").style.display = "block";
    document.getElementById("openActivitygrp").style.display = "none";
    document.getElementById("closeActivitygrp").style.display = "block";
    let data1 = {
      groupId: newGroupDetails?._id,
    };
    let { data } = await getGroupActivity(data1);
    setActivityDetails(data);
  };

  const GroupActivity_close = () => {
    document.getElementById("openActivitygrp").style.display = "block";
    document.getElementById("closeActivitygrp").style.display = "none";
    document.getElementById("GroupActivitytext").style.width = "0";
    document.getElementById("GroupActivitytext").style.display = "none";
  };

  const GroupDelete_open = () => {
    document.getElementById("GroupDelete").style.width = "100%";
    document.getElementById("GroupDelete").style.display = "block";
    document.getElementById("GroupActivitytext").style.display = "none";
    document.getElementById("openDeletegrp").style.display = "none";
    document.getElementById("closeDeletegrp").style.display = "block";
  };

  const GroupDelete_close = () => {
    document.getElementById("GroupDelete").style.width = "0";
    document.getElementById("GroupDelete").style.display = "none";
    document.getElementById("openDeletegrp").style.display = "block";
    document.getElementById("closeDeletegrp").style.display = "none";
  };

  const deleteMessages = async () => {
    let deleteResponse = await deleteUserGroupChat(
      newGroupDetails?._id,
      deleteMessageType,
      authToken
    );
    setDeleteMessageType(null);
    if (deleteResponse) {
      let participants = newGroupDetails.participants;
      participants?.map((participant, ind) => {
        joinGroup(
          socket,
          participant,
          newGroupDetails?._id,
          user?.organization
        );
      });
    }
  };



  const renderProfilePicture = (participantsUser) => {
    const profilePicture = participantsUser?.otherInformation?.profilePicture;
    const firstNameInitial = participantsUser?.firstName?.charAt(0);
    const lastNameInitial = participantsUser?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="45"
          height="45"
          alt="user img"
          style={{
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
          }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: "50",
            height: "50",
            borderRadius: "50%",
            backgroundColor: "#e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
            color: "#555",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {`${firstNameInitial || ""}${lastNameInitial || ""}`}
        </div>
      );
    }
  };

  const handleSelectUser = (selectedUser)=>{
    setSelectedUserId(selectedUser)
  }
  return (
    <>
      <div
        className="chat-sidebar chat-animate-right"
        style={
          srcIsAdmin
            ? {
                display: "block",
                right: 0,
                width: "350px",
                height: "calc(100vh - 0px)",
                zIndex: 99,
                top: 0,
              }
            : {
                display: "none",
                right: 109,
              }
        }
        id="groupDetails"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header py-0">
              <div className="HeadSlidName d-flex justify-content-end align-items-center">
                <button
                  className=""
                  onClick={() => {
                    groupDetails_close();
                  }}
                >
                  ×
                </button>
              </div>
            </div>
            <div className="modal-body pt-0">
              <div className="text-center profText">
                <div
                  className="profImg"
                  style={{
                    backgroundImage: `url(${
                      newGroupDetails?.groupDP?.url ||
                      "/assets/images/gropu-icon.svg"
                    })`,
                  }}
                  onClick={() => {
                    handleEditGroup();
                  }}
                />
                <div className="userName font-16 color1a py-1">
                  {newGroupDetails?.title}{" "}
                  <button
                    className="border-0 bg-none color1a"
                    onClick={() => {
                      handleEditGroup();
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" />
                  </button>
                </div>
                <div className="userdec font-14 color99 py-1">
                  Created by
                  <br />
                  {newGroupDetails?.superAdmin &&
                    newGroupDetails?.superAdmin[0]?.firstName}{" "}
                  {newGroupDetails?.superAdmin &&
                    newGroupDetails?.superAdmin[0]?.lastName}{" "}
                  <button className="adminicon">A</button>
                </div>
                <div className="userprd font-12 color4d py-1">
                  on {getCurrentTime(newGroupDetails?.createdAt)}
                </div>
                <p className="userprd font-12 color80 py-1">
                  {newGroupDetails?.content}
                </p>
              </div>
              <div className="">
                <div className="msg-search mx-0 mb-3">
                  <input
                    type="text"
                    className="form-control bg-none"
                    id="searchUserByName"
                    placeholder="Search"
                    aria-label="search"
                    name="searchUserByName"
                    onChange={(e) => {
                      SearchUser(e.target.value);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between flex-grow-1 possktopnew">
                  <h3 className="d-flex align-items-center font-12 fw-bold color1a">
                    <span className="gropu-icon me-3" />{" "}
                    {newGroupDetails?.participants?.length} Participants
                  </h3>
                  {!addRemove && (newGroupDetails?.admins?.includes(user._id) ||
                  user?.isAdmin) && (
                    <button
                      type="button"
                      className="btnblue addremvbtn  font-12"
                      onClick={() => {
                        SearchUser("notSearch");
                      }}
                    >
                      Add
                    </button>
                  )}

                  {addRemove && (newGroupDetails?.admins?.includes(user._id) ||
                  user?.isAdmin) && (
                    <button
                      type="button"
                      className="btnblue removebtn removebtn2  font-12"
                      onClick={() => {
                        setAddRemove((prevState) => !prevState);
                      }}
                    >
                      X
                    </button>
                  )}
                </div>
                <div className="grpselectadd pb-3">
                  {}

                  {!addRemove &&
                    newGroupDetails?.participantsDetails &&
                    newGroupDetails?.participantsDetails.map(
                      (groupUser, index) => (
                        <div
                          className="d-flex align-items-center py-1"
                          key={index}
                        >
                          <div className="flex-shrink-0 position-relative imgUserLs">
                            {renderProfilePicture(groupUser)}
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h3 className="font-14 fw-500 color1a">
                              {groupUser?.firstName} {groupUser?.lastName}
                            </h3>
                            <div className="font-14 color66">
                              {groupUser?.otherInformation?.designation}{" "}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center"
                            id={`userTag_${groupUser?._id}`}
                          >
                            {groupUser?._id &&
                            newGroupDetails?.admins &&
                            newGroupDetails.admins.includes(groupUser._id) ? (
                              <button
                                className="adminicon"
                                id={`userTag_A_${groupUser._id}`}
                              >
                                A
                              </button>
                            ) : (
                              <button
                                className="adminicon"
                                id={`userTag_A_${groupUser._id}`}
                                style={{ display: "none" }}
                              >
                                A
                              </button>
                            )}

                            {newGroupDetails?.moderators &&
                            newGroupDetails.moderators.includes(
                              groupUser._id
                            ) ? (
                              <button
                                className="adminicon"
                                id={`userTag_M_${groupUser._id}`}
                              >
                                M
                              </button>
                            ) : (
                              <button
                                className="adminicon"
                                id={`userTag_M_${groupUser._id}`}
                                style={{ display: "none" }}
                              >
                                M
                              </button>
                            )}

                            <div className="dropdown toggleBtns visible">
                              <button
                                className="toggledots"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                                onClick={()=>handleSelectUser(groupUser._id)}
                              >
                             <i className="mdi mdi-dots-vertical" />
                              </button>
                              <ul
                                className="dropdown-menu mx-0 shadow w-220px p-0"
                                data-bs-theme="light"
                                data-popper-placement="bottom-start"
                                style={{
                                  position: "absolute",
                                  inset: "0px auto auto 0px",
                                  margin: 0,
                                  transform: "translate(0px, 39px)",
                                }}
                              >
                                {groupUser?._id &&
                                  (newGroupDetails?.admins?.includes(
                                    user?._id
                                  ) ||
                                    user?.isAdmin) && (
                                    <>
                                      {newGroupDetails?.admins?.includes(
                                        groupUser?._id
                                      ) ? (
                                        <li
                                          onClick={() => {
                                            makeAdmin(groupUser?._id);
                                          }}
                                        >
                                          <a
                                            className={`dropdown-item d-flex gap-2 align-items-center ara_${groupUser._id}`}
                                            href="#"
                                          >
                                            Remove Admin
                                          </a>
                                        </li>
                                      ) : (
                                        <li
                                          onClick={() => {
                                            makeAdmin(groupUser?._id);
                                          }}
                                        >
                                          <a
                                            className={`dropdown-item d-flex gap-2 align-items-center ara_${groupUser._id}`}
                                            href="#"
                                          >
                                            Make Admin
                                          </a>
                                        </li>
                                      )}

                                      {newGroupDetails?.moderators?.includes(
                                        groupUser?._id
                                      ) ? (
                                        <li
                                          onClick={() => {
                                            makeModerator(groupUser?._id);
                                          }}
                                        >
                                          <a
                                            className={`dropdown-item d-flex gap-2 align-items-center arm_${groupUser._id}`}
                                            href="#"
                                          >
                                            Remove Moderator
                                          </a>
                                        </li>
                                      ) : (
                                        <li
                                          onClick={() => {
                                            makeModerator(groupUser?._id);
                                          }}
                                        >
                                          <a
                                            className={`dropdown-item d-flex gap-2 align-items-center arm_${groupUser._id}`}
                                            href="#"
                                          >
                                            Make Moderator
                                          </a>
                                        </li>
                                      )}

                                      <li
                                        onClick={() => {
                                          setSelectedUser(groupUser?._id);
                                          setRemoveExit("remove");
                                        }}
                                      >
                                        <a
                                          className="dropdown-item d-flex gap-2 align-items-center"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#ExitGroupmsg"
                                        >
                                          Remove From Group
                                        </a>
                                      </li>
                                    </>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  {addRemove &&
                    (newGroupDetails?.admins?.includes(user?._id) ||
                      user?.isAdmin) && (
                      <div className="modal-body settinghndl border-0 pt-0 ps-0 pe-0">
                        <div className="chat-list grpselectadd">
                          {userList &&
                            userList.map((userData, index) => {
                              return (
                                <div
                                  className="d-flex align-items-center py-1"
                                  key={index}
                                  data={userData?._id}
                                >
                                  <div className="flex-shrink-0 position-relative imgUserLs">
                                    {renderProfilePicture(userData)}
                                  </div>
                                  <div className="d-flex justify-content-between flex-grow-1 ms-3">
                                    <div className="">
                                      <h3>
                                        {userData?.firstName}{" "}
                                        {userData?.lastName}{" "}
                                      </h3>
                                      <div className="font-14 color66">
                                        {userData?.otherInformation?.department}{" "}
                                        -{" "}
                                        {
                                          userData?.otherInformation
                                            ?.designation
                                        }
                                      </div>
                                    </div>
                                    <div className="onlineOfine d-flex justify-content-between align-items-center">
                                      {newGroupDetails.participants.includes(
                                        userData?._id
                                      ) ? (
                                        <button
                                          type="button"
                                          className="btnblue removebtn font-12"
                                          onClick={(e) => {
                                            toggleButton(e.target);
                                            selectUserFun(userData?._id);
                                          }}
                                        >
                                          Remove
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btnblue addremvbtn font-12"
                                          onClick={(e) => {
                                            toggleButton(e.target);
                                            selectUserFun(userData?._id);
                                          }}
                                        >
                                          Add
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className="GroupActivity">
                <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr possktopnew">
                  Activity
                  <button
                    className="rounded-4 closetabpassword"
                    id="openActivitygrp"
                    onClick={() => GroupActivity_open()}
                  >
                    <i className="mdi mdi-chevron-right" />
                  </button>
                  <button
                    className="rounded-4 closetabpassword"
                    id="closeActivitygrp"
                    onClick={() => {
                      GroupActivity_close();
                    }}
                    style={{ display: "none" }}
                  >
                    <i className="mdi mdi-chevron-left" />
                  </button>
                </div>
                <div
                  className="GroupActivitytext mb-3"
                  style={{ display: "none" }}
                  id="GroupActivitytext"
                >
                  {activityDetails &&
                    activityDetails.map((item, index) => {
                      return (
                        <div
                          className="dflex align-items-center justify-content-between py-2"
                          key={index}
                        >
                          <div className="font-14 color1a fw-500">
                            <span className="d-block font-12 color80 fw-normal">
                              {getCurrentTime(item?.timestamp)}
                            </span>
                            {item?.userDetails?.firstName}{" "}
                            {item?.userDetails?.lastName}
                            <span className="d-block font-14 color80 fw-normal">
                              {item?.status == "Added" &&
                                `Added by ${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                              {item?.status == "Left" && `Left group`}
                              {item?.status == "Self Exit" &&
                                `Self exit from group`}
                              {item?.status == "KickedOut" &&
                                `Removed by ${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                              {item?.status == "DescriptionUpdated" &&
                                `Group description updated by ${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                              {item?.status == "TitleUpdated" &&
                                `Group title updated by ${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                              {item?.status == "AvatarChnaged" &&
                                `Group avatar chnaged by ${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                            </span>
                          </div>
                          <div className="onofbtnwx"> {}</div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {newGroupDetails?.admins?.length > 0 &&
                (newGroupDetails?.admins?.includes(user?._id) ||
                  user?.isAdmin) && (
                  <>
                    <div className="GroupDelete">
                      <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr possktopnew">
                        Delete Messages & Files
                        <button
                          className="rounded-4 closetabpassword"
                          id="openDeletegrp"
                          onClick={() => GroupDelete_open()}
                        >
                          <i className="mdi mdi-chevron-right" />
                        </button>
                        <button
                          className="rounded-4 closetabpassword"
                          id="closeDeletegrp"
                          onClick={() => GroupDelete_close()}
                          style={{ display: "none" }}
                        >
                          <i className="mdi mdi-chevron-left" />
                        </button>
                      </div>
                      <div
                        className="GroupDeletetext mb-3"
                        style={{ display: "none" }}
                        id="GroupDelete"
                      >
                        <div className="dflex align-items-center justify-content-between py-0 font-14 color1a fw-500">
                          {" "}
                          All Chat{" "}
                          <button
                            type="button"
                            className="btn me-0 border-0 bg-none color1a"
                            data-bs-toggle="modal"
                            data-bs-target="#groupdeletemsg"
                            onClick={() => {
                              setDeleteMessageType("text");
                            }}
                          >
                            <i className="mdi mdi-delete mdi-18px" />
                          </button>
                        </div>
                        <div
                          className="dflex align-items-center justify-content-between py-0 font-14 color1a fw-500"
                          data-bs-toggle="modal"
                          data-bs-target="#groupdeletemsg"
                        >
                          {" "}
                          Only Files &amp; Media{" "}
                          <button
                            type="button"
                            className="btn me-0 border-0 bg-none color1a"
                            onClick={() => {
                              setDeleteMessageType("media");
                            }}
                          >
                            <i className="mdi mdi-delete mdi-18px" />
                          </button>
                        </div>
                        <div className="dflex align-items-center justify-content-between py-0 font-14 color1a fw-500">
                          {" "}
                          All Chats including Files &amp; Media{" "}
                          <button
                            type="button"
                            className="btn me-0 border-0 bg-none color1a"
                            data-bs-toggle="modal"
                            data-bs-target="#groupdeletemsg"
                            onClick={() => {
                              setDeleteMessageType("all");
                            }}
                          >
                            <i className="mdi mdi-delete mdi-18px" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr">
                        Group Settings
                      </div>
                      <div className="Achpaswd">
                        <div className="dflex align-items-center justify-content-between py-2">
                          <div className="font-14 color1a fw-500">
                            Allow Chat
                          </div>
                          <div className="onofbtnwx">
                            <button
                              type="button"
                              className={`btn btn-toggle ${
                                newGroupDetails?.isChatAllowed === true
                                  ? "active"
                                  : ""
                              } me-0`}
                              data-bs-toggle="button"
                              aria-pressed={`${
                                newGroupDetails?.isChatAllowed || false
                              }`}
                              autoComplete="off"
                              onClick={() => {
                                allowOrDisallowChat(
                                  newGroupDetails?._id,
                                  newGroupDetails?.isChatAllowed
                                );
                              }}
                            >
                              <div className="handle" />
                            </button>
                          </div>
                        </div>
                        <div className="dflex align-items-center justify-content-between py-2">
                          <div className="font-14 color1a fw-500">
                            Deactivate group
                          </div>
                          <div className="onofbtnwx">
                            <button
                              type="button"
                              className={`btn btn-toggle ${
                                newGroupDetails.isDeactivated === true
                                  ? "active"
                                  : ""
                              } me-0`}
                              data-bs-toggle="button"
                              aria-pressed={`${
                                newGroupDetails.isDeactivated || false
                              }`}
                              autoComplete="off"
                              onClick={() => {
                                deactivateGroup(
                                  newGroupDetails?._id,
                                  newGroupDetails.isDeactivated
                                );
                              }}
                            >
                              <div className="handle" />
                            </button>
                          </div>
                        </div>
                        <div className="dflex align-items-center justify-content-between py-2">
                          <div className="font-14 color1a fw-500">
                            Permanently Delete
                          </div>
                          <div className="onofbtnwx">
                            <button
                              type="button"
                              className={`btn btn-toggle ${
                                newGroupDetails.isDeleted === true
                                  ? "active"
                                  : ""
                              } me-0`}
                              data-bs-toggle="button"
                              aria-pressed={`${
                                newGroupDetails.isDeleted || false
                              }`}
                              autoComplete="off"
                              onClick={() => {
                                deleteGroup(
                                  newGroupDetails?._id,
                                  newGroupDetails.isDeleted
                                );
                              }}
                            >
                              <div className="handle" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              <button
                className="ExitGroup bg-none colorR border-0 font-14"
                data-bs-toggle="modal"
                data-bs-target="#ExitGroupmsg"
                onClick={() => {
                  setRemoveExit("Left");
                  setSelectedUser(user?._id);
                }}
              >
                {removeExit && removeExit == "remove"
                  ? "Remove the"
                  : removeExit == "delete" ||
                    (newGroupDetails?.noParticipants &&
                      newGroupDetails?.noParticipants.includes(user?._id))
                  ? "Delete the"
                  : "Exit from"}{" "}
                group
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade lbtk"
        id="groupdeletemsg"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content rounded-3 shadow">
            <div className="modal-body px-4 pt-4 pb-2 text-start">
              <h5 className="mb-2 font-16 color33 fw-bold">Delete Messages</h5>
              <p className="mb-0 font-14 color80">
                Are you sure you want to delete these messages?
              </p>
            </div>
            <div className="modal-footer flex-nowrap p-0 border-0">
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                data-bs-dismiss="modal"
              >
                <strong>Cancel </strong>
              </button>
              <button
                onClick={() => {
                  deleteMessages();
                }}
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                data-bs-dismiss="modal"
              >
                <strong>Delete</strong>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade lbtk"
        id="ExitGroupmsg"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content rounded-3 shadow">
            <div className="modal-body px-4 pt-4 pb-2 text-start">
            <h5 className="mb-2 font-16 color33 fw-bold">
  {removeExit === "Left"
    ? "Exit From"
    : removeExit === "exit" && newGroupDetails?.noParticipants &&
      (newGroupDetails?.admins?.includes(user?._id) || user?.isAdmin)
    ? "Exit From"
    : removeExit === "delete" && newGroupDetails?.noParticipants &&
      (newGroupDetails?.admins?.includes(user?._id) || user?.isAdmin)
    ? "Delete This"
    : "Remove From"}{" "}
  Group
</h5>
<p className="mb-0 font-14 color80">
  Are you sure you want to{" "}
  {removeExit === "Left"
    ? "Exit From"
    : removeExit === "exit" && user?._id === selectedUserId &&
      newGroupDetails?.noParticipants &&
      (newGroupDetails?.admins?.includes(user?._id) || user?.isAdmin)
    ? "Exit From"
    : removeExit === "delete" && newGroupDetails?.noParticipants &&
      (newGroupDetails?.admins?.includes(user?._id) || user?.isAdmin)
    ? "Delete This"
    : "Remove From"}{" "}
  Group?
</p>

              {}
            </div>
            <div className="modal-footer flex-nowrap p-0 border-0">
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                data-bs-dismiss="modal"
                onClick={() => {
                  setRemoveExit(null);
                }}
              >
                <strong>Cancel </strong>
              </button>
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
                data-bs-dismiss="modal"
                onClick={() => {
                  removeUserFromGroup();
                }}
              >
              <strong>
  {removeExit === "Left"
    ? "Exit"
    : removeExit === "exit" &&
      newGroupDetails?.noParticipants &&
      !newGroupDetails?.noParticipants.includes(user?._id)
    ? "Exit"
    : removeExit === "delete" ||
      (newGroupDetails?.noParticipants &&
        newGroupDetails?.noParticipants.includes(user?._id))
    ? "Delete"
    : "Remove"}
</strong>{" "}

              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GroupDetails;

import { combineReducers } from "redux";

import HomeReducer from './containers/home-container/reducer/HomeReducer';
import authReducer  from "./containers/home-container/reducer/AuthReducer"
import AccountReducer  from "./containers/account-container/reducer/AccountReducer"
import ChatBoxReducer  from "./containers/chatbox-container/reducer/ChatBoxReducer"
import DepartmentReducer  from "./containers/admin-containers/department-container/reducer/DepartmentReducer"
import DesignationReducer  from "./containers/admin-containers/designation-container/reducer/DesignationReducer"
import LocationReducer  from "./containers/admin-containers/location-container/reducer/LocationReducer"
import NotificationReducer  from "./containers/admin-containers/notification-container/reducer/NotificationReducer"
import RoleReducer  from "./containers/admin-containers/role-container/reducer/RoleReducer"
import UserReducer  from "./containers/admin-containers/user-container/reducer/UserReducer"
import chatHistoryReducer from "./containers/home-container/reducer/ChatReducer";
import GroupReducer  from "./containers/admin-containers/group-container/reducer/GroupReducer"
import PermissionsMasterReducer from './containers/admin-containers/permission-container/reducer/PermissionsMasterReducer';
import RolePermissionsReducer from './containers/admin-containers/permission-container/reducer/RolePermissionsReducer';
import activeUserReducer  from "./redux/slices/UserSlice"
import activeUserChatListReducer  from "./redux/slices/chatListSlice"
import activeGroupReducer  from "./redux/slices/groupsSlice"

export default combineReducers({
    HomeReducer,
    auth: authReducer,
    AccountReducer,
    ChatBoxReducer,
    DepartmentReducer,
    DesignationReducer,
    UserReducer,
    LocationReducer,
    RoleReducer,
    chatHistoryReducer,
    GroupReducer,
    PermissionsMasterReducer,
    RolePermissionsReducer,
    NotificationReducer,
    activeUser: activeUserReducer,
    groupsSlice: activeGroupReducer,
    chatList:activeUserChatListReducer

});
